import React, { Component } from "react";
import Wrapper from "./wrapper";
import axios from "axios";
import { baseurl } from "../../config";
import "@material/react-chips/dist/chips.css";
import { decryptInfo } from "../../utils/auth";
import Loader from "react-loader-spinner";
class SingleOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayError: false,
      customerCode: null,
      productCode: null,
      publicationDate: null,
      productQuantity: null,
      error: "",
      status: false,
      data: {
        products: [],
        customers: []
      }
    };
    this.onHandleChange = this.onHandleChange.bind(this);
    this.onHandleSubmit = this.onHandleSubmit.bind(this);
  }
  componentDidMount() {
    const user = decryptInfo("printOrderMaker");
    axios({
      method: "get",
      url: `${baseurl}/order/singleOrder`,
      headers: {
        Authorization: `Bearer ${user.headers.authorization}`
      }
    })
      .then(res => {
        this.setState({
          status: false,
          data: res.data.data
        });
        console.log(res);
      })
      .catch(error => {
        console.log(error);
      });
  }
  onHandleSubmit(event) {
    event.preventDefault();
    const user = decryptInfo("printOrderMaker");
    const {
      productCode,
      productQuantity,
      customerCode,
      publicationDate
    } = this.state;
    let dateDiff = parseInt(Date.parse(publicationDate)) - Date.now();
    console.log(dateDiff);

    this.setState({
      status: true
    });
    axios({
      method: "post",
      url: `${baseurl}/order/singleOrder`,
      data: {
        productCode,
        productQuantity,
        customerCode,
        publicationDate
      },
      headers: {
        Authorization: `Bearer ${user.headers.authorization}`
      }
    })
      .then(res => {
        this.setState({
          status: false,
          displayError: true,
          error: `${res.data.message}`
        });
        console.log(res);
        this.props.history.push('/printOrderMaker/orderSuccessful')
      })
      .catch(error => {
        console.log(error);
        this.setState({
          status: false,
          displayError: true,
          error: error.response.data.message
        });
      });
  }
  onHandleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
      displayError: false
    });
  }

  render() {
    const {
      displayError,
      error,
      status,
      productCode,
      productQuantity,
      customerCode,
      publicationDate,
      data
    } = this.state;
    return (
      <Wrapper>
        <div className="page-content-wrapper ">
          <div className="container container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="row align-items-center">
                    <div className="col-md-8">
                      <h4 className="page-title m-0  ml-3">Order</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-10">
                <div className="card m-b-30">
                  <div className="card-body">
                    <h4 className="mt-0 header-title">
                      Single Direct Customer Order
                    </h4>
                    <p className="text-muted m-b-30 font-14"></p>
                    <form encType="multipart/form-data">
                      <div
                        className="form-group row"
                        style={{
                          display: `${displayError ? "block" : "none"}`
                        }}
                      >
                        <div className="col-12">
                          <span className="text-danger"> {error}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="input"
                          className="col-sm-3 col-form-label"
                        >
                          Select product
                        </label>
                        <div className="col-sm-4">
                          <select
                            className="custom-select"
                            name="productCode"
                            defaultValue={productCode}
                            onChange={this.onHandleChange}
                          >
                            <option selected>Select product</option>
                            {data.products.map(product => (
                              <option value={product.productCode}>
                                {product.productName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="input"
                          className="col-sm-3 col-form-label"
                        >
                          Select customer
                        </label>
                        <div className="col-sm-4">
                          <select
                            className="custom-select"
                            name="customerCode"
                            defaultValue={customerCode}
                            onChange={this.onHandleChange}
                          >
                            <option selected>Select Customer</option>
                            {data.customers.map(customer => (
                              <option value={customer.customerCode}>
                                {customer.userName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="input"
                          className="col-sm-3 col-form-label"
                        >
                          Product quantity{" "}
                        </label>
                        <div className="col-sm-4">
                          <input
                            className="form-control"
                            name="productQuantity"
                            type="number"
                            value={productQuantity}
                            onChange={this.onHandleChange}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="input"
                          className="col-sm-3 col-form-label"
                        >
                          Publication date{" "}
                        </label>
                        <div className="col-sm-4">
                          <input
                            className="form-control"
                            name="publicationDate"
                            type="date"
                            value={publicationDate}
                            onChange={this.onHandleChange}
                          />
                        </div>
                      </div>
                      <div
                        className="form-group text-center "
                        style={{ display: `${status ? "block" : "none"}` }}
                      >
                        <Loader
                          type="Bars"
                          color="#ff6805"
                          width="50"
                          height="30"
                        />
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-2"></div>
                        <div className="col-sm-6 text-right">
                          <button
                            className="btn button2 text-white"
                            onClick={this.onHandleSubmit}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default SingleOrder;
