import React,{Component} from 'react'
import avatar from '../../assets/icons/man-user.svg'
import search from '../../assets/icons/search.svg'
import {NavLink} from 'react-router-dom'
import angleDown from '../../assets/icons/angle-down.svg'
import menu from '../../assets/icons/menu.svg'
import close from '../../assets/icons/close.svg'
import $ from 'jquery'
import {decryptInfo} from '../../utils/auth'
import {Redirect} from 'react-router-dom'
class Content extends Component {
    constructor(props){
        super(props);
        this.state = {
            user: null
        }

    }
    componentDidMount(){
        $('.toggle-search').on('click', function (event) {
            event.preventDefault()
            var targetId = $(this).data('target');
            var $searchBar;
            if (targetId) {
                $searchBar = $(targetId);
                $searchBar.toggleClass('open');
            }
        });
        $('[data-toggle="tooltip"]').tooltip();
        $('[data-toggle="popover"]').popover();    
    }
    render(){     
        return (
        <div className="content-page">    
        <div className="content">

          
            <div className="topbar">

                <div className="topbar-left	d-none d-lg-block">
                    <div className="text-center">
                        <NavLink to="/" className="logo">
                            <span style={{color: '#e3f2fd'}}  className=" text-uppercase ">Gazeti Network 20</span>
                        </NavLink>
                    </div>
                </div>

                <nav className="navbar-custom">

                   
                     <div className="search-wrap" id="search-wrap">
                        <div className="search-bar">
                            <input className="search-input" type="search" placeholder="Search" />
                            <a href="/" className="close-search toggle-search" data-target="#search-wrap">
                                <i className="mdi mdi-close-circle"><img src={close} alt="icon" width="17" height="17"/></i>
                            </a>
                        </div>
                    </div>

                    <ul className="list-inline float-right mb-0">
                        <li className="list-inline-item dropdown notification-list">
                            <a className="nav-link waves-effect toggle-search" href="#"  data-target="#search-wrap">
                                <i className="mdi mdi-magnify noti-icon"><img src={search} alt="icon" width="14" height="13"/></i>
                            </a>
                        </li>
                        <li className="list-inline-item dropdown notification-list nav-user">
                            <a className="nav-link dropdown-toggle arrow-none waves-effect" data-toggle="dropdown" href="/" role="button"
                            aria-haspopup="false" aria-expanded="false">
                                <img src={avatar} alt="user" className="rounded-circle" />
                                <span className="d-none d-md-inline-block ml-1">{}
                                 <i className="mdi mdi-chevron-down"><img src={angleDown} width="10" height="10" alt="icon"/></i> 
                                 </span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right dropdown-menu-animated profile-dropdown">
                                <a className="dropdown-item" href="/"><i className="dripicons-exit text-muted"></i> <NavLink className="text-primary" to="/logout">Logout</NavLink></a>
                                <a className="dropdown-item" href="/login"><i className="dripicons-exit text-muted"></i> <NavLink to="/login">Login</NavLink></a>
                            <a className="dropdown-item" href="/activationCode"><i className="dripicons-exit text-muted"></i> <NavLink to="/activationCode">Activation code</NavLink></a>
                            </div>
                        </li>

                    </ul>

                    <ul className="list-inline menu-left mb-0">
                        <li className="list-inline-item">
                            <button type="button" className="button-menu-mobile open-left waves-effect">
                                <i className="mdi mdi-menu"><img src={menu} alt="icon" width="40%" height="40%"/></i>
                            </button>
                        </li>
                       
                       

                    </ul>


                </nav>

            </div>          
            {this.props.children}
        
        </div>
    </div>
        )
    }
}
export default Content;