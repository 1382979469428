import {week,roles} from '../config'
export const encryptInfo = (user, token="token")=>{
    const userString = JSON.stringify(user)
    localStorage.setItem(token,userString)
}
export const decryptInfo = (token="token")=>{
    if(localStorage.getItem(token) !== null){    
        const userString = localStorage.getItem(token)
        const user = JSON.parse(userString)        
        return user
    }    
    return {
        headers: {
            authorization: ""
        }
    }
}
export const formatDate=(date)=>{
    const current_datetime=new Date(date);    
    const month = current_datetime.getMonth() + 1 < 10 ? `0${current_datetime.getMonth() + 1}`: current_datetime.getMonth() + 1
    if(isNaN(month)){
        return ''
    }
    const day = current_datetime.getDate() < 10 ?`0${current_datetime.getDate()}`: current_datetime.getDate()
    const hour = current_datetime.getHours() < 10 ?`0${current_datetime.getHours()}`: current_datetime.getHours()
    const minutes = current_datetime.getMinutes() < 10 ?`0${current_datetime.getMinutes()}`: current_datetime.getMinutes()
    const seconds = current_datetime.getSeconds() < 10 ?`0${current_datetime.getSeconds()}`: current_datetime.getSeconds()
    return day + "-"+ (month) + "-" +current_datetime.getFullYear() + " "  + hour + ":" + minutes + ":" + seconds
}
export const releaseDays = (days)=>{
    console.log(days)
  const releaseDays = []
  week.forEach((day)=>{
      if(days.includes(`${day.index}`)){
          releaseDays.push(day.day)
      }
  })
  console.log(releaseDays)
  return releaseDays.join(', ')
}
export const getRoleName = (roleName)=>{
    let selectRole  = roles.find((role)=> role.role === roleName)
    console.log(selectRole)
    if(selectRole){
        return selectRole.roleName
    }
    return 'NULL'
}

export function toCommas(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

