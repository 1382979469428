import React, {Component} from 'react'
import {Modal,Button} from 'react-bootstrap'
import eye from '../../assets/icons/eye.svg'
import {formatDate} from '../../utils/auth'
export default class ViewOrder extends Component{
    constructor(props){
        super(props)
        this.state = {
            show: false
        }
        this.handleShow = this.handleShow.bind(this)
    }
    handleShow(){
        this.setState({
            show: !this.state.show
        })
    }
    render(){
        const {show} = this.state
        const {row} = this.props
        return (
          <React.Fragment>
            <Button
              variant="secondary"
              className="btn btn-xs"
              onClick={this.handleShow}             
              title="view order"
            >
              <img src={eye} width="18" height="18" alt="eye" />
            </Button>

            <Modal show={show} onHide={this.handleShow} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Order Summary</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row mb-3">
                  <div className="col-sm-3">Created at</div>
                  <div className="col-sm-9">{formatDate(row.createdAt)}</div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-3">Products received</div>
                  <div className="col-sm-9">
                    <table className="table table-bordered col-sm-11">
                      <tr>
                        <th>Product code</th>
                        <th>Product quantity</th>
                      </tr>
                      <tbody>
                        {row.productsReceived.map(item => (
                          <tr>
                            <td>{item.productCode}</td>
                            <td>{item.productQuantity}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-3">External bill count</div>
                  <div className="col-sm-9">
                    <table className="table table-bordered col-sm-11">
                      <tr>
                        <th>Product code</th>
                        <th>Product quantity</th>
                      </tr>
                      <tbody>
                        {row.billCount.external.map(item => (
                          <tr>
                            <td>{item.productCode}</td>
                            <td>{item.productQuantity}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-3">Internal bill count</div>
                  <div className="col-sm-9">
                    <table className="table table-bordered col-sm-11">
                      <tr>
                        <th>Product code</th>
                        <th>Product quantity</th>
                      </tr>
                      <tbody>
                        {row.billCount.internal.map(item => (
                          <tr>
                            <td>{item.productCode}</td>
                            <td>{item.productQuantity}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-3">External bill amount</div>
                  <div className="col-sm-9">
                    {parseFloat(row.billAmount.external.$numberDecimal)}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-3">Internal bill amount</div>
                  <div className="col-sm-9">
                    {parseFloat(row.billAmount.internal.$numberDecimal)}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleShow}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </React.Fragment>
        );
    }
}