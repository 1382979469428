import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import eye from "../../assets/icons/print.svg";
import { decryptInfo } from "../../utils/auth";
import { baseurl } from "../../config";
import axios from "axios";
import Loader from "react-loader-spinner";
export default class AddOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      displayError: false,
      error: "",
      status: false,
      rejected: null,
      accepted: null,
      reason: null,
      productCode: null,
      acceptedReturns: [],
      rejectedReturns: []
    };
    this.handleShow = this.handleShow.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onHandleAdd = this.onHandleAdd.bind(this);
    this.onHandleChange = this.onHandleChange.bind(this);
  }
  handleShow() {
    this.setState({
      show: !this.state.show
    });
  }
  onHandleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
      displayError: false
    });
  }
  onHandleAdd() {
    const { rejected, accepted, reason, productCode } = this.state;
    if (productCode) {
      if (rejected && reason) {
        let products = this.state.rejectedReturns.find(
          product => product.productCode === productCode
        );
        if (products) {
          this.setState({
            displayError: true,
            error: "Product already added"
          });
        } else {
          this.setState({
            rejectedReturns: [
              {
                productCode,
                productQuantity: rejected,
                reason
              },
              ...this.state.rejectedReturns
            ]
          });
        }
      } else if (rejected && !reason) {
        this.setState({
          displayError: true,
          error: "Please fill reason "
        });
      }
      if (accepted) {
        let products = this.state.acceptedReturns.find(
          product => product.productCode === productCode
        );
        if (products) {
          this.setState({
            displayError: true,
            error: "Product already added"
          });
        } else {
          this.setState({
            acceptedReturns: [
              {
                productCode,
                productQuantity: accepted
              },
              ...this.state.acceptedReturns
            ]
          });
        }
      }
    } else {
      this.setState({
        displayError: true,
        error: "Please fill product code "
      });
    }
  }
  onSubmit(event) {
    event.preventDefault();
    const { rejectedReturns, acceptedReturns } = this.state;
    if (!(acceptedReturns.length > 0) && !(rejectedReturns.length > 0)) {
      this.setState({
        displayError: true,
        error: "Please fill products"
      });
    } else {
      this.setState({
        status: true
      });
      const user = decryptInfo("returnOfficer");
      axios({
        method: "post",
        url: `${baseurl}/returnClaim/confirm`,
        data: {
          returnClaimId: this.props.row._id,
          acceptedReturns,
          rejectedReturns
        },
        headers: {
          Authorization: `Bearer ${user.headers.authorization}`
        }
      })
        .then(res => {
          this.setState({
            status: false,
            displayError: true,
            error: `${res.data.message}`
          });
          console.log(res);
          window.location.reload(false);
        })
        .catch(error => {
          console.log(error);
          this.setState({
            status: false,
            displayError: true,
            error: error.response? error.response.data.message: "Request has failed"
          });
        });
    }
  }
  render() {
    const {
      show,
      displayError,
      error,
      status,
      rejected,
      accepted,
      reason,
      productCode
    } = this.state;
    const { row } = this.props;
    return (
      <React.Fragment>
        <Button
          variant="secondary"
          className="btn btn-xs"
          onClick={this.handleShow}
          disabled={
            row.submissionBucket.acceptedReturns.length > 0 ||
            row.submissionBucket.rejectReturns.length > 0
              ? true
              : false
          }
        >
          <img src={eye} width="18" height="18" alt="eye" />
        </Button>

        <Modal show={show} onHide={this.handleShow} size="md">
          <Modal.Header closeButton>
            <Modal.Title>Verify Claim</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="form-group row"
              style={{ display: `${displayError ? "block" : "none"}` }}
            >
              <div className="col-12">
                <span className="text-danger"> {error}</span>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="input" className="col-sm-3 col-form-label">
                Product code
              </label>
              <div className="col-sm-6">
                <input
                  className="form-control"
                  type="text"
                  name="productCode"
                  value={productCode}
                  onChange={this.onHandleChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="input" className="col-sm-3 col-form-label">
                Rejected
              </label>
              <div className="col-sm-6">
                <input
                  className="form-control"
                  name="rejected"
                  type="number"
                  onChange={this.onHandleChange}
                  value={rejected}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="input" className="col-sm-3 col-form-label">
                Accepted
              </label>
              <div className="col-sm-6">
                <input
                  className="form-control"
                  name="accepted"
                  type="number"
                  onChange={this.onHandleChange}
                  value={accepted}
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="input" className="col-sm-3 col-form-label">
                Reason
              </label>
              <div className="col-sm-6">
                <textarea
                  name="reason"
                  onChange={this.onHandleChange}
                  className="form-control"
                  value={reason}
                ></textarea>
              </div>
            </div>
            <div
              className="form-group text-center "
              style={{ display: `${status ? "block" : "none"}` }}
            >
              <Loader type="Bars" color="#ff6805" width="50" height="30" />
            </div>
            <div className="form-group row">
              <div className="col-sm-2"></div>
              <div className="col-sm-6 text-right">
                <button
                  className="btn button2 text-white"
                  onClick={this.onHandleAdd}
                >
                  Add
                </button>
              </div>
              <div className="form-group row">
                <div className="col-sm-2"></div>
                <div className="col-sm-6 text-right">
                  <button
                    className="btn button2 text-white"
                    onClick={this.onSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleShow}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}
