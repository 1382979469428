import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import eye from "../../assets/icons/return.svg";
import { decryptInfo } from "../../utils/auth";
import { baseurl } from "../../config";
import axios from "axios";
import Loader from "react-loader-spinner";
export default class ReturnClaim extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      displayError: false,
      error: "",
      status: false,
      printedOrder: []
    };
    this.handleShow = this.handleShow.bind(this);
    this.onHandleChange = this.onHandleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  handleShow() {
    this.setState({
      show: !this.state.show
    });
  }
  onHandleChange(event) {
    console.log(event.target.name);
    this.setState({
      displayError: false
    });
    const filteredOrder = this.state.printedOrder.filter(
      order => order.productCode !== event.target.name
    );
    if (event.target.value !== 0 || event.target.value === "") {
      this.setState({
        printedOrder: [
          {
            productCode: event.target.name,
            productQuantity: event.target.value
          },
          ...filteredOrder
        ]
      });
    }
  }
  onSubmit(event) {
    event.preventDefault();
    const { printedOrder } = this.state;

    this.setState({
      status: true
    });
    const user = decryptInfo("directCustomer");
    if (printedOrder.length > 0) {
      axios({
        method: "post",
        url: `${baseurl}/returnClaim/request`,
        data: {
          orderReceivedCode: this.props.row.orderCode,
          productsReceived: printedOrder
        },
        headers: {
          Authorization: user ? `Bearer ${user.headers.authorization}` : ""
        }
      })
        .then(res => {
          this.setState({
            status: false,
            displayError: true,
            error: `${res.data.message}`
          });
          console.log(res);
          window.location.reload(false)
        })
        .catch(error => {
          console.log(error);
          this.setState({
            status: false,
            displayError: true,
            error: error.response? error.response.data.message: "Request has failed"
          });
        });
    } else {
      this.setState({
        displayError: true,
        error: "No product is submitted"
      });
    }
  }
  render() {
    const { show, displayError, error, status } = this.state;
    const { row } = this.props;
    return (
      <React.Fragment>
        <Button
          variant="secondary"
          className="btn btn-xs"
          disabled={row.submissionCode || row.billInitiated ? true : false}
          onClick={this.handleShow}
        >
          <img src={eye} width="18" height="18" alt="eye" />
        </Button>

        <Modal show={show} onHide={this.handleShow} size="md">
          <Modal.Header closeButton>
            <Modal.Title>Return claim</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form encType="multipart/form-data">
              <div
                className="form-group row"
                style={{ display: `${displayError ? "block" : "none"}` }}
              >
                <div className="col-12">
                  <span className="text-danger"> {error}</span>
                </div>
              </div>
              {row.productsReceived.map(order => (
                <div className="form-group row">
                  <label htmlFor="input" className="col-sm-2 col-form-label">
                    {order.productCode}
                  </label>
                  <div className="col-sm-3">
                    <input
                      className="form-control"
                      name={order.productCode}
                      type="number"
                      onChange={this.onHandleChange}
                    />
                  </div>
                </div>
              ))}
              <div
                className="form-group text-center "
                style={{ display: `${status ? "block" : "none"}` }}
              >
                <Loader type="Bars" color="#ff6805" width="50" height="30" />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.onSubmit}>
              Return
            </Button>
            <Button variant="secondary" onClick={this.handleShow}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}
