import React, {Component} from 'react'
import Nav from '../nav/nav'
import Content from '../content/content'
import {decryptInfo} from '../../utils/auth'
import {Redirect} from 'react-router-dom'
import list from '../../assets/icons/list.svg'
import home from '../../assets/icons/home.svg'
class Wrapper extends Component{
    constructor(props){
        super(props)
        this.state = {}
    }
    render(){
        const user = decryptInfo('billOfficer') 
          
        console.log(user)
        if(!user){
            return(
                <Redirect to="/login" />
            )
        }
        const links = [
            {
                name: 'Unsettled  Bills',
                url: '/billOfficer/home',
                icon:home
            },
            {
                name: 'Settled  Bills',
                url: '/billOfficer/settledBills',
                icon:home
            },
            {
                name: 'View New Invoices',
                url: '/billOfficer/newInvoice',
                icon:home
            },
            {
                name: 'View Invoice Adjustments',
                url: '/billOfficer/invoiceAdjustment',
                icon:home,
                children: [
                    {
                        url: "/billOfficer/supplyDiscrepancy",
                        name: "View Supply Discrepancy"
                    },
                    {
                        url: "/billOfficer/returnsDiscrepancy",
                        name: "View Return Discrepancy"
                    }
                ]
            },
            {
                name: 'View Debit & Credit Notes',
                url: '/billOfficer/debitCreditNotes',
                icon:home
            },
            {
                name: 'View Adjusted Invoices',
                url: '/billOfficer/adjustedInvoices',
                icon:home,
                children: [
                    {
                        url: "/billOfficer/adjustedInvoices",
                        name: "Full Payment Note Advise"
                    },
                    {
                        url: "/billOfficer/home",
                        name: "Partial Payment Note Advise"
                    }
                ]
            },
            {
                name: 'View Posted Payment Notes',
                url: '/billOfficer/postedPaymentNotes',
                icon:home
            },
            {
                name: 'View Settled Payment Notes',
                url: '/billOfficer/settledPaymentNotes',
                icon:home
            },
            {
                name: 'View Confirmed Payment',
                url: '/billOfficer/confirmedPaymentNotes',
                icon:home
            },
            {
                name: 'View Disputed Payments',
                url: '/billOfficer/disputedPayment',
                icon:home
            },
            {
                name: 'Statement',
                url: '/billOfficer/statement',
                icon:home,
                children: [
                    {
                        url: "/billOfficer/statement",
                        name: "View Current Customer Balance"
                    },
                    {
                        url: "/billOfficer/statement",
                        name: "Generate Customer Statement"
                    }
                ]
            },
            {
                name: 'View Customer Invoice Age',
                url: '/billOfficer/invoiceAge',
                icon:home
            },
            {
                name: 'Print Inventory Tracker',
                url: '/billOfficer/printInvetoryTracker',
                icon:home
            }
          
         
         
        
        ]
        const header = 'BILL OFFICER'
        return(
        <div id="wrapper">
            <Nav links ={links} header={header} />           
            <Content>
                {this.props.children}
            </Content>
        </div>
        )
    }
    
}
export default Wrapper