export const SET_NAVIGATION = "SET_NAVIGATION"
export const SET_TERRITORY = "SET_TERRITORY"
export const TERRITORY_LIST = "TERRITORY_LIST"
export const setNavigation = (nav)=>{
    return {
        type: SET_NAVIGATION,
        payload: nav
    }
}
export const setTerritory = (territory)=>{
    return {
        type: SET_TERRITORY,
        payload: territory
    }
}

export const territoryList = (territory,id)=>{
    let filter = territory.filter((item)=>(
        item._id !== id
    ))
    let territories = filter.map((territory)=>(
        {
            _id: territory._id,
            territoryName: territory.territoryName
        }
    ))
    return {
        type: TERRITORY_LIST,
        payload: territories
    }
}