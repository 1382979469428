import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import eye from "../../assets/icons/eye.svg";
import { decryptInfo } from "../../utils/auth";
import axios from "axios";
import Loader from "react-loader-spinner";
import { baseurl } from "../../config";
export default class RequestControlNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      displayError: false,
      error: "",
      status: false
    };
    this.handleShow = this.handleShow.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  handleShow() {
    this.setState({
      show: !this.state.show
    });
  }
  onSubmit(event) {
    event.preventDefault();
    this.setState({
      status: true
    });
    const user = decryptInfo("returnOfficer");
    axios({
      method: "post",
      url: `${baseurl}/returnClaim/billRequest`,
      data: {
        returnOrderId: this.props.row._id
      },
      headers: {
        Authorization: `Bearer ${user.headers.authorization}`
      }
    })
      .then(res => {
        this.setState({
          status: false,
          displayError: true,
          error: `${res.data.message}`
        });
        console.log(res);
      })
      .catch(error => {
        console.log(error);
        this.setState({
          status: false,
          displayError: true,
          error: error.response? error.response.data.message: "Request has failed"
        });
      });
  }
  render() {
    const { show, displayError, error, status } = this.state;
    const { row } = this.props;
    return (
      <React.Fragment>
        <Button
          variant="secondary"
          className="btn btn-xs"
          onClick={this.handleShow}
          disabled={
            row.billInitiated ||
            !(parseFloat(row.billAmount.internal.$numberDecimal) > 0)
              ? true
              : false
          }
        >
          <img src={eye} width="18" height="18" alt="eye" />
        </Button>

        <Modal show={show} onHide={this.handleShow} size="md">
          <Modal.Header closeButton>
            <Modal.Title>Request control number</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="form-group row"
              style={{ display: `${displayError ? "block" : "none"}` }}
            >
              <div className="col-12">
                <span className="text-danger"> {error}</span>
              </div>
            </div>
            <p>Request control number</p>
            <div
              className="form-group text-center "
              style={{ display: `${status ? "block" : "none"}` }}
            >
              <Loader type="Bars" color="#ff6805" width="50" height="30" />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.onSubmit}>
              Request
            </Button>
            <Button variant="secondary" onClick={this.handleShow}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}
