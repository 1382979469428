import React, { Component } from "react";
import close from "../../assets/icons/close.svg";

import $ from "jquery";
import { NavLink } from "react-router-dom";
import NavSub from "./nav_sub";
class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    document.title = "GN20";
    document.body.classList.add("fixed-left");
    this.$menuItem = $(".has_sub > a");
    function visibleSubMenuClose() {
      $(".has_sub").each(function() {
        var t = $(this);
        if (t.hasClass("nav-active")) {
          t.find("> ul").slideUp(300, function() {
            t.removeClass("nav-active");
          });
        }
      });
    }

    function adjustMainContentHeight() {
      // Adjust main content height
      var docHeight = $(document).height();
      if (docHeight > $(".body-content").height())
        $(".body-content").height(docHeight);
    }
    var $this = this;
    this.$body = $("body");
    this.$wrapper = $("#wrapper");
    this.$leftMenuButton = $(".button-menu-mobile");
    $this.$menuItem.on("click", function(event) {
      event.preventDefault();
      var parent = $(this).parent();
      var sub = parent.find("> ul");

      if (!$this.$body.hasClass("sidebar-collapsed")) {
        if (sub.is(":visible")) {
          sub.slideUp(300, function() {
            parent.removeClass("nav-active");
            $(".body-content").css({ height: "" });
            adjustMainContentHeight();
          });
        } else {
          visibleSubMenuClose();
          parent.addClass("nav-active");
          sub.slideDown(300, function() {
            adjustMainContentHeight();
          });
        }
      }
      return false;
    });
    this.$leftMenuButton.on("click", function(event) {
      event.preventDefault();
      $this.$body.toggleClass("fixed-left-void");
      $this.$wrapper.toggleClass("enlarged");
    });
  }
  componentWillUnmount() {
    document.body.classList.remove("fixed-left");
  }
  render() {
    return (
      <div className="left side-menu">
        <button
          type="button"
          className="button-menu-mobile button-menu-mobile-topbar open-left waves-effect"
        >
          <i className="mdi mdi-close">
            <img src={close} width="10" height="10" alt="icon" />
          </i>
        </button>

        <div className="left-side-logo d-block d-lg-none">
          <div className="text-center">
            <NavLink to="/" className="logo">
              <span style={{ color: "black" }} className=" text-uppercase ">
                Gazeti Network 20
              </span>
            </NavLink>
          </div>
        </div>

        <div className="sidebar-inner slimscrollleft">
          <div id="sidebar-menu">
            <ul>
              <li className="menu-title">{this.props.header}</li>
              {this.props.links.map(link => (
                <li className= {link.children?"has_sub":""}>
                  <NavLink to={link.url} activeClassName="active" exact>
                    <i className="dripicons-home">
                      <img src={link.icon} width="20" height="20" alt="icon" />
                    </i>
                    <span>{link.name}</span>
                  </NavLink>
                  {
                    link.children? <NavSub children={link.children} />: ""
                  }
                </li>

              ))}
            </ul>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    );
  }
}
export default Nav;
