import React from "react";
const CheckBox = ({ handleCheck, id, disabled,checked }) => {
  return (
    <input
      disabled={disabled}
      type="checkbox"
      className="custom-checkbox"
      onChange={(event) => handleCheck(event)}
      value = {id}
      checked = {checked}
    />
  );
};
export default CheckBox;
