export const filterTask = (data,name)=>{
    const filteredData = data.filter((task)=>(
        task.taskName === name
    ))
    return filteredData
}
export const toMilliSeconds = (time)=>{
    const hours = parseInt(time.split(':')[0])
    const minutes = parseInt(time.split(':')[1])
    return (hours*60*60*1000 + minutes*60*1000)
}
export const toTime = (time)=>{
    const hours = parseInt(parseInt(time)/(60*60*1000))
    const minutes = parseInt((parseInt(time)-hours*60*60*1000)/(1000*60))
    const timeHours  = hours < 10 ? `0${hours}`: hours
    const timeMinutes = minutes <10 ? `0${minutes}`: minutes
    return `${timeHours}:${timeMinutes}`
}
