import React, {Component} from 'react'
import {NavLink} from 'react-router-dom'
class NotFound extends Component{
    componentDidMount(){
        document.body.classList.add('fixed-left')
    }
    render(){
        return(
            <div className="account-pages">
            
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-8">
                        <div className="card">
                            <div className="card-block">
            
                                <div className="ex-page-content text-center">
                                    <h1 className="text-primary">4<i className="far fa-smile text-success ml-1 mr-1">0</i>4!</h1>
                                    <h4 className="">Sorry, page not found</h4><br />
            
                                    <NavLink className="btn btn-primary mb-5 waves-effect waves-light" to="/home">Back to Dashboard</NavLink>
                                </div>
            
                            </div>
                        </div>
                                            
                    </div>
                </div>
               
            </div>
        </div>        
        )
    }
    
}
export default NotFound