import {SET_NAVIGATION} from '../actions/index'
const initialState = {
    active: ''
}
const Nav = (state= initialState, action)=>{
    switch(action.type){
        case SET_NAVIGATION: {
            return {
                ...state,
                active: action.payload
            }
        }
        default: {
            return state
        }
    }

}
export default Nav