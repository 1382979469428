import React, { Component } from "react";
import Wrapper from "./wrapper";
import axios from "axios";
import { baseurl } from "../../config";
import { decryptInfo, getRoleName } from "../../utils/auth";
export default class ListPublisherEmployees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      role: null
    };
  }
  componentDidMount() {
    const user = decryptInfo("publisher");
    console.log(user);
    axios({
      method: "get",
      url: `${baseurl}/user/list/employees`,
      headers: {
        Authorization: `Bearer ${user.headers.authorization}`
      }
    })
      .then(res => {
        console.log(res);
        this.setState({
          data: res.data.data
        });
      })
      .catch(error => {
        console.log(error);
      });
  }
  render() {
    const { data,role} = this.state;
    let roles = data.map(role => role.role);
    roles = Array.from(new Set(roles));
    let filteredData = role?data.filter((row)=>row.role === role):data
    return (
      <Wrapper>
        <div className="page-content-wrapper ">
          <div className="container container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="row align-items-center">
                    <div className="col-md-8">
                      <h4 className="page-title m-0 ml-3">USERS</h4>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div class="col-12">
                <div class="card m-b-30">
                  <div class="card-body">
                    <h4 class="mt-0 header-title">HQ EMPLOYEES LIST </h4>
                    <div className="col-sm-12 text-right d-flex justify-content-end row">
                        <select className="custom-select col-sm-3 mb-3 bg-secondary text-white " onChange={
                            (event)=>this.setState({role: event.target.value})
                        } defaultValue={role} >
                            <option selected disabled>Select role</option>
                            {
                                roles.map((role,index)=>(
                                    <option key={index} value={role}>{getRoleName(role)}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div class="table-rep-plugin">
                      <div
                        class="table-responsive b-0"
                        data-pattern="priority-columns"
                      >
                        <table
                          id="tech-companies-1"
                          class="table  table-striped"
                        >
                          <thead>
                            <tr>
                              <th>Username</th>
                              <th data-priority="1">Role</th>
                              <th data-priority="1">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredData.map((row, index) => (
                              <tr key={index}>
                                <td>{row.userName}</td>
                                <td>{getRoleName(row.role)}</td>
                                <td>{row.status}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

