import React, { Component } from "react";
import Wrapper from "./wrapper";
import { decryptInfo } from "../../utils/auth";
import axios from "axios";
import { baseurl, week } from "../../config";
import { Chip, ChipSet } from "@material/react-chips";
import "@material/react-chips/dist/chips.css";
import Loader from "react-loader-spinner";
class AddProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayError: false,
      productName: "",
      releaseDays: [],
      publisherId: "", 
      data: {
        customerType: [],
        publishers: []
      },
      productCode: "",
      issueNumber: "",
      productStartDate: '',
      error: "",
      status: false
    };
    this.onHandleChange = this.onHandleChange.bind(this);
    this.onHandleSubmit = this.onHandleSubmit.bind(this);
  }

  componentDidMount() {
    const user = decryptInfo("admin");
    console.log(user);
    axios({
      method: "get",
      url: `${baseurl}/product/addInfo`,
      headers: {
        Authorization: `Bearer ${user.headers.authorization}`
      }
    })
      .then(res => {
        console.log(res);
        this.setState({
          data: res.data
        });
      })
      .catch(error => {
        console.log(error);
      });
  }
  onHandleSubmit(event) {
    const {
      productName,
      releaseDays,
      publisherId,
      productStartDate,
      issueNumber,
      productCode
    } = this.state;
    event.preventDefault();
    console.log(releaseDays)

    this.setState({
      status: true
    });
    const user = decryptInfo("admin");
    axios({
      method: "post",
      url: `${baseurl}/product/new`,
      data: {
        productName,
        releaseDays,
        publisherId,
        productStartDate,
        issueNumber,
        productCode
      },
      headers: {
        Authorization: `Bearer ${user.headers.authorization}`
      }
    })
      .then(res => {
        this.setState({
          status: false,
          displayError: true,
          error: `${res.data.message}`
        });
        console.log(res);
        this.props.history.push('/admin/productList')
      })
      .catch(error => {
        console.log(error);
        this.setState({
          status: false,
          displayError: true,
          error: error.response? error.response.data.message: "Request has failed"
        });
      });
  }
  onHandleChange(event) {
    console.log(this.state);
    this.setState({
      [event.target.name]: event.target.value,
      displayError: false
    });
  }

  render() {
    const {
      displayError,
      error,
      status,
      productName,
      releaseDays,
      publisherId,
      data,
      productStartDate,
      issueNumber,
      productCode
    } = this.state;
    return (
      <Wrapper>
        <div className="page-content-wrapper ">
          <div className="container container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="row align-items-center">
                    <div className="col-md-8">
                      <h4 className="page-title m-0  ml-3">Add product</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card m-b-30">
                  <div className="card-body">
                    <h4 className="mt-0 header-title">Add product</h4>
                    <p className="text-muted m-b-30 font-14"></p>
                    <div
                      className="form-group row"
                      style={{ display: `${displayError ? "block" : "none"}` }}
                    >
                      <div className="col-12">
                        <span className="text-danger"> {error}</span>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="input"
                        className="col-sm-3 col-form-label"
                      >
                        Product name
                      </label>
                      <div className="col-sm-4">
                        <input
                          className="form-control"
                          name="productName"
                          type="text"
                          value={productName}
                          onChange={this.onHandleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="input"
                        className="col-sm-3 col-form-label"
                      >
                        Product Code
                      </label>
                      <div className="col-sm-4">
                        <input
                          className="form-control"
                          name="productCode"
                          type="text"
                          value={productCode}
                          onChange={this.onHandleChange}
                        />
                      </div>
                    </div>
                    <div
                      className="form-group row"
                    >
                      <label className="col-sm-3 col-form-label">
                        Select release days
                      </label>
                      <div className="col-sm-9">
                        <ChipSet
                          filter
                          selectedChipIds={releaseDays}
                          handleSelect={releaseDays => this.setState({ releaseDays })}
                        >
                          {week.map((day, index) => (
                            <Chip className="text-capitalize"
                              id={`${day.index}`}
                              key={index}
                              label={day.day}
                            />
                          ))}
                        </ChipSet>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="input"
                        className="col-sm-3 col-form-label"
                      >
                        Product Start Date
                      </label>
                      <div className="col-sm-4">
                        <input
                          className="form-control"
                          name="productStartDate"
                          type="date"
                          value={productStartDate}
                          onChange={this.onHandleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="input"
                        className="col-sm-3 col-form-label"
                      >
                        Product Issue Number
                      </label>
                      <div className="col-sm-4">
                        <input
                          className="form-control"
                          name="issueNumber"
                          type="number"
                          value={issueNumber}
                          onChange={this.onHandleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        Select publisher
                      </label>
                      <div className="col-sm-4">
                        <select
                          className="custom-select"
                          name="publisherId"
                          defaultValue={publisherId}
                          onChange={this.onHandleChange}
                        >
                          <option selected disabled>
                            Select publisher
                          </option>
                          {data.publishers.map((publisher, index) => (
                            <option key={index} value={publisher._id}>
                              {publisher.userName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div
                      className="form-group text-center "
                      style={{ display: `${status ? "block" : "none"}` }}
                    >
                      <Loader
                        type="Bars"
                        color="#ff6805"
                        width="50"
                        height="30"
                      />
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2"></div>
                      <div className="col-sm-4 text-right">
                        <button
                          className="btn button2 text-white"
                          onClick={this.onHandleSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default AddProduct;
