import React, { Component } from "react";

import Wrapper from "./wrapper";
import { decryptInfo } from "../../utils/auth";
import axios from "axios";
import { baseurl } from "../../config";
import "@material/react-chips/dist/chips.css";
import Loader from "react-loader-spinner";
class ConfirmDriverHandleover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayError: false,
      error: "",
      status: false,
      parcelNumber: "",
      weight: "",
    };
    this.onHandleChange = this.onHandleChange.bind(this);
    this.onHandleSubmit = this.onHandleSubmit.bind(this);
  }
  onHandleSubmit(event) {
    event.preventDefault();
    const { parcelNumber, weight } = this.state;

    this.setState({
      status: true,
    });
    const user = decryptInfo("driver");
    axios({
      method: "post",
      url: `${baseurl}/driver/confirm`,
      data: {
        parcelNumber,
        weight,
      },
      headers: {
        Authorization: `Bearer ${user.headers.authorization}`,
      },
    })
      .then((res) => {
        this.setState({
          status: false,
          displayError: true,
          error: `${res.data.message}`,
        });
        console.log(res);
        this.props.history.push("/driver/list");
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          status: false,
          displayError: true,
          error: error.response
            ? error.response.data.message
            : "Request has failed",
        });
      })
  }
  onHandleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
      displayError: false,
    });
  }

  render() {
    const { displayError, parcelNumber, weight, error, status } = this.state;
    return (
      <Wrapper>
        <div className="page-content-wrapper ">
          <div className="container container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="row align-items-center">
                    <div className="col-md-8">
                      <h4 className="page-title m-0 ml-3">Confirm handover</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card m-b-30">
                  <div className="card-body">
                    <h4 className="mt-0 header-title">Confirm handover</h4>
                    <p className="text-muted m-b-30 font-14"></p>
                    <div
                      className="form-group row"
                      style={{ display: `${displayError ? "block" : "none"}` }}
                    >
                      <div className="col-12">
                        <span className="text-danger"> {error}</span>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="input"
                        className="col-sm-2 col-form-label"
                      >
                        Parcel number
                      </label>
                      <div className="col-sm-4">
                        <input
                          className="form-control"
                          name="parcelNumber"
                          type="text"
                          value={parcelNumber}
                          onChange={this.onHandleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="input"
                        className="col-sm-2 col-form-label"
                      >
                        Weight
                      </label>
                      <div className="col-sm-4">
                        <input
                          className="form-control"
                          type="text"
                          name="weight"
                          value={weight}
                          onChange={this.onHandleChange}
                        />
                      </div>
                    </div>
                    <div
                      className="form-group text-center "
                      style={{ display: `${status ? "block" : "none"}` }}
                    >
                      <Loader
                        type="Bars"
                        color="#ff6805"
                        width="50"
                        height="30"
                      />
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-6 text-right">
                        <button
                          className="btn button2 text-white"
                          onClick={this.onHandleSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default ConfirmDriverHandleover;
