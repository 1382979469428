import React, { Component } from "react";
import Wrapper from "./wrapper";
import axios from "axios";
import { baseurl } from "../../config";
import { decryptInfo, formatDate } from "../../utils/auth";
export default class Statement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      role: null,
    };
  }
  componentDidMount() {
    const user = decryptInfo("billOfficer");
    console.log(user);
    /*
    axios({
      method: "get",
      url: `${baseurl}/bill/settledBills`,
      headers: {
        Authorization: user ? `Bearer ${user.headers.authorization}` : ""
      }
    })
      .then(res => {
        console.log(res);
        this.setState({
          data: res.data.data
        });
      })
      .catch(error => {
        console.log(error);
      });*/
  }
  render() {
    const { data, role } = this.state;
    return (
      <Wrapper>
        <div className="page-content-wrapper ">
          <div className="container container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="row align-items-center">
                    <div className="col-md-8">
                      <h4 className="page-title m-0 ml-3">BILL OFFICER</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div class="col-12">
                <div class="card m-b-30">
                  <div class="card-body">
                    <h4 class="mt-0 header-title">
                    STATEMENT
                    </h4>
                    <div class="table-rep-plugin">
                      <div
                        class="table-responsive b-0"
                        data-pattern="priority-columns"
                      >
                        <table
                          id="tech-companies-1"
                          class="table  table-striped"
                        >
                          <thead>
                            <tr>
                            
                            </tr>
                          </thead>
                          <tbody></tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}
