import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { formatDate } from "../../utils/auth";
import eye from "../../assets/icons/eye.svg";
export default class ViewPublisher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      displayError: false,
      error: "",
      status: false,
      data: []
    };
    this.handleShow = this.handleShow.bind(this);
  }
  handleShow() {
    this.setState({
      show: !this.state.show
    });
  }

  render() {
    const { show} = this.state;
    const { row } = this.props;

   
    return (
      <React.Fragment>
        <Button
          variant="secondary"
          className="btn btn-xs btn-secondary"
          disabled={this.props.disabled}
          onClick={this.handleShow}
        >
          <img src={eye} width="15" height="15" alt="eye" />
        </Button>

        <Modal show={show} onHide={this.handleShow} size="md">
          <Modal.Header closeButton>
            <Modal.Title className="text-uppercase">
              Publisher Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table id="tech-companies-1" class="table  table-striped">
              <tbody>

                <tr>
                  <th>Created At</th>
                  <td>{formatDate(row.createdAt)}</td>
                </tr>
                <tr>
                  <th>Publisher ID</th>
                  <td className="text-uppercase">{row._id}</td>
                </tr>
                <tr>
                  <th>Publisher Name</th>
                  <td>{row.userName}</td>
                </tr>
                <tr>
                  <th>Phone Number</th>
                  <td>{row.phoneNumber}</td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{row.email}</td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td>{row.status}</td>
                </tr>
            
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}