export const contents = [
    {
        name: 'Admin',
        url: '/admin/home'
    },
    {
        name: 'Publisher',
        url: '/publisher/home'
    },
    {
        name: 'print order maker',
        url: '/printOrderMaker/home'
    },
    {
        name: 'Print manager',
        url: '/printManager/home'
    },
    {
        name: 'Night shift supervisor',
        url: '/nightShiftSupervisor/home'
    },
    {
        name: 'driver',
        url: '/driver/home'
    },
    {
        name: 'customer',
        url: '/directCustomer/home'
    },
    {
        name: 'return officer',
        url: '/returnOfficer/home'
    },
    {
        name: 'store officer',
        url: '/storeOfficer/home'
    },
    {
        name: 'bill officer',
        url: '/billOfficer/home'
    },
    {
        name: 'finance manager',
        url: '/financeManager/home'
    },
    {
        name: 'circulation manager',
        url: '/circulationManager/home'
    }
]