import React,{Component} from 'react'
import {Modal,Button} from 'react-bootstrap'
import feedback from '../../assets/icons/feedback.svg'
import folder from '../../assets/icons/folder.svg'
import screenshot from '../../assets/icons/screenshot.svg'
class ReportBug extends Component{
    constructor(props){
        super(props)
        this.state = {
            show: false
        }
        this.handleShow = this.handleShow.bind(this)
    }
    handleShow(){
        this.setState({
            show: !this.state.show
        })
    }
    render(){
        const {show} = this.state
        return(
            <React.Fragment>
                <Button style={style.button} onClick={this.handleShow}>
                    <i><img src={feedback} alt="icon" width="20" height="20"/></i>Feedback
                </Button>

                <Modal show={show} {...this.props}  style={style.modal}
                aria-labelledby="contained-modal-title-vcenter"
                onHide={this.handleShow} centred> 
                    <Modal.Header style={style.modal}>
                    <Modal.Title id="contained-modal-title-vcenter">Operation Module Feedback</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Thank you for taking your time to provide feedback</p>
                        <form >
                                <div className="form-group">
                                    <label for="summary">Type</label><br />
                                    <small>Choose type of feedback you are submitting</small>
                                    <select name="type"  className="form-control">
                                        <option>General feeback</option>
                                        <option>System issue</option>
                                        <option>Feature request</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label for="message">Enter your message below</label>
                                    <textarea name="message"  className="form-control" id="message"></textarea>
                                </div>
                                <div className="form-group" >
                                     <label for="message">Attachment</label>
                                     <div  className="d-flex flex-row col-lg-9 justify-content-between">
                                        <div style={style.attachment}>
                                            <i><img src={folder} alt="icon" width="20" height="20"/>
                                            &nbsp;&nbsp;
                                            </i>From storage
                                            <input type="file" name="attachment" style = {style.input} />
                                        </div>
                                        <div style={style.attachment}>
                                            <i><img src={screenshot} alt="icon" width="20" height="20"/></i>&nbsp;&nbsp;From screenshot
                                            <input type="file" name="attachment" style = {style.input} />
                                        </div>
                                     </div>
                                 </div> 
                                 <p>Are you satisfied with your exprience?</p>                        
                                <div className="form-check">
                                    <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" />
                                    <label class="form-check-label" for="exampleRadios1">
                                        Neutral
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" />
                                    <label class="form-check-label" for="exampleRadios1">
                                        Yes
                                    </label>
                                </div>
                                <div className="form-check">
                                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" />
                                    <label class="form-check-label" for="exampleRadios1">
                                        No
                                    </label>
                                </div>
                            </form>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleShow}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={this.handleShow}>
                        Submit feedback
                    </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
   
    }
}
const style = {
    button:{
        padding:0,
        background:'none',
        border:'none',
        color: '#696c80'
    },
    attachment: {
        border: '1px solid #ccc',
        padding:'3%',
        position: 'relative',
        overflow:'hidden'
    },
    input: {
        position:'absolute',
        top: 0,
        right: 0,
        opacity:0
    },
    modal:{
        fontFamily: 'Open Sans'
    }

}
export default ReportBug;