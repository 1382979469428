import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import link from "../../assets/icons/eye.svg";
import { formatDate } from "../../utils/auth";
export default class ViewSupplyDiscrepancy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      displayError: false,
      error: "",
      status: false,
      data: [],
    };
    this.handleShow = this.handleShow.bind(this);
  }
  handleShow() {
    this.setState({
      show: !this.state.show,
    });
  }

  render() {
    const { show } = this.state;
    const { row } = this.props;
    return (
      <React.Fragment>
        <Button
          variant="secondary"
          className="btn btn-xs"
          onClick={this.handleShow}
          disabled={show ? true : false}
        >
          <img src={link} width="18" height="18" alt="eye" />
        </Button>

        <Modal show={show} onHide={this.handleShow} size="lg">
          <Modal.Header closeButton>
            <Modal.Title> Detailed Supply Discrepancy View</Modal.Title>
          </Modal.Header>
          <Modal.Body className="view-bill-details">
            <div className="col-sm-12 row d-flex flex-row justify-content-between">
              <h4> Supply Discrepancy View</h4>
              <div></div>
            </div>
            <div className="col-sm-12 row d-flex flex-row justify-content-between">
              <table className="table table-striped col-sm-5">
                <thead>
                  <tr>
                    <th colSpan="2">Customer</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Account No</td>
                    <td>******</td>
                  </tr>
                  <tr>
                    <td>Name</td>
                    <td>{row.customerName}</td>
                  </tr>
                  <tr>
                    <td>Category</td>
                    <td>{row.category}</td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-striped col-sm-5">
                <thead>
                  <tr>
                    <th colSpan="2">Discrepancy</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Doc No</td>
                    <td>******</td>
                  </tr>
                  <tr>
                    <td>Type</td>
                    <td>{row.type}</td>
                  </tr>
                  <tr>
                    <td>Invoice Date</td>
                    <td>{formatDate(row.createdAt)}</td>
                  </tr>
                  <tr>
                    <td>Created By</td>
                    <td>Customer</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-sm-12 row d-flex flex-row justify-content-between">
              <table className="table table-striped col-sm-5">
                <thead>
                  <tr>
                    <th colSpan="2">Location</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Territory</td>
                    <td>{row.territoryName}</td>
                  </tr>
                  <tr>
                    <td>Route</td>
                    <td>{row.routeName}</td>
                  </tr>
                  <tr>
                    <td>Drop Point</td>
                    <td>{row.dropPointName}</td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-striped  col-sm-5">
                <thead>
                  <tr>
                    <th colSpan="2">Product</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>{row.publisherName}</td>
                  </tr>
                  <tr>
                    <td>Product Name</td>
                    <td>{row.productName}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-sm-12 row d-flex flex-row justify-content-between">
              <table className="table table-striped col-sm-5">
                <thead>
                  <tr>
                    <th colSpan="2">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>New/Reconciled</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Reconciliation ID</td>
                    <td></td>
                  </tr>
                
                </tbody>
              </table>
              <table className="table table-striped  col-sm-5">
                <thead>
                  <tr>
                    <th colSpan="2">Quantities</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Confirmed</td>
                    <td>{row.supplied}</td>
                  </tr>
                  <tr>
                    <td>Print Order</td>
                    <td>{row.printOrder}</td>
                  </tr>
                  <tr>
                    <td>Discrepancy</td>
                    <td>{row.discrepancy}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-sm-12 row d-flex flex-row justify-content-between">
              <table className="table table-striped col-sm-5">
             
              </table>
              <table className="table table-striped  col-sm-5">
                <thead>
                  <tr>
                    <th colSpan="2">Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Unit Price</td>
                    <td>{row.unitPrice}</td>
                  </tr>
                  <tr>
                    <td>Discount</td>
                    <td>****</td>
                  </tr>
                  <tr>
                    <td>Tax</td>
                    <td>****</td>
                  </tr>
                  <tr>
                    <td>Net Value</td>
                    <td>{row.value}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
