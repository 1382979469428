import React, {Component} from 'react'
import {NavLink} from 'react-router-dom'
import Loader from 'react-loader-spinner'
import axios from 'axios'
import {baseurl} from '../../config'
import {encryptInfo} from '../../utils/auth'
class Login extends Component{
    constructor(props){
        super(props)
        this.state = {
            email: "",
            password: "",
            displayError: false,
            error: "",
            remember_me: false,
            status: false
        }
        this.onInputChange = this.onInputChange.bind(this)
        this.onCheck = this.onCheck.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }
    componentWillMount(){
        document.body.style.background = "#fff"
        document.body.classList.add('fixed-left')
    }
    onInputChange(event){
        event.preventDefault()
        this.setState({
            [event.target.name]: event.target.value,
            displayError: false
        })

    }
    onCheck(event){
        this.setState({
            remember_me: !this.state.remember_me
        })

    }
    onSubmit(event){
        event.preventDefault()
        const {email,password,country} = this.state
        if(email === "" || password === "" || country === ""){
            this.setState({
                displayError: true,
                error: 'Please fill all fields'
            })
        }
        else{
            this.setState({
                status: true
            })
            console.log(this.state)
            axios({
                method:'post',
                url: `${baseurl}/user/login`,
                data: {
                        email: email,
                        password: password,
                        clientType: 'browser'
                    },
            }).then((res)=>{
                this.setState({
                    status: false
                })
                console.log(res)
                encryptInfo(res.data,res.data.data.role)
                this.props.history.push(
                    `/${res.data.data.role || 'publisher' }/home`
                )
              
            
            }).catch((error)=>{               
                this.setState({
                    displayError: true,
                    status: false,
                    error: error.response? error.response.data.message: "Request has failed"
                })
            })
        }

    }

    
    render(){
        const {displayError,email,password,error,status,remember_me} = this.state
        return(
            <div className="account-pages login bg-primary">
            
            <div className="">
                <div className="row  align-items-center">
                    <div className=" col-lg-4 offset-lg-4 bg-white">
                        <div className=" mb-0">
                            <div className="card-body">
                                <div className="p-2">                                    
                                    
                                     <span className="font-18 font-weight-bold">Sign in</span>
                                    
                                </div>                               
        
                                <div className="p-2">
                                    <form className="form-horizontal m-t-20" action="index.html">
                                        <div className="form-group row" 
                                            style={{display:`${displayError?'block':'none'}`}}
                                        >
                                            <div className ="col-12">
                                                <span className="text-danger"> {error}</span>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-12">
                                                <input className="form-control" type="email" name="email" placeholder="Email"
                                                    value={email} onChange= {this.onInputChange}
                                                />
                                            </div>
                                        </div>
        
                                        <div className="form-group row">
                                            <div className="col-12">
                                                <input className="form-control" type="password" name="password" placeholder="Password"
                                                    value={password} onChange= {this.onInputChange}
                                                />
                                            </div>
                                        </div>
        
                                        <div className="form-group row">
                                            <div className="col-12">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" name="remember_me"
                                                    checked={remember_me} onChange={this.onCheck}
                                                    className="custom-control-input" id="customCheck1" />
                                                    <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div className="form-group text-center row m-t-20">
                                            <div className="col-12">
                                                <button className="btn btn-primary btn-block waves-effect waves-light" onClick={this.onSubmit} type="submit">Log In</button>
                                            </div>
                                        </div>
                                        <div className="form-group text-center "
                                            style={{display:`${status?'block':'none'}`}}
                                        >
                                            <Loader type="Bars" color="#ff6805" width="50" height="30" />
                                        </div>
        
                                        <div className="form-group m-t-10 mb-0 row">
                                            <div className="col-sm-7 m-t-20">        
                                                <NavLink to="/" className="text-muted text-primary"><i className="mdi mdi-lock"></i> Home</NavLink>
                                            </div>
                                            
                                        </div>
                                    </form>
                                </div>
        
                            </div>
                        </div>
                    </div>            
                </div>
              
            </div>
        </div>          
        )
    }
    
}
export default Login