import React, { Component } from "react";
import Wrapper from "./wrapper";
import axios from "axios";
import { baseurl } from "../../config";
import { decryptInfo, formatDate } from "../../utils/auth";
import { roles } from "../../config";
import Loader from "react-loader-spinner";
class MakeOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      role: null,
      orders: [],
      status: false,
      error: "",
      displayError: false
    };
    this.handleCheck = this.handleCheck.bind(this);
    this.onHandleSubmit = this.onHandleSubmit.bind(this);
  }
  componentDidMount() {
    const user = decryptInfo("publisher");
    console.log(user);
    axios({
      method: "get",
      url: `${baseurl}/order/list/publisher`,
      headers: {
        Authorization: `Bearer ${user.headers.authorization}`
      }
    })
      .then(res => {
        console.log(res);
        this.setState({
          data: res.data.data
        });
      })
      .catch(error => {
        console.log(error);
      });
  }
  onHandleSubmit(event) {
    event.preventDefault();
    const { orders, role } = this.state;

    this.setState({
      status: true
    });
    const user = decryptInfo("printOrderMaker");
    axios({
      method: "post",
      url: `${baseurl}/printOrderMaker/new`,
      data: {
        orders: orders,
        publicationDate: role
      },
      headers: {
        Authorization: `Bearer ${user.headers.authorization}`
      }
    })
      .then(res => {
        this.setState({
          status: false,
          displayError: true,
          error: `${res.data.message}`
        });
        console.log(res);
      })
      .catch(error => {
        console.log(error);
        this.setState({
          status: false,
          displayError: true,
          error: error.response.data.message
        });
      });
  }
  handleCheck(id) {
    let orders = [];
    console.log(this.state.orders);
    if (this.state.orders.includes(id)) {
      orders = this.state.orders.filter(order => order !== id);
    } else {
      orders = [id, ...this.state.orders];
    }
    this.setState({
      orders
    });
  }
  render() {
    const { data, role, status, error, displayError } = this.state;
    console.log(role);
    let filteredRole = [];
    if (!role) {
      filteredRole = data;
    } else {
      filteredRole = data.filter(
        row => Date.parse(row.publicationDate) === Date.parse(role)
      );
    }
    return (
      <Wrapper>
        <div className="page-content-wrapper ">
          <div className="container container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="row align-items-center">
                    <div className="col-md-8">
                      <h4 className="page-title m-0 ml-3">ORDERS SUCCESSFUL</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div class="col-12">
                <div class="card m-b-30">
                  <div class="card-body">
                    <h4 class="mt-0 header-title">ORDERS SUCCESSFUL</h4>
                    <div class="col-sm-12 text-right mb-3 pr-3">
                      <input
                        class="form-control"
                        type="date"
                        onChange={event =>
                          this.setState({
                            role: event.target.value
                          })
                        }
                        value={role || ""}
                        className="custom-select bg-secondary text-white col-sm-3"
                        id="example-date-input"
                      />
                    </div>
                    <div class="table-rep-plugin">
                      <div
                        class="table-responsive b-0"
                        data-pattern="priority-columns"
                      >
                        <table
                          id="tech-companies-1"
                          class="table  table-striped"
                        >
                          <thead>
                            <tr>
                              <th>Publication Date</th>
                              <th>Customer code</th>
                              <th data-priority="1">Product code</th>
                              <th data-priority="3">Quantity</th>
                              <th data-priority="1">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredRole.map((row, index) => (
                              <tr key={index}>
                                <td>{formatDate(row.publicationDate)}</td>
                                <td>{row.customerCode}</td>
                                <td>{row.productCode}</td>
                                <td>{row.productQuantity}</td>
                                <td>{row.status}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default MakeOrder;
