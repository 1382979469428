import React, { Component } from "react";
import Wrapper from "./wrapper";
import axios from "axios";
import { baseurl } from "../../config";
import { decryptInfo} from "../../utils/auth";
import Loader from "react-loader-spinner";
import OrderFailedTable from "./order_failed_table";
export default class NoDataEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      role: null,
      orders: [],
      status: false,
      error: "",
      displayError: false
    };
  }
  componentDidMount() {
    const user = decryptInfo("publisher");
    console.log(user);
    axios({
      method: "get",
      url: `${baseurl}/publisher/noDataEntry`,
      headers: {
        Authorization: `Bearer ${user.headers.authorization}`
      }
    })
      .then(res => {
        console.log(res);
        this.setState({
          data: res.data.data
        });
      })
      .catch(error => {
        console.log(error);
      });
  }
  render() {
    const { data, role, status, error, displayError } = this.state;
    console.log(role);
    let filteredRole = [];
    if (!role) {
      filteredRole = data;
    } else {
      filteredRole = data.filter(
        row => Date.parse(row.order.publicationDate) === Date.parse(role)
      );
    }
    return (
      <Wrapper>
        <div className="page-content-wrapper ">
          <div className="container container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="row align-items-center">
                    <div className="col-md-8">
                      <h4 className="page-title m-0 ml-3">publisher</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div class="col-12">
                <div class="card m-b-30">
                  <div class="card-body">
                    <h4 class="mt-0 header-title">NO DATA ENTRY INCIDENTS</h4>
                    <OrderFailedTable filteredRole ={filteredRole} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}
