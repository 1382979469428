import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { formatDate, decryptInfo } from "../../utils/auth";
import axios from "axios";
import { baseurl } from "../../config";
export default class SelectedOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      displayError: false,
      error: "",
      status: false
    };
    this.handleShow = this.handleShow.bind(this);
    this.onHandleSubmit = this.onHandleSubmit.bind(this);
  }
  handleShow() {
    this.setState({
      show: !this.state.show
    });
  }
  onHandleSubmit(data) {
    if (!(data.length > 0)) {
      this.setState({
        error: "Please select orders",
        displayError: true
      });
    } else {
      let orders = data.map(row => row._id);
      this.setState({
        status: true
      });
      const user = decryptInfo("printOrderMaker");
      axios({
        method: "post",
        url: `${baseurl}/printOrderMaker/new`,
        data: {
          orders: orders,
          publicationDate: this.props.date
        },
        headers: {
          Authorization: `Bearer ${user.headers.authorization}`
        }
      })
        .then(res => {
          this.setState({
            status: false,
            displayError: true,
            error: `${res.data.message}`
          });
          console.log(res);
          window.location.reload(false)
        })
        .catch(error => {
          console.log(error);
          this.setState({
            status: false,
            displayError: true,
            error: error.response? error.response.data.message: "Request has failed"
          });
        });
    }
  }
  render() {
    const { show, displayError, error, status } = this.state;
    const { row } = this.props;
    console.log(row);
    const data = row.filter(order => order.isChecked === true);
    return (
      <React.Fragment>
        <Button
          variant="secondary"
          className="btn btn-primary"
          disabled={this.props.disabled}
          onClick={this.handleShow}
        >
          Create Print Order
        </Button>

        <Modal show={show} onHide={this.handleShow} size="lg">
          <Modal.Header closeButton>
            <Modal.Title className="text-uppercase">Order Summary</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="form-group text-center "
              style={{ display: `${status ? "block" : "none"}` }}
            >
              <Loader type="Bars" color="#ff6805" width="50" height="30" />
            </div>
            <div
              className="form-group row"
              style={{ display: `${displayError ? "block" : "none"}` }}
            >
              <div className="col-12">
                <span className="text-danger"> {error}</span>
              </div>
            </div>
            <table id="tech-companies-1" class="table  table-striped">
              <thead>
                <tr>
                  <th>Publication Date</th>
                  <th>Customer Name</th>
                  <th>Product Name</th>
                  <th>Product Quantity</th>
                  <th>Price</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row, index) => (
                  <tr key={index}>
                    <td>{formatDate(row.publicationDate)}</td>
                    <td>{row.customerName}</td>
                    <td>{row.productName}</td>
                    <td>{row.productQuantity}</td>
                    <td>TSH {row.price * row.productQuantity}</td>
                    <td>{row.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={event => {
                event.preventDefault();
                this.onHandleSubmit(data);
              }}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}
