import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import link from "../../assets/icons/link.svg";
import { decryptInfo } from "../../utils/auth";
import { baseurl } from "../../config";
import axios from "axios";
import Loader from "react-loader-spinner";
export default class AddLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      displayError: false,
      error: "",
      status: false,
      data: {
        territories: [],
        routes: []
      },
      territoryId: "",
      routeId: "",
      dropPoint: "",
      recId: ""
    };
    this.handleShow = this.handleShow.bind(this);
    this.onHandleChange = this.onHandleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {
    const user = decryptInfo("admin");
    console.log(user);
    axios({
      method: "get",
      url: `${baseurl}/user/publisherLink`,
      headers: {
        Authorization: `Bearer ${user.headers.authorization}`
      }
    })
      .then(res => {
        console.log(res);
        this.setState({
          data: res.data.data
        });
      })
      .catch(error => {
        console.log(error);
      });
  }
  handleShow() {
    this.setState({
      show: !this.state.show
    });
  }
  onHandleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }
  onSubmit(event) {
    event.preventDefault();
    const { territoryId, routeId, dropPoint, recId } = this.state;
    const user = decryptInfo("admin");

    this.setState({
      status: true
    });
    axios({
      method: "post",
      url: `${baseurl}/user/publisherLink`,
      data: {
        territoryId,
        routeId,
        dropPoint,
        recId,
        userId: this.props.row._id
      },
      headers: {
        Authorization: `Bearer ${user.headers.authorization}`
      }
    })
      .then(res => {
        this.setState({
          status: false,
          displayError: true,
          error: `${res.data.message}`
        });
        console.log(res);
      })
      .catch(error => {
        console.log(error);
        this.setState({
          status: false,
          displayError: true,
          error: error.response? error.response.data.message: "Request has failed"
        });
      });
  }
  render() {
    const {
      show,
      displayError,
      error,
      status,
      data,
      territoryId,
      routeId,
      dropPoint,
      recId
    } = this.state;
    let routes =
      territoryId !== ""
        ? data.routes.filter(route => route.territoryId === territoryId)
        : [];
    let route = data.routes.find(route => route._id === routeId);
    let selectedDropPoint = route ? route.dropPoint : [];
    return (
      <React.Fragment>
        <Button
          variant="secondary"
          className="btn btn-xs"
          onClick={this.handleShow}
          disabled={this.props.row.profileStatus === "done" ? true : false}
        >
          <img src={link} width="18" height="18" alt="eye" />
        </Button>

        <Modal show={show} onHide={this.handleShow} size="md">
          <Modal.Header closeButton>
            <Modal.Title>Link Customer To Publisher</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form encType="multipart/form-data">
              <div
                className="form-group row"
                style={{ display: `${displayError ? "block" : "none"}` }}
              >
                <div className="col-12">
                  <span className="text-danger"> {error}</span>
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="input" className="col-sm-4 col-form-label">
                  Select territory
                </label>

                <div className="col-sm-8">
                  <select
                    name="territoryId"
                    className="custom-select"
                    onChange={this.onHandleChange}
                    defaultValue={territoryId}
                  >
                    <option selected disabled>
                      Select territory
                    </option>
                    {data.territories.map((territory, index) => (
                      <option key={index} value={territory._id}>
                        {territory.territoryName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="input" className="col-sm-4 col-form-label">
                  Select route
                </label>

                <div className="col-sm-8">
                  <select
                    name="routeId"
                    className="custom-select"
                    onChange={this.onHandleChange}
                    defaultValue={routeId}
                  >
                    <option selected disabled>
                      Select route
                    </option>
                    {routes.map((route, index) => (
                      <option key={index} value={route._id}>
                        {route.routeName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="input" className="col-sm-4 col-form-label">
                  Select drop point
                </label>

                <div className="col-sm-8">
                  <select
                    name="dropPoint"
                    className="custom-select"
                    onChange={this.onHandleChange}
                    defaultValue={dropPoint}
                  >
                    <option selected>Select drop point</option>
                    {selectedDropPoint.map((route, index) => (
                      <option key={index} value={route._id}>
                        {route.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="input" className="col-sm-4 col-form-label">
                  Enter reconciliation ID
                </label>

                <div className="col-sm-8">
                  <input
                    type="text"
                    name="recId"
                    className="form-control"
                    value={recId}
                    onChange={this.onHandleChange}
                  />
                </div>
              </div>

              <div
                className="form-group text-center "
                style={{ display: `${status ? "block" : "none"}` }}
              >
                <Loader type="Bars" color="#ff6805" width="50" height="30" />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.onSubmit}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}
