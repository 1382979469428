import React, { Component } from "react";
import Wrapper from "./wrapper";
import { decryptInfo } from "../../utils/auth";
import axios from "axios";
import { baseurl,week } from "../../config";
import Loader from "react-loader-spinner"
class AddPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {        
      displayError: false,
      productId: '',
      customerTypeId: '',
      price: '',
      data: {
          customerType: [],
          products: []
      },
      error: "",
      status: false
    };
    this.onHandleChange = this.onHandleChange.bind(this);
    this.onHandleSubmit = this.onHandleSubmit.bind(this);
  }

  componentDidMount() {
    const user = decryptInfo("admin");
    console.log(user);
    axios({
      method: "get",
      url: `${baseurl}/product/priceInfo`,
      headers: {
        Authorization: `Bearer ${user.headers.authorization}`
      }
    })
      .then(res => {
        console.log(res);
        this.setState({
          data: res.data
        });
      })
      .catch(error => {
        console.log(error);
      });
  }
  onHandleSubmit(event) {
    const {

        price,customerTypeId,productId
    } = this.state;
    event.preventDefault();

    this.setState({
      status: true
    });
    const user = decryptInfo("admin");
    axios({
      method: "post",
      url: `${baseurl}/product/newPrice`,
      data: {
        price,
        customerTypeId,
        productId
      },
      headers: {
        Authorization: `Bearer ${user.headers.authorization}`
      }
    })
      .then(res => {
        this.setState({
          status: false,
          displayError: true,
          error: `${res.data.message}`
        });
        console.log(res);
      })
      .catch(error => {
        console.log(error);
        this.setState({
          status: false,
          displayError: true,
          error: error.response? error.response.data.message: "Request has failed"
        });
      });
  }
  onHandleChange(event) {
    console.log(this.state);
    this.setState({
      [event.target.name]: event.target.value,
      displayError: false
    });
  }

  render() {
    const {
      price,
      productId,
      customerTypeId, 
      displayError,
      error,
      status,     
      data
    } = this.state;
    return (
      <Wrapper>
        <div className="page-content-wrapper ">
          <div className="container container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="row align-items-center">
                    <div className="col-md-8">
                      <h4 className="page-title m-0 ml-3">Set Product price</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card m-b-30">
                  <div className="card-body">
                    <h4 className="mt-0 header-title">Set Product price</h4>
                    <p className="text-muted m-b-30 font-14"></p>
                    <div
                      className="form-group row"
                      style={{ display: `${displayError ? "block" : "none"}` }}
                    >
                      <div className="col-12">
                        <span className="text-danger"> {error}</span>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="input"
                        className="col-sm-3 col-form-label"
                      >
                        Select product
                      </label>
                      <div className="col-sm-4">
                      <select
                          className="custom-select"
                          name="productId"
                          defaultValue={productId}
                          onChange={this.onHandleChange}
                        >
                          <option selected>Select  product</option>
                            {
                                data.products.map((product)=>(
                                <option value={product._id}>{product.productName}</option>
                                ))
                            }
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="input"
                        className="col-sm-3 col-form-label"
                      >
                        Select customer type
                      </label>
                      <div className="col-sm-4">
                      <select
                          className="custom-select"
                          name="customerTypeId"
                          defaultValue={customerTypeId}
                          onChange={this.onHandleChange}
                        >
                          <option selected disabled>Select customer type</option>
                            {
                                data.customerType.map((customer)=>(
                                <option value={customer._id}>{customer.customerType}</option>
                                ))
                            }
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="input"
                        className="col-sm-3 col-form-label"
                      >
                        Product pricing
                      </label>
                      <div className="col-sm-4">
                      <input
                          className="form-control"
                          name="price"
                          type="number"
                          value={price}
                          onChange={this.onHandleChange}
                        />
                      </div>
                    </div>
                    <div
                      className="form-group text-center "
                      style={{ display: `${status ? "block" : "none"}` }}
                    >
                      <Loader
                        type="Bars"
                        color="#ff6805"
                        width="50"
                        height="30"
                      />
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2"></div>
                      <div className="col-sm-4 text-right">
                        <button
                          className="btn button2 text-white"
                          onClick={this.onHandleSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default AddPrice
