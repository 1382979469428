import React, {Component} from 'react'
import Wrapper from './wrapper'
import axios from 'axios'
import {baseurl} from '../../config'
import "@material/react-chips/dist/chips.css";
import {decryptInfo} from '../../utils/auth'
import Loader from 'react-loader-spinner'
class BulkRegister extends Component{
    constructor(props){
        super(props)
        this.state = {            
            orderfile: null,
            displayError:false,
            error: '',
            status: false
        }
        this.onHandleChange = this.onHandleChange.bind(this)      
        this.onHandleSubmit = this.onHandleSubmit.bind(this)
    }
    onHandleSubmit(event){
        event.preventDefault()
        const user = decryptInfo('admin')
        const {
            orderfile
        } = this.state
        const formData = new FormData()
        formData.append('orderfile',orderfile)
        this.setState({
            status: true
        })
        axios({
            method: 'post',
            url: `${baseurl}/user/bulkRegister`,
            data: formData,
            headers: {
                Authorization: `Bearer ${user.headers.authorization}`
            }
        }).then((res)=>{
            this.setState({
                status: false,
                displayError: true,
                error: `${res.data.message}`
            })
            console.log(res)          
        }).catch((error)=>{
            console.log(error)
            this.setState({
                status: false,
                displayError: true,
                error: error.response? error.response.data.message: "Request has failed"
            })
        })

    }
    onHandleChange(event){      
        this.setState({
            orderfile: event.target.files[0]
        })
    }

    render(){
        const {
            displayError,
            error,
            status
        } = this.state     
        return(
            <Wrapper>
                 <div className="page-content-wrapper ">
                    <div className="container container-fluid" >
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="page-title-box">
                                    <div className="row align-items-center">
                                        <div className="col-md-8">
                                            <h4 className="page-title m-0 ml-3">Bulk Register</h4>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="row">
                                <div className="col-12">
                                    <div className="card m-b-30">
                                        <div className="card-body">            
                                            <h4 className="mt-0 header-title">Bulk Register</h4>
                                            <p className="text-muted m-b-30 font-14"></p>
                                            <form encType="multipart/form-data">
                                            <div className="form-group row" 
                                            style={{display:`${displayError?'block':'none'}`}}
                                            >
                                                <div className ="col-12">
                                                    <span className="text-danger"> {error}</span>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="orderfile" className="col-sm-2 col-form-label">
                                                    Upload file
                                                </label>
                                                <div className="col-sm-6">
                                                    <input className="form-control" name="orderfile" type="file"
                                                        onChange = {this.onHandleChange} id="orderfile"
                                                     />
                                                </div>
                                            </div>                                            
                                            <div className="form-group text-center "
                                                style={{display:`${status?'block':'none'}`}}
                                             >
                                                <Loader type="Bars" color="#ff6805" width="50" height="30" />
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-2"></div>
                                                <div className="col-sm-6 text-right">
                                                    <button className="btn button2 text-white" onClick={this.onHandleSubmit}>
                                                        Submit
                                                    </button>
                                                </div>
                                               
                                            </div>
                                            </form>
                                            </div>
            
                                        </div>
                                    </div>
                                </div> 
                            </div> 
                    </div>
            </Wrapper>
        )
    }
    
}

export default BulkRegister
