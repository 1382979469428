import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import eye from "../../assets/icons/eye.svg";
import axios from "axios";
import { baseurl } from "../../config";
import { decryptInfo } from "../../utils/auth";
export default class DeliverySchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      data: {},
      routes: []
    };
    this.handleShow = this.handleShow.bind(this);
  }
  handleShow() {
    this.setState({
      show: !this.state.show
    });
  }
  componentDidMount() {
    const user = decryptInfo("nightShiftSupervisor");
    console.log(user);
    axios({
      method: "post",
      url: `${baseurl}/nightShiftSupervisor/deliverySchedule`,
      headers: {
        Authorization: user ? `Bearer ${user.headers.authorization}` : ""
      },
      data: {
        orderReceivedCode: this.props.orderReceivedCode
      }
    })
      .then(res => {
        console.log(res);
        this.setState({
          data: res.data.data,
          routes: res.data.routes
        });
      })
      .catch(error => {
        console.log(error);
      });
  }
  render() {
    const { show } = this.state;
    const { row } = this.props;
    return (
      <React.Fragment>
        <Button
          variant="secondary"
          className="btn btn-xs"
          onClick={this.handleShow}
        >
          <img src={eye} width="10" height="10" alt="eye" />
        </Button>

        <Modal show={show} onHide={this.handleShow} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Dispatch Schedule</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.routes.map((route,index) =>
              
                <table key={index} className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th colSpan="4" className="text-center">
                        Dispatch Summary Route {route}
                      </th>
                    </tr>
                    <tr>
                      <th>Customer Name</th>
                      <th>Product Name</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    this.state.data[route].map((row, index)=>(
                      <tr key={index}>
                      <td>{row.customerName}</td>
                      <td>{row.productName}</td>
                      <td>{row.productQuantity}</td>
                    </tr>
                    ))
                  }
                  
                  </tbody>
                </table>
             
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleShow}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}
