import React, { Component } from "react";
import Wrapper from "./wrapper";
import axios from "axios";
import { baseurl } from "../../config";
import { decryptInfo, formatDate } from "../../utils/auth";
import ViewOrders from "./view_orders";
export default class CustomerInvoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      role: null,
      displayError: false,
      error: ""
    };
    this.onCheck = this.onCheck.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {
    const user = decryptInfo("directCustomer");
    console.log(user);
    axios({
      method: "get",
      url: user ? `${baseurl}/customer/invoices` : "",
      headers: {
        Authorization: user ? `Bearer ${user.headers.authorization}` : ""
      }
    })
      .then(res => {
        console.log(res);
        this.setState({
          data: res.data.data
        });
      })
      .catch(error => {
        console.log(error);
      });
  }
  onSubmit(event) {
    event.preventDefault();
    this.setState({
      status: true
    });
    const user = decryptInfo("directCustomer");
    let orderCodes = [];
    this.state.data.forEach(order => {
      if (order.isChecked) {
        orderCodes.push(order.orderCode);
      }
    });
    axios({
      method: "post",
      url: `${baseurl}/customer/billRequest`,
      data: {
        orderCodes: orderCodes
      },
      headers: {
        Authorization: user ? `Bearer ${user.headers.authorization}` : ""
      }
    })
      .then(res => {
        this.setState({
          status: false,
          displayError: true,
          error: `${res.data.message}`
        });
        console.log(res);
        window.location.reload(false)
      })
      .catch(error => {
        console.log(error);
        this.setState({
          status: false,
          displayError: true,
          error: error.response.data.message
        });
      });
  }
  onCheck(event) {
    const { data } = this.state;
    data.forEach(row => {
      if (row._id === event.target.value) {
        row.isChecked = event.target.checked;
      }
    });
    this.setState({
      data: data,
      displayError: false
    });
  }
  render() {
    const { data} = this.state;
    return (
      <Wrapper>
        <div className="page-content-wrapper ">
          <div className="container container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="row align-items-center">
                    <div className="col-md-8">
                      <h4 className="page-title m-0 ml-3">DIRECT CUSTOMER</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div class="col-12">
                <div class="card m-b-30">
                  <div class="card-body">
                    <h4 class="mt-0 header-title">CUSTOMER INVOICES</h4>
                    <div class="table-rep-plugin">
                      <div
                        class="table-responsive b-0"
                        data-pattern="priority-columns"
                      >
                        <table
                          id="tech-companies-1"
                          class="table  table-striped"
                        >
                          <thead>
                            <tr>
                              <th>Bill ID</th>
                              <th>Control Number</th>
                              <th>Bill Amount</th>
                              <th>Payment Status</th>
                              <th>View Orders</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((row, index) => (
                              <tr key={index}>
                                <td className="text-uppercase">{row._id}</td>
                                <td className="text-uppercase">{row.controlNumber}</td>
                                <td>TSH {row.billAmount.$numberDecimal}</td>
                                <td>{row.paymentComplete?'PAID':'NOT PAID'}</td>
                                <td><ViewOrders orderCode ={row.orderCode}/></td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}
