import React,{Component} from 'react'
import HomeContent from './home_content'
import Navbar from '../../components/navbar'
import $ from 'jquery'
import {contents} from './content'
class Home extends Component {
    constructor(props){
        super(props);
        this.state = {

        }

    }
    componentDidMount(){
        document.body.style.backgroundColor = "white"
        $('.toggle-search').on('click', function (event) {
            event.preventDefault()
            var targetId = $(this).data('target');
            var $searchBar;
            if (targetId) {
                $searchBar = $(targetId);
                $searchBar.toggleClass('open');
            }
        });
        $('[data-toggle="tooltip"]').tooltip();
        $('[data-toggle="popover"]').popover();
    }
    render(){
        return (
        <React.Fragment>
            <Navbar />
                <div className=" container1" >
                    <div className="row">
                    {
                    contents.map((content)=>(
                        <HomeContent name={content.name} url={content.url} />
                    ))
                    }
                    </div>
                </div>
               
               
          

        </React.Fragment>

    
        )
    }
}
export default Home;