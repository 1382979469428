import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { toTime } from "../../utils/filterTask";
import eye from "../../assets/icons/drop.svg";
export default class ViewDropPoints extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      displayError: false,
      error: "",
      status: false
    };
    this.handleShow = this.handleShow.bind(this);
  }
  handleShow() {
    this.setState({
      show: !this.state.show
    });
  }

  render() {
    const { show } = this.state;
    const { row } = this.props;
    const formatTime = (time, dayZone) => {
      let day = dayZone >= 0 ? dayZone : dayZone * -1;
      let description = "";
      if (dayZone === 0) {
        description = "On Publication Day";
      } else if (dayZone < 0) description = "Day(s) before Publication Date";
      else if (dayZone > 0) description = "Day(s) after Publication Date";

      return dayZone === 0
        ? `${toTime(time)} ${description}`
        : `${toTime(time)} ${day} ${description}`;
    };
    return (
      <React.Fragment>
        <Button
          variant="secondary"
          className="btn btn-xs btn-secondary"
          disabled={this.props.disabled}
          onClick={this.handleShow}
        >
          <img src={eye} width="15" height="15" alt="eye" />
        </Button>

        <Modal show={show} onHide={this.handleShow} size="md">
          <Modal.Header closeButton>
            <Modal.Title className="text-uppercase">
              LIST DROP POINTS
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table id="tech-companies-1" class="table  table-striped">
              <thead>
                <th>Name</th>
                <th>ETA </th>
              </thead>
              <tbody>
                {row.dropPoint.map((drop, index) => (
                  <tr key={index}>
                    <td>{drop.name}</td>
                    <td>{formatTime(drop.eta.time, drop.eta.dayZone)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}
