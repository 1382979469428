import React, {Component} from 'react'
import {roles, week} from '../../config'
import Wrapper from './wrapper'
import {decryptInfo} from '../../utils/auth'
import axios from 'axios'
import {baseurl} from '../../config'
import "@material/react-chips/dist/chips.css";
import {ChipSet, Chip} from '@material/react-chips';
import Loader from 'react-loader-spinner'
class RegisterUser extends Component{
    constructor(props){
        super(props)
        this.state = {            
            email:"",
            password:"",
            role: "",
            phoneNumber: null,
            userName: "",
            customerType: "",
            products: [],
            territoryId: "",
            publisherId: '',
            station: "",
            data: {
                customerType: [],
                territories: [],
                products: [],
                publishers: []
            },
            retype_password: '',
            displayError:false,
            error: '',
            status: false,
            billingCount:'',
            billingPeriod:''
        }
        this.onHandleChange = this.onHandleChange.bind(this)      
        this.onHandleSubmit = this.onHandleSubmit.bind(this)
    }
    componentDidMount(){
        const user = decryptInfo('admin')
        console.log(user)
        axios({
            method: 'get',
            url: `${baseurl}/user/registrationInfo`,
            headers: {
                Authorization: `Bearer ${user.headers.authorization}`
            }
        }).then((res)=>{
            console.log(res)
            this.setState({
                data: res.data
            })
        }).catch((error)=>{
            console.log(error)
        })
    }
    onHandleSubmit(event){
        event.preventDefault()
        const {
            email,
            password,
            role,
            phoneNumber,
            userName,
            customerType,
            products,
            territoryId,
            publisherId,
            retype_password,
            billingCount,
            billingPeriod,
            data,
            station
        } = this.state
        let details = {
            email,
            password,
            role,
            phoneNumber,
            userName,
            retype_password,
            publisherId
        }
        if(role ==="directCustomer"){
            details.customerType = customerType
            details.products = products 
            details.station = station
            details.billingCycle = {
                period: billingPeriod,
                count: (billingCount || '')
            }    
        }
        if (role === 'driver'){
            details.territoryId = territoryId  
        }      
        const user = decryptInfo('admin')
        if(retype_password !== password){
            this.setState({
                displayError: true,
                error:'Passwords do not match'
            })
        }
        else{
            this.setState({
                status: true
            })
            axios({
                method: 'post',
                url: `${baseurl}/user/register`,
                data: details,
                headers: {
                    Authorization: `Bearer ${user.headers.authorization}`
                }
            }).then((res)=>{
                this.setState({
                    status: false,
                    displayError: true,
                    error: `${res.data.message}.<${res.data.data.activationCode}>`
                })
                console.log(res)          
            }).catch((error)=>{
                console.log(error)
                this.setState({
                    status: false,
                    displayError: true,
                    error: error.response? error.response.data.message: "Request has failed"
                })
            })
        }

    }
    onHandleChange(event){      
        this.setState({
            [event.target.name]: event.target.value,
            displayError: true
        })
    }

    render(){
        const {
            email,
            password,
            role,
            phoneNumber,
            userName,
            customerType,
            products,
            territoryId,
            data,
            displayError,
            error,
            status,
            publisherId,
            retype_password,
            billingPeriod,
            billingCount,
            station
        } = this.state
        console.log(data)
        console.log(role)
        let filterProduct = (publisherId)=>{
            let filtered = data.products.filter((product)=>(
                product.publisherId === publisherId
            ))
            return filtered
        }
        let dates = []
        for (let j =1 ; j< 32; j++){
            dates.push(j)
        }
        let disabled =  role === 'directCustomer'? false : true
        let isDriver =  role === 'driver'? false : true
        let isPublisher = role ==='publisher' || role === '' ?true: false
        let selectProducts = publisherId ===''? [] : filterProduct(publisherId)
        let selectedTerritory  =  publisherId ===''?[]: data.territories.filter((territory)=>(
            territory.publisherId === publisherId
        ))
        return(
            <Wrapper>
                 <div className="page-content-wrapper ">
                    <div className="container container-fluid" >
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="page-title-box">
                                    <div className="row align-items-center">
                                        <div className="col-md-8">
                                            <h4 className="page-title m-0 ml-4">Register User</h4>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="row">
                                <div className="col-12">
                                    <div className="card m-b-30">
                                        <div className="card-body">            
                                            <h4 className="mt-0 header-title">Register user</h4>
                                            <p className="text-muted m-b-30 font-14"></p>
                                            <div className="form-group row" 
                                            style={{display:`${displayError?'block':'none'}`}}
                                            >
                                                <div className ="col-12">
                                                    <span className="text-danger"> {error}</span>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="input" className="col-sm-2 col-form-label">Username</label>
                                                <div className="col-sm-6">
                                                    <input className="form-control" name="userName" type="text" value={userName}
                                                        onChange = {this.onHandleChange}
                                                     />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="input" className="col-sm-2 col-form-label">Email</label>
                                                <div className="col-sm-6">
                                                    <input className="form-control" type="email" name="email" value={email}
                                                        onChange = {this.onHandleChange}
                                                     />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="input" className="col-sm-2 col-form-label">Phone number</label>
                                                <div className="col-sm-6">
                                                    <input className="form-control" name="phoneNumber" type="tel" value={phoneNumber}
                                                        onChange = {this.onHandleChange}
                                                     />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="input" className="col-sm-2 col-form-label">Password</label>
                                                <div className="col-sm-6">
                                                    <input className="form-control" name="password" type="password" value={password}
                                                        onChange = {this.onHandleChange}
                                                     />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="input" className="col-sm-2 col-form-label">Retype password</label>
                                                <div className="col-sm-6">
                                                    <input className="form-control" name="retype_password" type="password" value={retype_password}
                                                        onChange = {this.onHandleChange}
                                                     />
                                                </div>
                                            </div>
                                            <div  className="form-group row">
                                                <label className="col-sm-2 col-form-label">Select role</label>
                                                <div className="col-sm-6">
                                                    <select className="custom-select" name="role" defaultValue={role} onChange = {this.onHandleChange} >
                                                        <option selected>Select role</option>
                                                        {
                                                            roles.map((role,index)=>(
                                                                <option className="text-capitalize" key={index} value={role.role}>
                                                                    {role.roleName}
                                                                </option>
                                                            ))
                                                        }

                                                    </select>
                                                </div>
                                            </div>
                                            <div  style={{display:`${isPublisher?'none':'flex'}`}}  className="form-group row">
                                                <label className="col-sm-2 col-form-label">Select publisher</label>
                                                <div className="col-sm-6">
                                                    <select className="custom-select" name="publisherId" defaultValue={publisherId} onChange = {this.onHandleChange} >
                                                        <option selected>Select publisher</option>
                                                        {
                                                            data.publishers.map((role,index)=>(
                                                                <option key={index} value={role._id}>
                                                                    {role.userName}
                                                                </option>
                                                            ))
                                                        }

                                                    </select>
                                                </div>
                                            </div>
                                            <div style={{display:`${isDriver?'none':'flex'}`}} className="form-group row">
                                                <label className="col-sm-2 col-form-label">Select territory</label>
                                                <div className="col-sm-6">
                                                    <select className="custom-select" name="territoryId" defaultValue={territoryId} onChange = {this.onHandleChange} >
                                                        <option selected>Select territory</option>
                                                        {
                                                            selectedTerritory.map((territory,index)=>(
                                                                <option 
                                                                    key={index}
                                                                    value={territory._id}
                                                                >
                                                                    {territory.territoryName}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>            
                                            <div style={{display:`${disabled?'none':'flex'}`}} className="form-group row">
                                                <label className="col-sm-2 col-form-label">Select customer type</label>
                                                <div className="col-sm-6">
                                                    <select className="custom-select" name="customerType" defaultValue={customerType} onChange = {this.onHandleChange} >
                                                        <option selected>Select customer type</option>
                                                        {
                                                            data.customerType.map((customer,index)=>(
                                                                <option 
                                                                    key={index}
                                                                    value={customer._id}
                                                                >
                                                                    {customer.customerType}
                                                                </option>
                                                            ))
                                                        }

                                                    </select>
                                                </div>
                                            </div>
                                            <div style={{display:`${disabled?'none':'flex'}`}} className="form-group row">
                                                <label className="col-sm-2 col-form-label">Station</label>
                                                <div className="col-sm-6">
                                                <input className="form-control" name="station" type="text" value={station}
                                                        onChange = {this.onHandleChange}
                                                     />
                                                </div>
                                            </div>
                                            <div style={{display:`${disabled?'none':'flex'}`}} className="form-group row">
                                                <label className="col-sm-2 col-form-label">Select products</label>
                                                <div className="col-sm-10">
                                                    <ChipSet
                                                        filter
                                                        selectedChipIds = {products}
                                                        handleSelect = {(products)=>this.setState({products})}
                                                    >
                                                         {
                                                            selectProducts.map((product,index)=>(
                                                                <Chip id={product._id} key={index}
                                                                 label = {product.productName}
                                                                />                                                              
                                                            ))
                                                        }
                                                    </ChipSet>
                                                </div>
                                            </div>  
                                            <div style={{display:`${disabled?'none':'flex'}`}} className="form-group row">
                                                <label className="col-sm-2 col-form-label">Billing cycle</label>
                                                <div className="col-sm-3">
                                                <select className="custom-select" name="billingPeriod" defaultValue={billingPeriod} onChange = {this.onHandleChange} >
                                                        <option selected>Select billing period</option>
                                                        <option value="daily">Daily</option>
                                                        <option value="weekly">Weekly</option>
                                                        <option value="monthly">Monthly</option>
                                                    </select>
                                                </div>
                                                <div style={{display:`${billingPeriod === "weekly"?'flex':'none'}`}} className="col-sm-3">
                                                <select className="custom-select" name="billingCount" defaultValue={billingCount} onChange = {this.onHandleChange} >
                                                        <option selected>Select day</option>
                                                        {
                                                        week.map((day,index)=>(
                                                        <option  key={index} value={day.index}>{day.day}</option>
                                                        ))
                                                    }
                                                    </select>
                                                  
                                                </div>
                                                <div style={{display:`${billingPeriod !== "monthly"?'none':'flex'}`}} className="col-sm-3">
                                                <select className="custom-select" name="billingCount" defaultValue={billingCount} onChange = {this.onHandleChange} >
                                                        <option selected>Select date</option>
                                                        {
                                                        dates.map((date,index)=>(
                                                        <option  key={index} value={date}>{date}</option>
                                                        ))
                                                    }
                                                    </select>
                                                  
                                                </div>
                                            </div>                                      
                                            <div className="form-group text-center "
                                                style={{display:`${status?'block':'none'}`}}
                                             >
                                                <Loader type="Bars" color="#ff6805" width="50" height="30" />
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-2"></div>
                                                <div className="col-sm-6 text-right">
                                                    <button className="btn button2 text-white" onClick={this.onHandleSubmit}>
                                                        Submit
                                                    </button>
                                            </div>
                                               
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div> 
                    </div>
            </Wrapper>
        )
    }
    
}

export default RegisterUser
