import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import eye from "../../assets/icons/eye.svg";
import Loader from "react-loader-spinner";
import axios from "axios";
import { baseurl } from "../../config";
import { decryptInfo } from "../../utils/auth";
export default class MakePackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      data: {
        packers:[],
        customers:[]
      },
      products: {},
      displayError: false,
      error: "",
      packerId: "",
      customerId: "",
      weight: "",
      status: false
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleProduct = this.handleProduct.bind(this);
    this.onHandleSubmit = this.onHandleSubmit.bind(this)
  } 
  handleShow() {
    this.setState({
      show: !this.state.show,
    });
  }
  handleProduct(event) {
    if (event.target.value) {
      this.setState({
        products: {
            ...this.state.products,
            [event.target.name]: event.target.value
        }
      });
    }
  }
  onHandleSubmit(){
    const {packerId,customerId,products,weight} =this.state
    const user = decryptInfo("nightShiftSupervisor");
    let productsPacked = []
    for(let product in products){
      productsPacked.push({
        productCode: product,
        productQuantity: products[product]
      })
    }
    this.setState({
      status:true
    })
    axios({
      method: "post",
      url: `${baseurl}/nightShiftSupervisor/makePackage`,
      headers: {
        Authorization: user ? `Bearer ${user.headers.authorization}` : "",
      },
      data: {
        customerId,
        packerId,
        products: productsPacked,
        weight,
        nightShiftId: this.props.row._id
      }
    })
      .then((res) => {
        console.log(res);
        this.setState({
          error: res.data.message,
          status: false,
          displayError: true
        });
      })
      .catch((error) => {
        console.log(error)
        this.setState({
          error: "Request has  failed",
          displayError:true,
          status: false
        })
      });
  }
  componentDidMount() {
    const user = decryptInfo("nightShiftSupervisor");
    console.log(user);
    axios({
      method: "get",
      url: `${baseurl}/user/packerInfo`,
      headers: {
        Authorization: user ? `Bearer ${user.headers.authorization}` : "",
      },
    })
      .then((res) => {
        console.log(res);
        this.setState({
          data: res.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    const {
      show,
      weight,
      packerId,
      customerId,
      displayError,
      error,
      status,
      products,
      data
    } = this.state;
    const { row } = this.props;
    const {packers,customers} = data
    return (
      <React.Fragment>
        <Button
          variant="secondary"
          className="btn btn-xs"
          onClick={this.handleShow}
        >
          <img src={eye} width="10" height="10" alt="eye" />
        </Button>

        <Modal show={show} onHide={this.handleShow} size="md">
          <Modal.Header closeButton>
            <Modal.Title>Make Parcel</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="form-group row"
              style={{ display: `${displayError ? "block" : "none"}` }}
            >
              <div className="col-12">
                <span className="text-danger"> {error}</span>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="input" className="col-sm-3 col-form-label">
                Packer
              </label>
              <div className="col-sm-6">
                <select
                  className="custom-select"
                  onChange={(event) =>
                    this.setState({
                      packerId: event.target.value,
                    })
                  }
                  defaultValue={packerId}
                >
                  <option selected>Select packer</option>
                  {packers.map((packer, index) => (
                    <option key={index} value={packer._id}>
                      {packer.userName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="input" className="col-sm-3 col-form-label">
                Customer
              </label>
              <div className="col-sm-6">
                <select
                  className="custom-select"
                  onChange={(event) =>
                    this.setState({
                      customerId: event.target.value,
                    })
                  }
                  defaultValue={customerId}
                >
                  <option selected>Select customer</option>
                  {customers.map((customer, index) => (
                    <option key={index} value={customer._id}>
                      {customer.userName}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {row.productsReceived.map((product) => (
              <div className="form-group row">
                <label htmlFor="input" className="col-sm-3 col-form-label">
                  Product {product.productCode}
                </label>
                <div className="col-sm-6">
                  <input
                    className="form-control"
                    type="number"
                    name={product.productCode}
                    placeholder="Enter product quantity"
                    value= {products[product.productCode] || ""}
                    onChange={this.handleProduct}
                  />
                </div>
              </div>
            ))}
            <div className="form-group row">
              <label htmlFor="input" className="col-sm-3 col-form-label">
                Weight
              </label>
              <div className="col-sm-6">
                <input
                  className="form-control"
                  type="number"
                  value={weight}
                  onChange={(event) => {
                    this.setState({
                      weight: event.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div
              className="form-group text-center "
              style={{ display: `${status ? "block" : "none"}` }}
            >
              <Loader type="Bars" color="#ff6805" width="50" height="30" />
            </div>
            <div className="form-group row">
              <div className="col-sm-2"></div>
              <div className="col-sm-6 text-right"></div>
              <div className="form-group row">
                <div className="col-sm-2"></div>
                <div className="col-sm-6 text-right">
                  <button
                    className="btn button2 text-white"
                    onClick={this.onHandleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleShow}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}
