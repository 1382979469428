//export const loginBaseUrl = "http://localhost:5000/api";
// export const baseurl = 'http://ec2-35-171-155-180.compute-1.amazonaws.com:5000/api'
//export const baseurl = 'https:gn20.co.tz/api'

export const baseurl = "http://161.35.65.70:5000/api";
export const apiKey = "bHrOu5AD7D5lgN1Zkpslp619vSKXsMRZ7yrprOrq";
export const mapBoxApiKey =
  "pk.eyJ1IjoibXVraXphIiwiYSI6ImNqd25pcnJicjI4OGg0YW8yaTNlZzNhZmgifQ.2mwFABC4m_yejI5zwsnGAA";
export const roles = [
  { role: "printOrderMaker", roleName: "Print Order Maker" },
  { role: "printManager", roleName: "Print Manager" },
  { role: "circulationManager", roleName: "Circulation Manager" },
  { role: "financeManager", roleName: "Finance Manager" },
  { role: "billOfficer", roleName: "Bill Officer" },
  { role: "hr", roleName: "HR" },
  { role: "nightShiftSupervisor", roleName: "Night Shift Supervisor" },
  { role: "driver", roleName: "Driver" },
  { role: "transporter", roleName: "Transporter" },
  { role: "territoryRepresentative", roleName: "Territory Representative" },
  { role: "returnOfficer", roleName: "Return Officer" },
  { role: "storeOfficer", roleName: "Store Officer" },
  { role: "directCustomer", roleName: "Direct Customer" },
  { role: "publisher", roleName: "Publisher" },
  {role:"packer",roleName:"Packer"}
];
export const week = [
  { index: 1, day: "monday" },
  { index: 2, day: "tuesday" },
  { index: 3, day: "wednesday" },
  { index: 4, day: "thursday" },
  { index: 5, day: "friday" },
  { index: 6, day: "saturday" },
  { index: 0, day: "sunday" }
];
