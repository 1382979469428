import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import eye from "../../assets/icons/reject.svg";
export default class RejectOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleChange = this.handleChange.bind(this)
  }
  handleShow() {
    this.setState({
      show: !this.state.show
    });
  }
  handleChange(event){
      this.setState({
          [event.target.name] : event.target.value
      })
  }
  render() {
    const { show,reason } = this.state;
    return (
      <React.Fragment>
        <Button
          variant="secondary"
          className="btn btn-xs"
          onClick={this.handleShow}
        >
          <img src={eye} width="18" height="18" alt="eye" />
        </Button>

        <Modal show={show} onHide={this.handleShow} size="md">
          <Modal.Header closeButton>
            <Modal.Title>Reject Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
                <div className="form-group row">
                    <label htmlFor="reason" className="col-sm-2 col-form-label">
                        Reason
                    </label>
                    <div className="col-sm-8">
                        <textarea className="form-control" value={reason} onChange={this.handleChange}>
                        
                        </textarea>
                    </div>
                </div>
            </form>
          </Modal.Body>
          <Modal.Footer>

            <Button variant="secondary" >
              Confirm
            </Button>

          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}
