import React, { Component } from "react";
import Wrapper from "./wrapper";
import axios from "axios";
import { baseurl } from "../../config";
import { decryptInfo, formatDate } from "../../utils/auth";
export default class PrintInventoryTracker extends Component {
  constructor(props) {
    super();
    this.state = {
      data: [],
      driverData: [],
    };
  }
  componentDidMount() {
    const user = decryptInfo("billOfficer");
    console.log(user);
    axios({
      method: "get",
      url: `${baseurl}/bill/printInventoryTracker`,
      headers: {
        Authorization: user ? `Bearer ${user.headers.authorization}` : "",
      },
    })
      .then((res) => {
        console.log(res);
        this.setState({
          data: res.data.data,
          driverData: res.data.driverData || [],
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    const { data, driverData } = this.state;
    return (
      <Wrapper>
        <div className="page-content-wrapper ">
          <div className="container container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="row align-items-center">
                    <div className="col-md-8">
                      <h4 className="page-title m-0 ml-3">
                        {" "}
                        BILL OFFICER
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div class="col-12">
                <div class="card m-b-30">
                  <div class="card-body">
                    <h4 class="mt-0 header-title text-center">
                      {" "}
                      PRINT ORDER INVENTORY TRACKER
                    </h4>
                    <div className="d-flex flex-row justify-content-end align-items-center mb-3">
                      <button
                        className="btn btn-md bg-primary text-white"
                        onClick={() => {
                          window.location.reload();
                        }}
                      >
                        Refresh
                      </button>
                    </div>
                    <div class="table-rep-plugin" style={{ minHeight: "30vh" }}>
                      <div
                        class="table-responsive b-0"
                        data-pattern="priority-columns"
                      >
                        <table
                          id="tech-companies-1"
                          class="table  table-bordered"
                        >
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Role</th>
                              <th>Product</th>
                              <th>IN</th>
                              <th>OUT</th>
                              <th data-priority="3">Balance</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((row, index) => (
                              <tr key={index}>
                                <td>{row.publicationDate}</td>
                                <td>{row.role}</td>
                                <td>{row.productName}</td>
                                <td>{row.received}</td>
                                <td>{row.handover}</td>
                                <td>{row.balance}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <h4 className="mt-3 header-title text-center">
                      {" "}
                      PRINT ORDER INVENTORY TRACKER
                    </h4>
                    <h5 className="header-title mb-2 text-center ">Driver</h5>
                    <div class="table-rep-plugin" style={{ minHeight: "30vh" }}>
                      <div
                        class="table-responsive b-0"
                        data-pattern="priority-columns"
                      >
                        <table
                          id="tech-companies-1"
                          class="table  table-bordered"
                        >
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Name</th>
                              <th>Product</th>
                              <th>IN</th>
                              <th>OUT</th>
                              <th data-priority="3">Balance</th>
                            </tr>
                          </thead>
                          <tbody>
                            {driverData.map((row, index) => (
                              <tr key={index}>
                                <td>{row.publicationDate}</td>
                                <td>{row.driverName}</td>
                            <td>{row.productName}</td>
                                <td>{row.received}</td>
                                <td>{row.handover}</td>
                                <td>{row.balance}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}
