import React, { Component } from "react";
import Wrapper from "./wrapper";
import axios from "axios";
import { baseurl } from "../../config";
import { decryptInfo, formatDate,toCommas } from "../../utils/auth";
import ViewSuccessful from "./view_successful_order";
import CheckBox from "./check_box";
import RejectOrder from "./reject_order";
import SelectedRows from "./view_selected_orders";
class MakeOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      role: null,
      orders: [],
      status: false,
      error: "",
      priceSum: 0,
      productName: "",
      customerName: "",
      quantitySum: 0,
      displayError: false
    };
    this.handleCheck = this.handleCheck.bind(this);
    this.checkAll = this.checkAll.bind(this);
  }
  componentDidMount() {
    const user = decryptInfo("printOrderMaker");
    console.log(user);
    axios({
      method: "get",
      url: `${baseurl}/order/list/maker`,
      headers: {
        Authorization: `Bearer ${user.headers.authorization}`
      }
    })
      .then(res => {
        console.log(res);
        this.setState({
          data: res.data.data,
          priceSum: res.data.priceSum,
          quantitySum: res.data.quantitySum
        });
      })
      .catch(error => {
        console.log(error);
      });
  }
  checkAll(checked, data) {
    console.log(checked, data);
    if (data.length === 0) {
      this.setState({
        displayError: true,
        error: "No data was selected"
      });
    } else if (!this.state.role) {
      this.setState({
        displayError: true,
        error: "Please select publication date"
      });
    } else {
      let selected = {};
      data.forEach(row => {
        selected[row._id] = 1;
      });
      let orders = this.state.data;
      orders.forEach(order => {
        if (selected[order._id]) {
          order.isChecked = checked;
        }
      });
      this.setState({
        data: orders,
        displayError: false
      });
    }
  }
  handleCheck(event) {
    const { data } = this.state;
    data.forEach(row => {
      if (row._id === event.target.value) {
        row.isChecked = event.target.checked;
      }
    });
    this.setState({
      data: data,
      displayError: false
    });
  }
  render() {
    const {
      data,
      role,
      error,
      displayError,
      productName,
      customerName
    } = this.state;
    const namesArray = [];
    const productsArray = [];
    data.forEach(row => {
      namesArray.push(row.customerName);
      productsArray.push(row.productName);
    });
    const names = Array.from(new Set(namesArray));
    const products = Array.from(new Set(productsArray));
    console.log(this.state);

    console.log(role);
    let filteredRole = [];
    let priceSum = 0;
    let quantitySum = 0;
    if (!role && !productName && !customerName) {
      filteredRole = data;
    } else {
      filteredRole = data.filter(
        row =>
          row.productName === productName ||
          Date.parse(row.publicationDate) === Date.parse(role) ||
          row.customerName === customerName
      );
    }
    for (let row of filteredRole) {
      priceSum += row.price * row.productQuantity;
      quantitySum += row.productQuantity;
    }
    return (
      <Wrapper>
        <div className="page-content-wrapper ">
          <div className="container container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="row align-items-center">
                    <div className="col-md-8">
                      <h4 className="page-title m-0 ml-3">ORDERS</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div class="col-12">
                <div class="card m-b-30">
                  <div class="card-body">
                    <h4 class="mt-0 header-title">
                      successful open customer orders
                    </h4>

                    <div
                      className="form-group row"
                      style={{ display: `${displayError ? "block" : "none"}` }}
                    >
                      <div className="col-12">
                        <span className="text-danger"> {error}</span>
                      </div>
                    </div>
                    <div class="col-sm-12 text-right mb-3 mt-3 d-flex justify-content-between row">
                      <input
                        class="form-control col-sm-3"
                        type="date"
                        onChange={event =>
                          this.setState({
                            role: event.target.value
                          })
                        }
                        value={role || ""}
                        className="custom-select bg-secondary text-white col-sm-3"
                        id="example-date-input"
                      />
                      <select
                        className="custom-select col-sm-3 bg-secondary text-white"
                        onChange={event =>
                          this.setState({
                            productName: event.target.value
                          })
                        }
                        defaultValue={productName}
                      >
                        <option selected>Select product name</option>
                        {products.map((product, index) => (
                          <option key={index} value={product}>
                            {product}
                          </option>
                        ))}
                      </select>
                      <select
                        className="custom-select col-sm-3 bg-secondary text-white"
                        onChange={event =>
                          this.setState({
                            customerName: event.target.value
                          })
                        }
                        defaultValue={customerName}
                      >
                        <option selected>Select customer name</option>
                        {names.map((name, index) => (
                          <option key={index} value={name}>
                            {name}
                          </option>
                        ))}
                      </select>
                      <SelectedRows
                        row={filteredRole}
                        date={role}
                        disabled={!(filteredRole.length > 0) || !role}
                      />
                    </div>
                    <div class="table-rep-plugin">
                      <div
                        class="table-responsive b-0"
                        data-pattern="priority-columns"
                      >
                        <table
                          id="tech-companies-1"
                          class="table  table-striped"
                        >
                          <thead>
                            <tr>
                              <th>
                                <input
                                  type="checkbox"
                                  onChange={event => {
                                    this.checkAll(
                                      event.target.checked,
                                      filteredRole
                                    );
                                  }}
                                />
                              </th>
                              <th>Customer</th>
                              <th>Station</th>
                              <th>Publication Date</th>
                              <th data-priority="1">Product</th>
                              <th data-priority="3">Quantity</th>
                              <th data-priority="3">Value</th>
                              <th data-priority="1">Status</th>
                              <th>View</th>
                              <th>Reject</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredRole.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  <CheckBox
                                    id={row._id}
                                    handleCheck={this.handleCheck}
                                    disabled={
                                      row.status === "Closed" ? true : false
                                    }
                                    checked={row.isChecked}
                                  />
                                </td>
                                <td>{row.customerName}</td>
                                <td>{row.station}</td>
                                <td>{formatDate(row.publicationDate)}</td>

                                <td>{row.productName}</td>
                                <td>{row.productQuantity}</td>
                                <td>TSH {toCommas(row.price * row.productQuantity)}</td>
                                <td>{row.status}</td>
                                <td>
                                  <ViewSuccessful row={row} />
                                </td>
                                <td>
                                  <RejectOrder row={row} />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <th colSpan="5"></th>
                              <th>Total quantity</th>
                              <td colSpan="3">{quantitySum}</td>
                            </tr>
                            <tr>
                              <th colSpan="5"></th>
                              <th>Price</th>
                              <td colSpan="3">TSH {toCommas(priceSum)}</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default MakeOrder;
