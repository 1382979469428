import { SET_TERRITORY, TERRITORY_LIST } from "../actions/index";

const initialState = {
  territory: null,
  territoryList: []
};

const TerritoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TERRITORY:
      return {
        ...state,
        territory: action.payload
      };
    case TERRITORY_LIST:
      return {
        ...state,
        territoryList: action.payload
      };
    default:
      return state;
  }
};
export default TerritoryReducer;
