import React, {Component} from 'react'
import Nav from '../nav/nav'
import Content from '../content/content'
import {decryptInfo} from '../../utils/auth'
import {Redirect} from 'react-router-dom'
import list from '../../assets/icons/list.svg'
import home from '../../assets/icons/home.svg'
class Wrapper extends Component{
    constructor(props){
        super(props)
        this.state = {}
    }
    render(){
        const user = decryptInfo('printOrderMaker') 
          
        console.log(user)
        if(!user){
            return(
                <Redirect to="/login" />
            )
        }
        const links = [
            {
                name: 'Single Order',
                url: '/printOrderMaker/singleOrder',
                icon:home
            },
            {
                name: 'Bulk Order',
                url: '/printOrderMaker/bulkOrder',
                icon:home
            },
            {
                name: 'Orders successful',
                url: '/printOrderMaker/orderSuccessful',
                icon:home
            },
            {
                name: 'Orders failed',
                url: '/printOrderMaker/orderFailed',
                icon:home
            },
            {
                name: 'Print Orders',
                url: '/printOrderMaker/list',
                icon:list
            },
        
        ]
        const header = 'PRINT ORDER MAKER'
        return(
        <div id="wrapper">
            <Nav links ={links} header={header} />           
            <Content>
                {this.props.children}
            </Content>
        </div>
        )
    }
    
}
export default Wrapper