import React from 'react'
import {NavLink} from 'react-router-dom'
 const NavSub = ({children})=>{
    return(
        <ul className="list-unstyled">
            {
                children.map(({url,name})=>(
                    <li>
                        <NavLink to={url} activeClassName="active" exact>
                            {name}
                        </NavLink>
                    </li>
                ))
            }
        </ul>
    )
}
export default NavSub