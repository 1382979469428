import React, {Component} from 'react'
import Wrapper from './wrapper'
import axios from 'axios'
import {baseurl} from '../../config'
import {decryptInfo,formatDate} from '../../utils/auth'
import {roles} from '../../config'
import Loader from 'react-loader-spinner'
import CheckBox from './check_box'
class MakeOrder extends Component{
    constructor(props){
        super(props)
        this.state = {            
            data: [],
            role: null,
            orders: [],
            status: false,
            error: '',
            displayError: false
        }
    }
    componentDidMount(){
        const user = decryptInfo('printOrderMaker')
        console.log(user)
        axios({
            method: 'get',
            url: `${baseurl}/order/failed/maker`,
            headers: {
                Authorization: `Bearer ${user.headers.authorization}`
            }
        }).then((res)=>{
            console.log(res)
            this.setState({
                data: res.data.data
            })
        }).catch((error)=>{
            console.log(error)
        })
    }
    render(){
        const {
            data,
            role,
            status,
            error,
            displayError
        } = this.state    
        console.log(role) 
        let filteredRole = []
        if(!role){
            filteredRole = data
        }
        else{
            filteredRole  = data.filter((row)=>(
                Date.parse(row.order.publicationDate) === Date.parse(role)
            ))
        }
        return(
            <Wrapper>
                 <div className="page-content-wrapper ">
                    <div className="container container-fluid" >
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="page-title-box">
                                    <div className="row align-items-center">
                                        <div className="col-md-8">
                                            <h4 className="page-title m-0 ml-3">ORDER</h4>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="row">
                        <div class="col-12">
                                    <div class="card m-b-30">
                                        <div class="card-body">            
                                            <h4 class="mt-0 header-title">FAILED OPEN CUSTOMER ORDERS</h4> 
                                            <div className="form-group text-center "
                                                style={{display:`${status?'block':'none'}`}}
                                             >
                                                <Loader type="Bars" color="#ff6805" width="50" height="30" />
                                            </div>
                                            <div className="form-group row" 
                                                style={{display:`${displayError?'block':'none'}`}}
                                            >
                                                <div className ="col-12">
                                                    <span className="text-danger"> {error}</span>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 text-right mb-3 pr-3">                                            
                                            <input class="form-control" type="date" 
                                                 onChange={(event)=>this.setState({
                                                    role: event.target.value
                                                })}
                                                value={role || ''}
                                                className="custom-select bg-secondary text-white col-sm-3"
                                                id="example-date-input" />                                             
                                            </div>                                        
                                            <div class="table-rep-plugin">                                                
                                                <div class="table-responsive b-0" data-pattern="priority-columns">
                                                    <table id="tech-companies-1" class="table  table-striped">
                                                        <thead>
                                                        <tr>
                                                            <th>Created At</th>
                                                            <th>Publication Date</th>
                                                            <th>Customer code</th>
                                                            <th data-priority="1">Product code</th>
                                                            <th data-priority="3">Quantity</th>
                                                            <th data-priority="1">Status</th>
                                                            <th data-priority="1">Reason</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                filteredRole.map((row,index)=>(
                                                                    <tr key={index}>
                                                                        <td>{formatDate(row.createdAt)}</td>
                                                                        <td>{row.order? formatDate(row.order.publicationDate): 'NULL'}</td>
                                                                        <td>{row.order?row.order.customerCode: 'NULL'}</td>
                                                                        <td>{row.order?row.order.productCode:'NULL'}</td>
                                                                        <td>{row.order?row.order.productQuantity: 'NULL'}</td>
                                                                        <td>{row.status}</td>
                                                                        <td className="text-lowercase">{row.reason.split('_').join(" ")}</td>
                                                                    </tr>
                                                                ))
                                                            }                                                             
                                                        </tbody>
                                                    </table>
                                                </div>
            
                                            </div>
            
                                        </div>
                                    </div>
                                </div> 
                        </div> 
                    </div> 
                    </div>
            </Wrapper>
        )
    }
    
}

export default MakeOrder
