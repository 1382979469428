import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import link from "../../assets/icons/eye.svg";
import { formatDate } from "../../utils/auth";
export default class ViewBillDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      displayError: false,
      error: "",
      status: false,
      data: [],
    };
    this.handleShow = this.handleShow.bind(this);
  }
  handleShow() {
    this.setState({
      show: !this.state.show,
    });
  }

  render() {
    const { show } = this.state;
    const { row } = this.props;
    return (
      <React.Fragment>
        <Button
          variant="secondary"
          className="btn btn-xs"
          onClick={this.handleShow}
          disabled={show ? true : false}
        >
          <img src={link} width="18" height="18" alt="eye" />
        </Button>

        <Modal show={show} onHide={this.handleShow} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Invoice Detail View</Modal.Title>
          </Modal.Header>
          <Modal.Body className="view-bill-details">
            <div className="col-sm-12 row d-flex flex-row justify-content-between">
              <h4>Invoice Details</h4>
              <button className="btn btn-sm btn-primary">
                Print Delivery Note
              </button>
            </div>
            <div className="col-sm-12 row d-flex flex-row justify-content-between">
              <table className="table table-striped col-sm-5">
                <thead>
                  <tr>
                    <th colSpan="2">Customer</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Account No</td>
                    <td>******</td>
                  </tr>
                  <tr>
                    <td>Name</td>
                    <td>{row.customerName}</td>
                  </tr>
                  <tr>
                    <td>Category</td>
                    <td>{row.customerType}</td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-striped col-sm-5">
                <thead>
                  <tr>
                    <th colSpan="2">Invoice Details</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Doc No</td>
                    <td>******</td>
                  </tr>
                  <tr>
                    <td>Invoice Date</td>
                    <td>{formatDate(row.publicationDate)}</td>
                  </tr>
                  <tr>
                    <td>Created Date</td>
                    <td>{formatDate(row.createdAt)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-sm-12 row d-flex flex-row justify-content-between">
              <table className="table table-striped col-sm-5">
                <thead>
                  <tr>
                    <th colSpan="2">Location</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Territory</td>
                    <td>{row.territoryName}</td>
                  </tr>
                  <tr>
                    <td>Route</td>
                    <td>{row.routeName}</td>
                  </tr>
                  <tr>
                    <td>Drop Point</td>
                    <td>{row.dropPointName}</td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-striped  col-sm-5">
                <thead>
                  <tr>
                    <th colSpan="2">Publisher</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>{row.publisherName}</td>
                  </tr>
                  <tr>
                    <td>TIN Number</td>
                    <td>******</td>
                  </tr>
                  <tr>
                    <td>VRN</td>
                    <td>****</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-sm-12 row d-flex flex-row justify-content-between ">
              <table className="table table-striped col-sm-12 ">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Issue Date</th>
                    <th>Quantity</th>
                    <th>Unit Price</th>
                    <th>Discount</th>
                    <th>Tax</th>
                    <th>Net Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{row.productName}</td>
                    <td>{formatDate(row.createdAt)}</td>
                    <td>{row.productQuantity}</td>
                    <td>{row.unitPrice}</td>
                    <td>****</td>
                    <td>****</td>
                    <td>{row.amount}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}
