import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import eye from "../../assets/icons/eye.svg";
import { formatDate } from "../../utils/auth";
export default class ViewOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
    this.handleShow = this.handleShow.bind(this);
  }
  handleShow() {
    this.setState({
      show: !this.state.show
    });
  }
  render() {
    const { show } = this.state;
    const { row } = this.props;
    return (
      <React.Fragment>
        <Button
          variant="secondary"
          className="btn btn-xs"
          onClick={this.handleShow}
        >
          <img src={eye} width="10" height="10" alt="eye" />
        </Button>

        <Modal show={show} onHide={this.handleShow} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Order Summary</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table id="tech-companies-1" class="table  table-striped">
              <tbody>
                <tr>
                  <th >Print Order ID</th>
                  <td className="text-uppercase">{row.printOrderID}</td>
                </tr>
                <tr>
                  <th>Publication Date</th>
                  <td>{formatDate(row.publicationDate)}</td>
                </tr>
                <tr>
                  <th>Quantity</th>
                  <td>{row.quantity}</td>
                </tr>
                <tr>
                  <th data-priority="1">Printer Discrepancy</th>
                  <td>{row.printerDiscrepany}</td>
                </tr>
                <tr>
                  <th data-priority="1">Dispatch Discrepancy</th>
                  <td>{row.dispatchDiscrepany}</td>
                </tr>
                <tr>
                  <th data-priority="1">Delivery Discrepancy</th>
                  <td>{row.deliveryDiscrepany}</td>
                </tr>
                <tr>
                  <th data-priority="3">Confirmed Delivery</th>
                  <td>{row.confirmedDelivery}</td>
                </tr>
                <tr>
                  <th data-priority="1">Customer Affected</th>
                  <td>{row.customersAffected}</td>
                </tr>
                <tr>
                  <th data-priority="1">Value</th>
                  <td>TSH {row.value}</td>
                </tr>
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleShow}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}
