import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import {toMilliSeconds } from "../../utils/filterTask";
import { decryptInfo } from "../../utils/auth";
import { baseurl } from "../../config";
import axios from "axios";
import Loader from "react-loader-spinner";
import eye from "../../assets/icons/add.svg";
export default class AddDropPoint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      displayError: false,
      error: "",
      status: false,
      deliveryName: "",
      deliveryTime: "",
      deliveryZone: ""
    };
    this.handleShow = this.handleShow.bind(this);
    this.onHandleChange = this.onHandleChange.bind(this);
    this.onHandleSubmit = this.onHandleSubmit.bind(this);
  }
  handleShow() {
    this.setState({
      show: !this.state.show
    });
  }
  onHandleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }
  onHandleSubmit(event) {
    const { deliveryName, deliveryTime, deliveryZone } = this.state;
    event.preventDefault();
    this.setState({
      status: true
    });
    const user = decryptInfo("admin");
    axios({
      method: "post",
      url: `${baseurl}/route/addDropPoint`,
      data: {
          deliveryName,
          deliveryTime: toMilliSeconds(deliveryTime),
          deliveryZone,
          id: this.props.row._id
      },
      headers: {
        Authorization: `Bearer ${user.headers.authorization}`
      }
    })
      .then(res => {
        this.setState({
          status: false,
          displayError: true,
          error: `${res.data.message}`
        });
        console.log(res);
      })
      .catch(error => {
        console.log(error);
        this.setState({
          status: false,
          displayError: true,
          error: error.response? error.response.data.message: "Request has failed"
        });
      });
  }

  render() {
    const {
      show,
      displayError,
      error,
      status,
      deliveryName,
      deliveryTime,
      deliveryZone
    } = this.state;
    const { row } = this.props;

    console.log(row.orderCreation);
    return (
      <React.Fragment>
        <Button
          variant="secondary"
          className="btn btn-xs btn-secondary"
          disabled={this.props.disabled}
          onClick={this.handleShow}
        >
          <img src={eye} width="15" height="15" alt="eye" />
        </Button>

        <Modal show={show} onHide={this.handleShow} size="md">
          <Modal.Header closeButton>
            <Modal.Title className="text-uppercase">Add Drop Point</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div
                className="form-group row"
                style={{ display: `${displayError ? "block" : "none"}` }}
              >
                <div className="col-12">
                  <span className="text-danger"> {error}</span>
                </div>
              </div>

              <div className="form-group">
                <div className="col-sm-12 row">
                  <label htmlFor="input" className="col-sm-4  col-form-label">
                    Delivery Point Name
                  </label>
                  <div className="col-sm-6">
                    <input
                      className="form-control"
                      name="deliveryName"
                      type="text"
                      value={deliveryName}
                      onChange={this.onHandleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="col-sm-12 row">
                  <label htmlFor="input" className="col-sm-4 col-form-label">
                    ETA Day Zone
                  </label>
                  <div className="col-sm-6">
                    <input
                      className="form-control"
                      name="deliveryZone"
                      type="text"
                      value={deliveryZone}
                      onChange={this.onHandleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="col-sm-12 row">
                  <label htmlFor="input" className="col-sm-4 col-form-label">
                    Delivery time
                  </label>
                  <div className="col-sm-6">
                    <input
                      className="form-control"
                      name="deliveryTime"
                      type="time"
                      value={deliveryTime}
                      onChange={this.onHandleChange}
                    />
                  </div>
                </div>
              </div>

              <div
                className="form-group text-center "
                style={{ display: `${status ? "block" : "none"}` }}
              >
                <Loader type="Bars" color="#ff6805" width="50" height="30" />
              </div>
              <div className="form-group row">
                <div className="col-sm-3 mb-3 mt-3"></div>
                <div className="col-sm-4 text-right">
                  <button
                    className="btn button2 text-white"
                    onClick={this.onHandleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}
