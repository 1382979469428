import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import eye from "../../assets/icons/eye.svg";
import { decryptInfo } from "../../utils/auth";
import axios from "axios";
import { baseurl } from "../../config";
export default class ViewOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      displayError: false,
      error: "",
      data: [],
      status: false
    };
    this.handleShow = this.handleShow.bind(this);
  }
  handleShow() {
    this.setState({
      show: !this.state.show
    });
  }
  componentDidUpdate(){
    const user = decryptInfo("directCustomer");
    axios({
      method: "post",
      url: `${baseurl}/customer/viewBill`,
      data: {
        orderCode: this.props.orderCode
      },
      headers: {
        Authorization: user ? `Bearer ${user.headers.authorization}` : ""
      }
    })
      .then(res => {
        this.setState({
          data: res.data.data
        });
        console.log(res);
      })
      .catch(error => {
        console.log(error);
      });
  }
  render() {
    const { show, data } = this.state;
    return (
      <React.Fragment>
        <Button
          variant="secondary"
          className="btn btn-xs"
          onClick={this.handleShow}
        >
          <img src={eye} width="18" height="18" alt="eye" />
        </Button>

        <Modal show={show} onHide={this.handleShow} size="md">
          <Modal.Header closeButton>
            <Modal.Title>View Orders</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table table-stripped table-bordered">
              <thead>
                <th>Order Code</th>
                <th>Bill Amount</th>
              </thead>
              <tbody>
                {data.map((row, index) => (
                  <tr key={index}>
                    <td>{row.orderCode}</td>
                    <td>{row.billAmount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleShow}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}
