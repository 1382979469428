import React, {Component} from 'react'
import Wrapper from './wrapper'
import axios from 'axios'
import {baseurl} from '../../config'
import {decryptInfo} from '../../utils/auth'
import ViewPublisher from './view_publisher'
import EditPublisher from './edit_publisher'


class ListPublishers extends Component{
    constructor(props){
        super(props)
        this.state = {            
            data: [],
            role: null
        }
     
    }
    componentDidMount(){
        const user = decryptInfo('admin')
        console.log(user)
        axios({
            method: 'post',
            url: `${baseurl}/user/list`,
            headers: {
                Authorization: `Bearer ${user.headers.authorization}`
            },
            data: {
                role: 'publisher'
            }
        }).then((res)=>{
            console.log(res)
            this.setState({
                data: res.data.data
            })
        }).catch((error)=>{
            console.log(error)
        })
    }
    render(){
        const {
            data,
        } = this.state     
    
        return(
            <Wrapper>
                 <div className="page-content-wrapper ">
                    <div className="container container-fluid" >
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="page-title-box">
                                    <div className="row align-items-center">
                                        <div className="col-md-8">
                                            <h4 className="page-title m-0 ml-3">USERS</h4>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="row">
                        <div class="col-12">
                                    <div class="card m-b-30">
                                        <div class="card-body">
            
                                            <h4 class="mt-0 header-title">LIST PUBLISHERS</h4>                                      
                                            <div class="table-rep-plugin">                                                
                                                <div class="table-responsive b-0" data-pattern="priority-columns">
                                                    <table id="tech-companies-1" class="table  table-striped">
                                                        <thead>
                                                        <tr>
                                                            <th>Username</th>
                                                            <th data-priority="1">Role</th>  
                                                            <th>Status</th>  
                                                            <th>View Details</th>   
                                                            <th>Edit Publisher</th>                                                     
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                data.map((row,index)=>(
                                                                    <tr key={index}>
                                                                        <td>{row.userName}</td>
                                                                        <td>{row.role}</td>                                                    
                                                                        <td>{row.status}</td>
                                                                        <td><ViewPublisher row={row} /></td>
                                                                        <td><EditPublisher row={row} header={"Edit Publisher"} /></td>
                                                                    </tr>
                                                                ))
                                                            }                                                             
                                                        </tbody>
                                                    </table>
                                                </div>
            
                                            </div>
            
                                        </div>
                                    </div>
                                </div> 
                        </div> 
                    </div> 
                    </div>
            </Wrapper>
        )
    }
    
}

export default ListPublishers
