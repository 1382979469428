import React, {Component} from 'react'
import logo from '../../assets/images/logo.png'
import {NavLink} from 'react-router-dom'
import ReportBug from '../nav/report_bug'
class ChangeP extends Component{
    constructor(props){
        super(props)
        this.state = {

        }
    }
    componentWillMount(){
        document.body.style.background = "#fff"
        document.body.classList.add('fixed-left')
    }
    render(){
        return(
            <div className="account-pages">
            
            <div className="">
                <div className="row align-items-center">
                    <div className="col-lg-4 offset-lg-1">
                        <div className=" mb-0">
                            <div className="card-body">
                                <div className="p-2">                                    
                                    <div>
                                        <a href="/" className="logo logo-admin">
                                            <img src={logo} alt="logo" width="100" height="70" />
                                        </a>
                                    </div>
                                    
                                     <span className="font-18 font-weight-bold">Change Password</span>
                                    
                                </div>                               
        
                                <div className="p-2">
                                    <form className="form-horizontal m-t-20" action="index.html">
        
                                        <div className="form-group row">
                                            <div className="col-12">
                                                <input className="form-control" type="password" required="" placeholder="Password"/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-12">
                                                <input className="form-control" type="password" required="" placeholder="Confirm password"/>
                                            </div>
                                        </div>
                        
        
                                        <div className="form-group text-center row m-t-20">
                                            <div className="col-12">
                                                <button className="btn btn-primary btn-block waves-effect waves-light" type="submit">Submit</button>
                                            </div>
                                        </div>      
                                       
                                    </form>
                                </div>
        
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 logo-pic">
                       
                    </div>
                    <footer className="footer1">
                        <div className="col-lg-3 d-flex flex-row align-items-center">
                            <div className="col-sm-6">
                                <ReportBug />
                            </div>
                            <select name="language" className="select-css">
                                <option selected>English</option>
                                <option>Francais</option>
                                <option>Espanol</option>
                                <option>Portugues</option>
                            </select>
                        </div>
                        <div  className="footer-footer">
                            <span> &copy;2013-2019, Kopa Technologies, Inc. or it’s affiliates. All rights reserved </span>
                            <span><NavLink to="/terms">Terms of Use&nbsp;&nbsp;</NavLink></span>
                            <span><NavLink to="/terms">Privacy and Policy&nbsp;&nbsp;</NavLink></span>              
                        </div>         
                    </footer>
            
                </div>
              
            </div>
        </div>          
        )
    }
    
}
export default ChangeP