import React, { Component } from "react";
import Wrapper from "./wrapper";
import axios from "axios";
import { baseurl } from "../../config";
import { decryptInfo, formatDate } from "../../utils/auth";
import ViewSupplyDiscrepancy from "./view_supply_discrepancy";
import ViewReturnDiscrepancy from "./view_return_claim_discrepancy";
export default class ReturnDiscrepancy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      role: null,
    };
  }
  componentDidMount() {
    const user = decryptInfo("billOfficer");
    console.log(user);
    axios({
      method: "get",
      url: `${baseurl}/bill/returnsDiscrepancy`,
      headers: {
        Authorization: user ? `Bearer ${user.headers.authorization}` : "",
      },
    })
      .then((res) => {
        console.log(res);
        this.setState({
          data: res.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    const { data, role } = this.state;
    return (
      <Wrapper>
        <div className="page-content-wrapper ">
          <div className="container container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="row align-items-center">
                    <div className="col-md-8">
                      <h4 className="page-title m-0 ml-3">BILL OFFICER</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div class="col-12">
                <div class="card m-b-30">
                  <div class="card-body">
                    <h4 class="mt-0 header-title">VIEW RETURN CLAIM DISCREPANCY</h4>
                    <div class="table-rep-plugin">
                      <div
                        class="table-responsive b-0"
                        data-pattern="priority-columns"
                      >
                        <table
                          id="tech-companies-1"
                          class="table  table-striped"
                        >
                          <thead>
                            <tr>
                              <th>Select</th>
                              <th>Issue Date</th>
                              <th>Product Name</th>
                              <th>Customer Name</th>
                              <th>Route Name</th>
                              <th>Discrepancy Type</th>
                              <th>Discrepancy Quantity</th>
                              <th>Discrepancy Value</th>
                              <th>Invoice Balance</th>
                              <th data-priority="3">NS Track</th>
                              <th data-priority="3">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((row) => (
                              <tr key={row._id}>
                                <td>
                                  <input type="checkbox" />
                                </td>
                                <td>{formatDate(row.createdAt)}</td>
                                <td>{row.customerName}</td>
                                <td>{row.productName}</td>
                                <td>{row.routeName}</td>
                                <td>{row.type}</td>
                                <td>{row.discrepancy}</td>
                                <td>{row.value}</td>
                                <td>{row.balance}</td>
                                <td>***</td>
                                <td>
                                  <ViewReturnDiscrepancy row={row} />
                                </td>
                              </tr>
                            ))}
                            <td></td>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}
