import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {createStore,applyMiddleware} from 'redux'
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom' 
import reducers from './reducers/index'
import thunk from 'redux-thunk';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.js'
import './assets/style.css'
import 'typeface-open-sans'
import 'typeface-nunito'
const store = createStore(reducers,applyMiddleware(thunk))
ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
         <App />
        </BrowserRouter>
    </Provider>,    
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
