import React, {Component} from 'react'
import Wrapper from './wrapper'
import axios from 'axios'
import {baseurl} from '../../config'
import {decryptInfo} from '../../utils/auth'
import {roles} from '../../config'

class ListUsers extends Component{
    constructor(props){
        super(props)
        this.state = {            
            data: [],
            role: null
        }
     
    }
    componentDidMount(){
        const user = decryptInfo('admin')
        console.log(user)
        axios({
            method: 'get',
            url: `${baseurl}/user/list/all`,
            headers: {
                Authorization: user? `Bearer ${user.headers.authorization}`:''
            }
        }).then((res)=>{
            console.log(res)
            this.setState({
                data: res.data.data
            })
        }).catch((error)=>{
            console.log(error)
        })
    }
    render(){
        const {
            data,
            role
        } = this.state     
        let filteredRole = []
        if(!role){
            filteredRole = data
        }
        else{
            filteredRole  = data.filter((row)=>(
                row.role === role
            ))
        }
        return(
            <Wrapper>
                 <div className="page-content-wrapper ">
                    <div className="container container-fluid" >
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="page-title-box">
                                    <div className="row align-items-center">
                                        <div className="col-md-8">
                                            <h4 className="page-title m-0 ml-3">LIST OF USERS</h4>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="row">
                        <div class="col-12">
                                    <div class="card m-b-30">
                                        <div class="card-body">
            
                                            <h4 class="mt-0 header-title">ALL USERS</h4> 
                                            <div class="col-sm-12 text-right mb-3 pr-3">
                                               <select name="role" 
                                                    onChange={(event)=>this.setState({
                                                        role: event.target.value
                                                    })}
                                                    defaultValue={role}
                                                    className="custom-select bg-secondary text-white col-sm-2"
                                                >
                                                    <option selected disabled>Select role</option>
                                                    {
                                                        roles.map((role,index)=>(
                                                            <option 
                                                                key={index}
                                                                value= {role.role}
                                                            >
                                                                {role.roleName}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </div>                                        
                                            <div class="table-rep-plugin">                                                
                                                <div class="table-responsive b-0" data-pattern="priority-columns">
                                                    <table id="tech-companies-1" class="table  table-striped">
                                                        <thead>
                                                        <tr>
                                                            <th>Username</th>
                                                            <th data-priority="1">Email</th>
                                                            <th data-priority="3">Phone number</th>
                                                            <th data-priority="1">Status</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                filteredRole.map((row,index)=>(
                                                                    <tr key={index}>
                                                                        <td>{row.userName}</td>
                                                                        <td>{row.email}</td>
                                                                        <td>{row.phoneNumber}</td>
                                                                        <td>{row.status}</td>
                                                                    </tr>
                                                                ))
                                                            }                                                             
                                                        </tbody>
                                                    </table>
                                                </div>
            
                                            </div>
            
                                        </div>
                                    </div>
                                </div> 
                        </div> 
                    </div> 
                    </div>
            </Wrapper>
        )
    }
    
}

export default ListUsers
