import React, {Component} from 'react'
import Nav from '../nav/nav'
import Content from '../content/content'
import {decryptInfo} from '../../utils/auth'
import {Redirect} from 'react-router-dom'
import list from '../../assets/icons/list.svg'
class Wrapper extends Component{
    constructor(props){
        super(props)
        this.state = {}
    }
    render(){
        const user = decryptInfo('admin') 
          
        console.log(user)
        if(!user){
            return(
                <Redirect to="/login" />
            )
        }
        const links = [  
            {
                name: 'Register User',
                url: '/admin/registerUser',
                icon:list
            },
            {
                name: 'List All users',
                url: '/admin/listUsers',
                icon:list
            },
            {
                name: 'View Publishers',
                url: '/admin/publisherList',
                icon:list
            },
            {
                name: 'View HQ Employees',
                url: '/admin/employeesList',
                icon:list
            },
            {
                name: 'View Territory Employees',
                url: '/admin/territoryEmployees',
                icon:list
            },
            {
                name: 'View Service Providers',
                url: '/admin/serviceProviderList',
                icon:list
            },
            {
                name: 'View Direct Customers',
                url: '/admin/customerList',
                icon:list
            },
            {
                name: 'Publisher Link',
                url: '/admin/publisherLink',
                icon:list
            },
            {
                name: 'Add territory',
                url: '/admin/territoryAdd',
                icon:list
            },
            {
                name: 'View Territories',
                url: '/admin/territoryList',
                icon:list
            },
            {
                name: 'View Supply Route ',
                url: '/admin/routeList',
                icon:list
            },
            {
                name: 'Add customer type ',
                url: '/admin/customerTypeAdd',
                icon:list
            },
            {
                name: 'List customer type ',
                url: '/admin/customerTypeList',
                icon:list
            },
            {
                name: 'Add product',
                url: '/admin/productAdd',
                icon:list
            },
            {
                name: 'List products',
                url: '/admin/productList',
                icon:list
            },
            {
                name: 'List product prices',
                url: '/admin/productPrice',
                icon:list
            },
            {
                name: 'Set Product Price',
                url: '/admin/priceAdd',
                icon:list
            },

        ]
        const header = 'ADMIN'
        return(
        <div id="wrapper">
            <Nav links ={links} header={header} />           
            <Content>
                {this.props.children}
            </Content>
        </div>
        )
    }
    
}
export default Wrapper