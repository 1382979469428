import React, { Component } from "react";
import Wrapper from "./wrapper";
import axios from "axios";
import { baseurl } from "../../config";
import { decryptInfo, formatDate, toCommas } from "../../utils/auth";
import BillStatus from "./bill_status";
import RequestControlNumber from "./request_control_number";
import CheckBox from "../print_order_maker/check_box";
import { roles } from "../../config";
import ViewOrder from "./show_items";
import Loader from "react-loader-spinner";
class PrintManagerOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      role: null,
      displayError: false,
      error: "",
      status: false,
    };
  }
  componentDidMount() {
    const user = decryptInfo("printManager");
    console.log(user);
    axios({
      method: "get",
      url: `${baseurl}/printManager/list`,
      headers: {
        Authorization: user ? `Bearer ${user.headers.authorization}` : "",
      },
    })
      .then((res) => {
        console.log(res);
        this.setState({
          data: res.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  onSubmit = (event) => {
    event.preventDefault();
    this.setState({
      status: true,
    });
    const user = decryptInfo("printManager");
    let orderCodes = [];
    this.state.data.forEach((order) => {
      if (order.isChecked) {
        orderCodes.push(order.orderCode);
      }
    });
    axios({
      method: "post",
      url: `${baseurl}/printManager/billing`,
      data: {
        orderCodes: orderCodes,
      },
      headers: {
        Authorization: user ? `Bearer ${user.headers.authorization}` : "",
      },
    })
      .then((res) => {
        this.setState({
          status: false,
          displayError: true,
          error: `${res.data.message}`,
        });
        console.log(res);
        window.location.reload(false);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          status: false,
          displayError: true,
          error: error.response.data.message,
        });
      });
  };
  onCheck = (event) => {
    const { data } = this.state;
    data.forEach((row) => {
      if (row._id === event.target.value) {
        row.isChecked = event.target.checked;
      }
    });
    this.setState({
      data: data,
      displayError: false,
    });
  };
  render() {
    const { data, role, displayError, error, status } = this.state;
    console.log(role);
    let filteredRole = [];
    if (!role) {
      filteredRole = data;
    } else {
      filteredRole = data.filter(
        (row) => Date.parse(row.createdAt) === Date.parse(role)
      );
    }
    return (
      <Wrapper>
        <div className="page-content-wrapper ">
          <div className="container container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="row align-items-center">
                    <div className="col-md-8">
                      <h4 className="page-title m-0 ml-3">PRINT MANAGER</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div class="col-12">
                <div class="card m-b-30">
                  <div class="card-body">
                    <h4 class="mt-0 header-title">Print Order Execution</h4>
                    <div class="col-sm-12  text-right mb-3 pr-3">
                      <div
                        className="form-group row"
                        style={{
                          display: `${displayError ? "block" : "none"}`,
                        }}
                      >
                        <div className="col-12">
                          <span className="text-danger"> {error}</span>
                        </div>
                      </div>
                      <div
                        className="form-group text-center "
                        style={{ display: `${status ? "block" : "none"}` }}
                      >
                        <Loader
                          type="Bars"
                          color="#ff6805"
                          width="50"
                          height="30"
                        />
                      </div>
                      <button
                        className="btn btn-primary"
                        onClick={this.onSubmit}
                      >
                        Make Invoice
                      </button>
                    </div>
                    <div class="table-rep-plugin">
                      <div
                        class="table-responsive b-0"
                        data-pattern="priority-columns"
                      >
                        <table
                          id="tech-companies-1"
                          class="table  table-striped"
                        >
                          <thead>
                            <tr>
                              <th>Select</th>
                              <th>Publication Date</th>
                              <th>Order code</th>
                              <th data-priority="1">Handover Status</th>

                              <th data-priority="3">Internal bill amount</th>
                              <th>View Order</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredRole.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  <td>
                                    <CheckBox
                                      id={row._id}
                                      handleCheck={this.onCheck}
                                      disabled={
                                        row.billInitiated ||
                                        row.handleOver.status !== "confirmed"
                                      }
                                      checked={row.isChecked || false}
                                    />
                                  </td>
                                </td>
                                <td>{formatDate(row.createdAt)}</td>
                                <td>{row.orderCode}</td>
                                <td>
                                  {(row.handleOver && row.handleOver.status) ||
                                    ""}
                                </td>

                                <td>
                                  {toCommas(
                                    parseFloat(
                                      row.billAmount.internal.$numberDecimal
                                    )
                                  )}
                                </td>
                                <td>
                                  <ViewOrder row={row} />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default PrintManagerOrders;
