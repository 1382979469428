import React, { Component } from "react";
import Wrapper from "./wrapper";
import { decryptInfo } from "../../utils/auth";
import axios from "axios";
import { baseurl } from "../../config";
import Loader from "react-loader-spinner";
import { toMilliSeconds, toTime } from "../../utils/filterTask";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
class AddRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routeName: "",
      routeType: "",
      deliveryTime: 0,
      territoryId: this.props.territory ? this.props.territory._id : "",
      mondayEnd: "",
      mondayStart: "",
      tuesdayEnd: "",
      tuesdayStart: "",
      wednesdayEnd: "",
      wednesdayStart: "",
      thursdayEnd: "",
      thursdayStart: "",
      fridayEnd: "",
      fridayStart: "",
      saturdayEnd: "",
      saturdayStart: "",
      sundayEnd: "",
      sundayStart: "",
      deliveryName: "",
      displayError: false,
      data: [],
      deliveryPoints: [],
      shippingPoints: [],
      shippingTerritory: "",
      shippingName: "",
      shippingZone: "",
      error: "",
      status: false
    };
    this.onHandleChange = this.onHandleChange.bind(this);
    this.onHandleSubmit = this.onHandleSubmit.bind(this);
    this.onHandleDelivery = this.onHandleDelivery.bind(this);
    this.onHandleShipping = this.onHandleShipping.bind(this);
  }

  componentDidMount() {
    const user = decryptInfo("admin");
    console.log(user);
    axios({
      method: "get",
      url: `${baseurl}/territory/list`,
      headers: {
        Authorization: `Bearer ${user.headers.authorization}`
      }
    })
      .then(res => {
        console.log(res);
        this.setState({
          data: res.data.data
        });
      })
      .catch(error => {
        console.log(error);
      });
  }
  onHandleDelivery(event) {
    event.preventDefault();
    const {
      deliveryTime,
      deliveryName,
      deliveryZone,
      deliveryPoints
    } = this.state;
    const point = deliveryPoints.find(
      delivery => delivery.name === deliveryName
    );
    if (deliveryName === "" || !deliveryTime || !deliveryZone) {
      this.setState({
        error: "Please fill all drop point information",
        displayError: true
      });
    } else if (point) {
      this.setState({
        error: "Delivery point already exists",
        displayError: true
      });
    } else {
      this.setState({
        deliveryPoints: [
          {
            name: deliveryName,
            eta: {
              dayZone: deliveryZone,
              time: toMilliSeconds(deliveryTime)
            }
          },
          ...this.state.deliveryPoints
        ],
        deliveryName: "",
        deliveryTime: 0
      });
    }
  }
  onHandleShipping(event) {
    event.preventDefault();
    const {
      shippingPoints,
      shippingTerritory,
      shippingName,
      shippingTime,
      shippingZone
    } = this.state;
    const point = shippingPoints.find(
      delivery => delivery.name === shippingName
    );
    if (
      shippingName === "" ||
      shippingTime === "" ||
      shippingTerritory === "" ||
      shippingZone === ""
    ) {
      this.setState({
        error: "Please select all correct shipping details",
        displayError: true
      });
    } else if (point) {
      this.setState({
        error: "Delivery point already exists",
        displayError: true
      });
    } else {
      this.setState({
        deliveryPoints: [
          {
            name: shippingName,
            eta: {
              dayZone: shippingZone,
              time: toMilliSeconds(shippingTime)
            },
            territoryId: shippingTerritory
          },
          ...this.state.deliveryPoints
        ],
        shippingName: "",
        shippingTime: ""
      });
    }
  }
  onHandleSubmit(event) {
    const {
      routeName,
      routeType,
      deliveryPoints,
      shippingPoints,
      territoryId
    } = this.state;
    event.preventDefault();
    let routeOperation = [
      {
        day: 0,
        time: {
          start: toMilliSeconds(this.state.sundayStart),
          end: toMilliSeconds(this.state.sundayEnd)
        }
      },
      {
        day: 1,
        time: {
          start: toMilliSeconds(this.state.mondayStart),
          end: toMilliSeconds(this.state.mondayEnd)
        }
      },
      {
        day: 2,
        time: {
          start: toMilliSeconds(this.state.tuesdayStart),
          end: toMilliSeconds(this.state.tuesdayEnd)
        }
      },
      {
        day: 3,
        time: {
          start: toMilliSeconds(this.state.wednesdayStart),
          end: toMilliSeconds(this.state.wednesdayEnd)
        }
      },
      {
        day: 4,
        time: {
          start: toMilliSeconds(this.state.thursdayStart),
          end: toMilliSeconds(this.state.thursdayEnd)
        }
      },
      {
        day: 5,
        time: {
          start: toMilliSeconds(this.state.fridayStart),
          end: toMilliSeconds(this.state.fridayEnd)
        }
      },
      {
        day: 6,
        time: {
          start: toMilliSeconds(this.state.saturdayStart),
          end: toMilliSeconds(this.state.saturdayEnd)
        }
      }
    ];
    let query = {};
    if (routeType === "Internal") {
      query.dropPoint = deliveryPoints;
    }
    if (routeType === "External") {
      query.shippingPoint = shippingPoints;
    }

    this.setState({
      status: true
    });
    const user = decryptInfo("admin");
    axios({
      method: "post",
      url: `${baseurl}/route/new`,
      data: {
        ...query,
        routeName,
        routeType,
        routeOperation,
        territoryId
      },
      headers: {
        Authorization: `Bearer ${user.headers.authorization}`
      }
    })
      .then(res => {
        this.setState({
          status: false,
          displayError: true,
          error: `${res.data.message}`
        });
        console.log(res);
      })
      .catch(error => {
        console.log(error);
        this.setState({
          status: false,
          displayError: true,
          error: error.response.data.message
        });
      });
  }
  onHandleChange(event) {
    console.log(this.state);
    this.setState({
      [event.target.name]: event.target.value,
      displayError: false
    });
  }

  render() {
    const {
      displayError,
      error,
      status,
      routeName,
      routeType,
      deliveryTime,
      mondayEnd,
      mondayStart,
      tuesdayEnd,
      tuesdayStart,
      wednesdayEnd,
      wednesdayStart,
      thursdayEnd,
      thursdayStart,
      fridayEnd,
      fridayStart,
      saturdayEnd,
      saturdayStart,
      sundayEnd,
      sundayStart,
      deliveryName,
      deliveryZone,
      shippingName,
      shippingTime,
      shippingZone,
      shippingTerritory,
    } = this.state;
    let internal = routeType === "Internal" ? false : true;
    let external = routeType === "External" ? false : true;
    if (!this.props.territory || !this.props.territoryList) {
      return <Redirect to="/admin/territoryList" />;
    }
    const formatTime = (time, dayZone) => {
      let day = dayZone >= 0 ? dayZone : dayZone * -1;
      let description = "";
      if (dayZone === 0) {
        description = "On Publication Day";
      } else if (dayZone < 0) description = "Day(s) before Publication Date";
      else if (dayZone > 0) description = "Day(s) after Publication Date";

      return dayZone === 0
        ? `${toTime(time)} ${description}`
        : `${toTime(time)} ${day} ${description}`;
    };
    return (
      <Wrapper>
        <div className="page-content-wrapper ">
          <div className="container container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="row align-items-center">
                    <div className="col-md-8">
                      <h4 className="page-title m-0 ml-3">admin</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card m-b-30">
                  <div className="card-body">
                    <h4 className="mt-0 header-title">Add Supply Route</h4>
                    <p className="text-muted m-b-30 font-14"></p>
                    <div
                      className="form-group row"
                      style={{ display: `${displayError ? "block" : "none"}` }}
                    >
                      <div className="col-12">
                        <span className="text-danger"> {error}</span>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="input"
                        className="col-sm-3 col-form-label"
                      >
                        Territory Name
                      </label>
                      <div className="col-sm-4">
                        <p>{this.props.territory.territoryName}</p>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="input"
                        className="col-sm-3 col-form-label"
                      >
                        Route name
                      </label>
                      <div className="col-sm-4">
                        <input
                          className="form-control"
                          name="routeName"
                          type="text"
                          value={routeName}
                          onChange={this.onHandleChange}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        htmlFor="input"
                        className="col-sm-3 col-form-label"
                      >
                        Route type
                      </label>
                      <div className="col-sm-4">
                        <select
                          className="custom-select"
                          name="routeType"
                          defaultValue={routeType}
                          onChange={this.onHandleChange}
                        >
                          <option selected>Select route type</option>
                          <option value="External">External</option>
                          <option value="Internal">Internal</option>
                        </select>
                      </div>
                    </div>
                    <p className="text-danger">
                      Delivery window starts at:{" "}
                      {this.props.territory.deliveryWindow
                        ? formatTime(
                            this.props.territory.deliveryWindow.start.time,
                            this.props.territory.deliveryWindow.start.dayZone
                          )
                        : ""}
                    </p>
                    <p className="text-danger">
                      Delivery window ends at:{" "}
                      {this.props.territory.deliveryWindow
                        ? formatTime(
                            this.props.territory.deliveryWindow.end.time,
                            this.props.territory.deliveryWindow.end.dayZone
                          )
                        : ""}
                    </p>
                    <h5 className="mb-2 mt-2">Route Operation Time</h5>
                    <div className="form group ">
                      <div className="col-sm-12 row">
                        <label
                          htmlFor="input"
                          className="col-sm-3 col-form-label"
                        >
                          Monday
                        </label>
                        <label
                          htmlFor="input"
                          className="col-sm-3 col-form-label"
                        >
                          Start Time
                        </label>
                        <div className="col-sm-3 mb-3 mt-3">
                          <input
                            className="form-control"
                            name="mondayStart"
                            type="time"
                            value={mondayStart}
                            onChange={this.onHandleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 row">
                        <div className="col-sm-3"></div>
                        <label
                          htmlFor="input"
                          className="col-sm-3 col-form-label"
                        >
                          End Time
                        </label>
                        <div className="col-sm-3 mb-3 mt-3">
                          <input
                            className="form-control"
                            name="mondayEnd"
                            type="time"
                            value={mondayEnd}
                            onChange={this.onHandleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form group ">
                      <div className="col-sm-12 row">
                        <label
                          htmlFor="input"
                          className="col-sm-3 col-form-label"
                        >
                          Tuesday
                        </label>
                        <label
                          htmlFor="input"
                          className="col-sm-3 col-form-label"
                        >
                          Start Time
                        </label>
                        <div className="col-sm-3 mb-3 mt-3">
                          <input
                            className="form-control"
                            name="tuesdayStart"
                            type="time"
                            value={tuesdayStart}
                            onChange={this.onHandleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 row">
                        <div className="col-sm-3"></div>
                        <label
                          htmlFor="input"
                          className="col-sm-3 col-form-label"
                        >
                          End Time
                        </label>
                        <div className="col-sm-3 mb-3 mt-3">
                          <input
                            className="form-control"
                            name="tuesdayEnd"
                            type="time"
                            value={tuesdayEnd}
                            onChange={this.onHandleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form group ">
                      <div className="col-sm-12 row">
                        <label
                          htmlFor="input"
                          className="col-sm-3 col-form-label"
                        >
                          Wednesday
                        </label>
                        <label
                          htmlFor="input"
                          className="col-sm-3 col-form-label"
                        >
                          Start Time
                        </label>
                        <div className="col-sm-3 mb-3 mt-3">
                          <input
                            className="form-control"
                            name="wednesdayStart"
                            type="time"
                            value={wednesdayStart}
                            onChange={this.onHandleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 row">
                        <div className="col-sm-3"></div>
                        <label
                          htmlFor="input"
                          className="col-sm-3 col-form-label"
                        >
                          End Time
                        </label>
                        <div className="col-sm-3 mb-3 mt-3">
                          <input
                            className="form-control"
                            name="wednesdayEnd"
                            type="time"
                            value={wednesdayEnd}
                            onChange={this.onHandleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form group">
                      <div className="col-sm-12 row">
                        <label
                          htmlFor="input"
                          className="col-sm-3 col-form-label"
                        >
                          Thursday
                        </label>
                        <label
                          htmlFor="input"
                          className="col-sm-3 col-form-label"
                        >
                          Start Time
                        </label>
                        <div className="col-sm-3 mb-3 mt-3">
                          <input
                            className="form-control"
                            name="thursdayStart"
                            type="time"
                            value={thursdayStart}
                            onChange={this.onHandleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 row">
                        <div className="col-sm-3"></div>
                        <label
                          htmlFor="input"
                          className="col-sm-3 col-form-label"
                        >
                          End Time
                        </label>
                        <div className="col-sm-3 mb-3 mt-3">
                          <input
                            className="form-control"
                            name="thursdayEnd"
                            type="time"
                            value={thursdayEnd}
                            onChange={this.onHandleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form group ">
                      <div className="col-sm-12 row">
                        <label
                          htmlFor="input"
                          className="col-sm-3 col-form-label"
                        >
                          Friday
                        </label>
                        <label
                          htmlFor="input"
                          className="col-sm-3 col-form-label"
                        >
                          Start Time
                        </label>
                        <div className="col-sm-3 mb-3 mt-3">
                          <input
                            className="form-control"
                            name="fridayStart"
                            type="time"
                            value={fridayStart}
                            onChange={this.onHandleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 row">
                        <div className="col-sm-3"></div>
                        <label
                          htmlFor="input"
                          className="col-sm-3 col-form-label"
                        >
                          End Time
                        </label>
                        <div className="col-sm-3 mb-3 mt-3">
                          <input
                            className="form-control"
                            name="fridayEnd"
                            type="time"
                            value={fridayEnd}
                            onChange={this.onHandleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form group ">
                      <div className="col-sm-12 row">
                        <label
                          htmlFor="input"
                          className="col-sm-3 col-form-label"
                        >
                          Saturday
                        </label>
                        <label
                          htmlFor="input"
                          className="col-sm-3 col-form-label"
                        >
                          Start Time
                        </label>
                        <div className="col-sm-3 mb-3 mt-3">
                          <input
                            className="form-control"
                            name="saturdayStart"
                            type="time"
                            value={saturdayStart}
                            onChange={this.onHandleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 row">
                        <div className="col-sm-3"></div>
                        <label
                          htmlFor="input"
                          className="col-sm-3 col-form-label"
                        >
                          End Time
                        </label>
                        <div className="col-sm-3 mb-3 mt-3">
                          <input
                            className="form-control"
                            name="saturdayEnd"
                            type="time"
                            value={saturdayEnd}
                            onChange={this.onHandleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form group ">
                      <div className="col-sm-12 row">
                        <label
                          htmlFor="input"
                          className="col-sm-3 col-form-label"
                        >
                          Sunday
                        </label>
                        <label
                          htmlFor="input"
                          className="col-sm-3 col-form-label"
                        >
                          Start Time
                        </label>
                        <div className="col-sm-3 mb-3 mt-3 ">
                          <input
                            className="form-control"
                            name="sundayStart"
                            type="time"
                            value={sundayStart}
                            onChange={this.onHandleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 row">
                        <div className="col-sm-3"></div>
                        <label
                          htmlFor="input"
                          className="col-sm-3 col-form-label"
                        >
                          End Time
                        </label>
                        <div className="col-sm-3 mb-3 mt-3">
                          <input
                            className="form-control"
                            name="sundayEnd"
                            type="time"
                            value={sundayEnd}
                            onChange={this.onHandleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      style={{ display: `${internal ? "none" : "block"}` }}
                      className="form-group"
                    >
                      <h5>Add Drop Point</h5>
                      <div className="col-sm-12 row">
                        <label
                          htmlFor="input"
                          className="col-sm-3  col-form-label"
                        >
                          Delivery Point Name
                        </label>
                        <div className="col-sm-3 mb-3">
                          <input
                            className="form-control"
                            name="deliveryName"
                            type="text"
                            value={deliveryName}
                            onChange={this.onHandleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 row">
                        <label
                          htmlFor="input"
                          className="col-sm-3 col-form-label"
                        >
                          ETA Day Zone
                        </label>
                        <div className="col-sm-3">
                          <input
                            className="form-control"
                            name="deliveryZone"
                            type="text"
                            value={deliveryZone}
                            onChange={this.onHandleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 row">
                        <label
                          htmlFor="input"
                          className="col-sm-3 mb-3 mt-3 col-form-label"
                        >
                          Delivery time
                        </label>
                        <div className="col-sm-3 mb-3 mt-3">
                          <input
                            className="form-control"
                            name="deliveryTime"
                            type="time"
                            value={deliveryTime}
                            onChange={this.onHandleChange}
                          />
                        </div>
                        <div className="col-sm-3 mb-3 mt-3">
                          <button
                            className="btn btn-md btn-primary"
                            onClick={this.onHandleDelivery}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{ display: `${external ? "none" : "block"}` }}
                      className="form-group"
                    >
                      <h5>Add Shipping Point</h5>
                      <div className="col-sm-12 row">
                        <label className="col-sm-3 col-form-label">
                          Select territory
                        </label>
                        <div className="col-sm-3 mb-3">
                          <select
                            className="custom-select"
                            name="shippingTerritory"
                            defaultValue={shippingTerritory}
                            onChange={this.onHandleChange}
                          >
                            <option selected>Select territory</option>
                            {this.props.territoryList.map(
                              (territory, index) => (
                                <option key={index} value={territory._id}>
                                  {territory.territoryName}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12 row">
                        <label
                          htmlFor="input"
                          className="col-sm-3 col-form-label"
                        >
                          Shipping Point Name
                        </label>
                        <div className="col-sm-3 mb-3">
                          <input
                            className="form-control"
                            name="shippingName"
                            type="text"
                            value={shippingName}
                            onChange={this.onHandleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 row">
                        <label
                          htmlFor="input"
                          className="col-sm-3 col-form-label"
                        >
                          ETA Day Zone
                        </label>
                        <div className="col-sm-3 mb-3">
                          <input
                            className="form-control"
                            name="shippingZone"
                            type="text"
                            value={shippingZone}
                            onChange={this.onHandleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 row">
                        <label
                          htmlFor="input"
                          className="col-sm-3 mb-3 mt-3 col-form-label"
                        >
                          Shipping Time
                        </label>
                        <div className="col-sm-3 mb-3 mt-3">
                          <input
                            className="form-control"
                            name="shippingTime"
                            type="time"
                            value={shippingTime}
                            onChange={this.onHandleChange}
                          />
                        </div>
                        <div className="col-sm-3 mb-3 mt-3">
                          <button
                            className="btn btn-md btn-primary"
                            onClick={this.onHandleShipping}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      className="form-group text-center "
                      style={{ display: `${status ? "block" : "none"}` }}
                    >
                      <Loader
                        type="Bars"
                        color="#ff6805"
                        width="50"
                        height="30"
                      />
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-3 mb-3 mt-3"></div>
                      <div className="col-sm-4 text-right">
                        <button
                          className="btn button2 text-white"
                          onClick={this.onHandleSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    territory: state.territory.territory,
    territoryList: state.territory.territoryList
  };
}

export default connect(mapStateToProps, null)(AddRoute);
