import React from 'react'
import {Switch,Route} from 'react-router-dom'
import BulkRegister from './containers/admin/bulk_register'
import RegisterUser from './containers/admin/register_user'
import ListUsers from './containers/admin/list_users'
import TerritoryAdd from './containers/admin/add_territory'
import ListTerritories from './containers/admin/list_territories'
import AddRoute from './containers/admin/add_route'
import ListRoute from './containers/admin/list_routes'
import CustomerTypeAdd from './containers/admin/add_customer_type'
import CustomerTypeList from './containers/admin/list_customer_type'
import ListPublishers from './containers/admin/list_publishers'
import ListEmployees from './containers/admin/list_employees'
import ListCustomers from './containers/admin/list_customers'
import AddProduct from './containers/admin/add_product'
import ListProduct from './containers/admin/list_products'
import AddPrice from './containers/admin/add_price'
import CustomerPublisherLink from './containers/admin/customer_publisher_link'
import ListProductPrice from './containers/admin/list_product_price'
import ActivationCode from './containers/auth/activate_code'
import ListOrder from './containers/publisher/list_orders'
import PublisherOrderSuccessful from './containers/publisher/order_succesful'
import PublisherOrderFailed from './containers/publisher/order_failed'
import PublisherListProducts from './containers/publisher/list_products'
import Login from './containers/auth/login'
import NotFound from './components/not_found'
import Home from './containers/home/home'
import ChangePassword from './containers/auth/change_password'
import Logout from './components/logout'
import ListOrders from './containers/print_order_maker/list_orders'
import OrderSuccessful from './containers/print_order_maker/order_succesful'
import OrderFailed from './containers/print_order_maker/order_failed'
import BulkOrder from './containers/print_order_maker/bulk_order'
import SingleOrder from './containers/print_order_maker/single_order'
import ReceivedOrders from './containers/print_manager/confirm_order'
import PrintManagerOrders from './containers/print_manager/list_orders'
import ConfirmNightHandleover from './containers/night_shift_supervisor/confirm_handlover'
import NightShiftOrders from './containers/night_shift_supervisor/list_orders'
import ConfirmDriverHandleover from './containers/driver/confirm_handlover'
import DriverOrders from './containers/driver/list_orders'
import ConfirmCustomerHandover from './containers/customer/confirm_handlover'
import CustomerOrders from './containers/customer/list_orders'
import ReturnClaims from './containers/return_officer/list_orders'
import ConfirmSubmission from './containers/store_officer/confirm_handlover'
import ListStore from './containers/store_officer/list_orders'
import ListServiceProvider from './containers/admin/list_service_provider'
import LateIncidents from './containers/publisher/late_incidents'
import NoDataEntry from './containers/publisher/no_data_entry_incidents'
import OrderExecution from './containers/publisher/order_execution'
import ReturnControl from './containers/publisher/return_control'
import CustomerSales from './containers/publisher/customer_sales'
import UnsettledBills from './containers/bills/unsettled_bill'
import SettledBills from './containers/bills/settled_bills'
import CustomerInvoices from './containers/customer/invoices'
import TerritoryEmployees from './containers/admin/list_territoryEmployees'
import EditTerritory from './containers/admin/edit_territory'
import ListPublisherEmployees from './containers/publisher/list_publisher_employees'
import PublisherRoutes from './containers/publisher/list_routes'
import PublisherTerritories from './containers/publisher/list_territories'
import PublisherServiceProvider from './containers/publisher/list_publisher_service_providers'
import PublisherCustomer from './containers/publisher/list_customers'
import ParcelList from './containers/night_shift_supervisor/list_parcel'
import PrintManagerInvoices from './containers/print_manager/invoices'
import FinanceSettledBills from './containers/financeManager/settled_bills'
import FinanceUnsettledBills from './containers/financeManager/unsettled_bill'
import CirculationUnsettledBills from './containers/circulationManager/unsettled_bill'
import CirculationSettledBills from './containers/circulationManager/settled_bills'
import NewInvoices from './containers/bills/new_invoices'
import InvoiceAdjustment from './containers/bills/invoice_adjustments'
import DebitCreditNotes from './containers/bills/debit_credit_notes'
import AdjustedInvoices from './containers/bills/adjusted_invoices'
import PostedPaymentNotes from './containers/bills/posted_payment_notes'
import SettledPaymentNotes from './containers/bills/settled_payment_notes'
import ConfirmedPaymentNotes from './containers/bills/confirmed_payment_notes'
import DisputedPayment from './containers/bills/disputed_payment'
import Statement from './containers/bills/statement'
import InvoiceAge from './containers/bills/invoice_age'
import SupplyDiscrepancy from './containers/bills/supply_discrepancy'
import ReturnDiscrepancy from './containers/bills/return_claim_discrepancy'
import PrintInventoryTracker from './containers/circulationManager/print_inventory_tracker'
import PrintInventoryBillTracker from './containers/bills/print_inventory_tracker'

const Routes = ()=>{
    return(
       <Switch>         
           <Route exact path="/login" component= {Login} />
           <Route exact path="/activationCode" component= {ActivationCode} />
           <Route exact path="/" component= {Home} />
           <Route exact path="/logout" component= {Logout} />
           <Route path="/admin/home" component= {ListUsers} />
           <Route path="/forgotPassword" component= {ChangePassword} />
           <Route path="/admin/bulkRegister" component= {BulkRegister} />
           <Route path="/admin/registerUser" component= {RegisterUser} />
           <Route path="/admin/listUsers" component= {ListUsers} />
           <Route path="/admin/territoryAdd" component= {TerritoryAdd} />
           <Route path="/admin/routeAdd" component= {AddRoute} />
           <Route path="/admin/routeList" component= {ListRoute} />
           <Route path="/admin/territoryList" component= {ListTerritories} />
           <Route path="/admin/customerTypeAdd" component= {CustomerTypeAdd} />
           <Route path="/admin/customerTypeList" component= {CustomerTypeList} />
           <Route path="/admin/publisherList" component= {ListPublishers} />
           <Route path="/admin/employeesList" component= {ListEmployees} />
           <Route path="/admin/serviceProviderList" component= {ListServiceProvider} />
           <Route path="/admin/customerList" component= {ListCustomers} />
           <Route path="/admin/productAdd" component= {AddProduct} />
           <Route path="/admin/productList" component= {ListProduct} />
           <Route path="/admin/priceAdd" component= {AddPrice} />
           <Route path="/admin/productPrice" component= {ListProductPrice} />
           <Route path="/admin/publisherLink" component= {CustomerPublisherLink} />
           <Route path="/admin/territoryEmployees" component= {TerritoryEmployees} />
           <Route path="/admin/editTerritory" component= {EditTerritory} />
           <Route path="/publisher/home" component= {PublisherListProducts} />         
           <Route path="/publisher/listOrder" component= {ListOrder} />
           <Route path="/publisher/products" component= {PublisherListProducts} />
           <Route path="/publisher/OrderSuccessful" component= {PublisherOrderSuccessful} />
           <Route path="/publisher/OrderFailed" component= {PublisherOrderFailed} />
           <Route path="/publisher/lateIncidents" component= {LateIncidents} />
           <Route path="/publisher/noDataEntry" component= {NoDataEntry} />
           <Route path="/publisher/orderExecution" component= {OrderExecution} />
           <Route path="/publisher/returnControl" component= {ReturnControl} />
           <Route path="/publisher/customerSales" component= {CustomerSales} />
           <Route path="/publisher/employees" component= {ListPublisherEmployees} />
           <Route path="/publisher/routes" component= {PublisherRoutes} />
           <Route path="/publisher/territories" component= {PublisherTerritories} />
           <Route path="/publisher/providers" component= {PublisherServiceProvider} /> 
           <Route path="/publisher/customers" component= {PublisherCustomer} />            
           <Route path="/printOrderMaker/home" component= {ListOrders} />
           <Route path="/printOrderMaker/list" component= {ListOrders} />
           <Route path="/printOrderMaker/OrderSuccessful" component= {OrderSuccessful} />
           <Route path="/printOrderMaker/OrderFailed" component= {OrderFailed} />
           <Route path="/printOrderMaker/bulkOrder" component= {BulkOrder} />
           <Route path="/printOrderMaker/singleOrder" component= {SingleOrder} />
           <Route path="/printManager/received" component= {ReceivedOrders} />
           <Route path="/printManager/home" component= {PrintManagerOrders} />
           <Route path="/printManager/list" component= {PrintManagerOrders} />
           <Route path="/printManager/invoices" component= {PrintManagerInvoices} />
           <Route path="/nightShiftSupervisor/home" component= {NightShiftOrders} />
           <Route path="/nightShiftSupervisor/list" component= {NightShiftOrders} />
           <Route path="/nightShiftSupervisor/confirm" component= {ConfirmNightHandleover} />
           <Route path="/nightShiftSupervisor/listParcel" component= {ParcelList} />
           <Route path="/driver/home" component= {DriverOrders} />
           <Route path="/driver/list" component= {DriverOrders} />
           <Route path="/driver/confirm" component= {ConfirmDriverHandleover} />
           <Route path="/directCustomer/home" component= {CustomerOrders} />
           <Route path="/directCustomer/list" component= {CustomerOrders} />
           <Route path="/directCustomer/confirm" component= {ConfirmCustomerHandover} />
           <Route path="/directCustomer/invoices" component= {CustomerInvoices} />
           <Route path="/returnOfficer/home" component= {ReturnClaims} />
           <Route path="/returnOfficer/listClaims" component= {ReturnClaims} />
           <Route path="/storeOfficer/home" component= {ListStore} />
           <Route path="/storeOfficer/submission" component= {ConfirmSubmission} />
           <Route path="/storeOfficer/list" component= {ListStore} />
           <Route path="/billOfficer/home" component= {UnsettledBills} />
           <Route path="/billOfficer/settledBills" component= {SettledBills} />
           <Route path="/billOfficer/newInvoice" component= {NewInvoices} />
           <Route path="/billOfficer/invoiceAdjustment" component= {InvoiceAdjustment} />
           <Route path="/billOfficer/adjustedInvoices" component= {AdjustedInvoices} />
           <Route path="/billOfficer/debitCreditNotes" component= {DebitCreditNotes} />
           <Route path="/billOfficer/postedPaymentNotes" component= {PostedPaymentNotes} />
           <Route path="/billOfficer/settledPaymentNotes" component= {SettledPaymentNotes} />
           <Route path="/billOfficer/confirmedPaymentNotes" component= {ConfirmedPaymentNotes} />
           <Route path="/billOfficer/disputedPayment" component= {DisputedPayment} />
           <Route path="/billOfficer/statement" component= {Statement} />
           <Route path="/billOfficer/invoiceAge" component= {InvoiceAge} />
           <Route path="/billOfficer/supplyDiscrepancy" component= {SupplyDiscrepancy} />
           <Route path="/financeManager/home" component= {FinanceUnsettledBills} />
           <Route path="/financeManager/settledBills" component= {FinanceSettledBills} />
           <Route path="/circulationManager/home" component= {CirculationUnsettledBills} />
           <Route path="/circulationManager/settledBills" component= {CirculationSettledBills} />
           <Route path="/circulationManager/printInvetoryTracker" component= {PrintInventoryTracker} />
           <Route path="/billOfficer/supplyDiscrepancy" component= {SupplyDiscrepancy} />
           <Route path="/billOfficer/returnsDiscrepancy" component= {ReturnDiscrepancy} />
           <Route path="/billOfficer/printInvetoryTracker" component= {PrintInventoryBillTracker} />


           <Route path="*" component={NotFound} />
       </Switch>
    )
}
export default Routes