import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import Loader from "react-loader-spinner";
import eye from "../../assets/icons/edit.svg";
import { decryptInfo } from "../../utils/auth";
import axios from "axios";
import { baseurl } from "../../config";
import {withRouter} from 'react-router-dom'
class EditPublisher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      email: this.props.row.email,
      phoneNumber: this.props.row.phoneNumber,
      userName: this.props.row.userName,
      displayError: false,
      error: "",
      status: false,
      data: []
    };
    this.handleShow = this.handleShow.bind(this);
    this.onHandleChange = this.onHandleChange.bind(this);
    this.onHandleSubmit = this.onHandleSubmit.bind(this);
    this.onHandleClose = this.onHandleClose.bind(this)
  }
  handleShow() {
    this.setState({
      show: !this.state.show
    });
  }
  onHandleClose(){
    this.setState({
      show: false
    });
  
    
  }
  onHandleSubmit(event) {
    event.preventDefault();
    const { email, phoneNumber, userName } = this.state;

    this.setState({
      status: true
    });
    const user = decryptInfo("admin");
    axios({
      method: "post",
      url: `${baseurl}/user/edit/publisher`,
      data: {
        id: this.props.row._id,
        data: {
          userName,
          phoneNumber,
          email
        }
      },
      headers: {
        Authorization: `Bearer ${user.headers.authorization}`
      }
    })
      .then(res => {
        this.setState({
          status: false,
          displayError: true,
          error: `${res.data.message}`
        });
        window.location.reload(false)      
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          status: false,
          displayError: true,
          error: error.response? error.response.data.message: "Request has failed"
        });
      });
  }
  onHandleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
      displayError: true
    });
  }

  render() {
    const {
      show,
      status,
      error,
      displayError,
      userName,
      email,
      phoneNumber
    } = this.state;
  

    return (
      <React.Fragment>
        <Button
          variant="secondary"
          className="btn btn-xs btn-secondary"
          disabled={this.props.disabled}
          onClick={this.handleShow}
        >
          <img src={eye} width="15" height="15" alt="eye" />
        </Button>

        <Modal show={show} onHide={this.onHandleClose} size="md">
          <Modal.Header closeButton>
            <Modal.Title className="text-uppercase">{this.props.header}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p className="text-muted m-b-30 font-14"></p>
              <div
                className="form-group row"
                style={{ display: `${displayError ? "block" : "none"}` }}
              >
                <div className="col-12">
                  <span className="text-danger"> {error}</span>
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="input" className="col-sm-4 col-form-label">
                  Username
                </label>
                <div className="col-sm-7">
                  <input
                    className="form-control"
                    name="userName"
                    type="text"
                    value={userName}
                    onChange={this.onHandleChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="input" className="col-sm-4 col-form-label">
                  Email
                </label>
                <div className="col-sm-7">
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    value={email}
                    onChange={this.onHandleChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="input" className="col-sm-4 col-form-label">
                  Phone number
                </label>
                <div className="col-sm-7">
                  <input
                    className="form-control"
                    name="phoneNumber"
                    type="tel"
                    value={phoneNumber}
                    onChange={this.onHandleChange}
                  />
                </div>
              </div>

              <div
                className="form-group text-center "
                style={{ display: `${status ? "block" : "none"}` }}
              >
                <Loader type="Bars" color="#ff6805" width={20}height={20} />
              </div>
              <div className="form-group row">
                <div className="col-sm-4"></div>
                <div className="col-sm-6 text-right">
                  <button
                    className="btn button2 text-white"
                    onClick={this.onHandleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}
export default withRouter(EditPublisher)
