import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { formatDate } from "../../utils/auth";
import { toTime } from "../../utils/filterTask";
import eye from "../../assets/icons/eye.svg";
export default class ViewTerritory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      displayError: false,
      error: "",
      status: false,
      data: []
    };
    this.handleShow = this.handleShow.bind(this);
  }
  handleShow() {
    this.setState({
      show: !this.state.show
    });
  }

  render() {
    const { show} = this.state;
    const { row } = this.props;
    const formatTime = (time, dayZone) => {
      let day = dayZone >= 0 ? dayZone : dayZone * -1;
      let description = "";
      if (dayZone === 0) {
        description = "On Publication Day";
      } else if (dayZone < 0) description = "Day(s) before Publication Date";
      else if (dayZone > 0) description = "Day(s) after Publication Date";

      return dayZone === 0
        ? `${toTime(time)} ${description}`
        : `${toTime(time)} ${day} ${description}`;
    };

    console.log(row.orderCreation);
    return (
      <React.Fragment>
        <Button
          variant="secondary"
          className="btn btn-xs btn-secondary"
          disabled={this.props.disabled}
          onClick={this.handleShow}
        >
          <img src={eye} width="15" height="15" alt="eye" />
        </Button>

        <Modal show={show} onHide={this.handleShow} size="lg">
          <Modal.Header closeButton>
            <Modal.Title className="text-uppercase">
              Territory Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table id="tech-companies-1" class="table  table-striped">
              <tbody>
                <tr>
                  <th>Territory ID</th>
                  <td className="text-uppercase">{row._id}</td>
                </tr>
                <tr>
                  <th>Created At</th>
                  <td>{formatDate(row.createdAt)}</td>
                </tr>
                <tr>
                  <th>Territory Name</th>
                  <td>{row.territoryName}</td>
                </tr>
                <tr>
                  <th>Territory Type</th>
                  <td>{row.territoryType}</td>
                </tr>
                <tr>
                  <th>Order Creation Deadline</th>
                  <td>
                    {row.orderCreation
                      ? formatTime(
                          row.orderCreation.end.time,
                          row.orderCreation.end.dayZone
                        )
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th>Printing Window Start</th>
                  <td>
                    {row.printingWindow
                      ? formatTime(
                          row.printingWindow.start.time,
                          row.printingWindow.start.dayZone
                        )
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th>Printing Window End</th>
                  <td>
                    {row.printingWindow
                      ? formatTime(
                          row.printingWindow.end.time,
                          row.printingWindow.end.dayZone
                        )
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th>Shipping Window Start</th>
                  <td>
                    {row.shippingWindow
                      ? formatTime(
                          row.shippingWindow.start.time,
                          row.shippingWindow.start.dayZone
                        )
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th>Shipping Window End</th>
                  <td>
                    {row.shippingWindow
                      ? formatTime(
                          row.shippingWindow.end.time,
                          row.shippingWindow.end.dayZone
                        )
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th>Dispatch Window Start</th>
                  <td>
                    {row.dispatchWindow
                      ? formatTime(
                          row.dispatchWindow.start.time,
                          row.dispatchWindow.start.dayZone
                        )
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th>Dispatch Window End</th>
                  <td>
                    {row.dispatchWindow
                      ? formatTime(
                          row.dispatchWindow.end.time,
                          row.dispatchWindow.end.dayZone
                        )
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th>Delivery Window Start</th>
                  <td>
                    {row.deliveryWindow
                      ? formatTime(
                          row.deliveryWindow.start.time,
                          row.deliveryWindow.start.dayZone
                        )
                      : ""}
                  </td>
                </tr>
           
                <tr>
                  <th>Delivery Window End</th>
                  <td>
                    {row.deliveryWindow
                      ? formatTime(
                          row.deliveryWindow.end.time,
                          row.deliveryWindow.end.dayZone
                        )
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th>Return Claim Window Start</th>
                  <td>
                    {row.returnClaimWindow
                      ? formatTime(
                          row.returnClaimWindow.start.time,
                          row.returnClaimWindow.start.dayZone
                        )
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th>Return Claim Window End</th>
                  <td>
                    {row.returnClaimWindow
                      ? formatTime(
                          row.returnClaimWindow.end.time,
                          row.returnClaimWindow.end.dayZone
                        )
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th>Return Submission Window Start</th>
                  <td>
                    {row.returnSubmissionWindow
                      ? formatTime(
                          row.returnSubmissionWindow.start.time,
                          row.returnSubmissionWindow.start.dayZone
                        )
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th>Return Submission Window End</th>
                  <td>
                    {row.returnSubmissionWindow
                      ? formatTime(
                          row.returnSubmissionWindow.end.time,
                          row.returnSubmissionWindow.end.dayZone
                        )
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th>Return Verification Window Start</th>
                  <td>
                    {row.returnVerificationWindow
                      ? formatTime(
                          row.returnVerificationWindow.start.time,
                          row.returnVerificationWindow.start.dayZone
                        )
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th>Return Verification Window End</th>
                  <td>
                    {row.returnVerificationWindow
                      ? formatTime(
                          row.returnVerificationWindow.end.time,
                          row.returnVerificationWindow.end.dayZone
                        )
                      : ""}
                  </td>
                </tr>
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}
