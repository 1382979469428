import React, {Component} from 'react'
import {Modal,Button} from 'react-bootstrap'
import eye from '../../assets/icons/eye.svg'
import {formatDate} from '../../utils/auth'
import axios from 'axios'
import {baseurl} from '../../config'
import {decryptInfo} from '../../utils/auth'
export default class BillStatus extends Component{
    constructor(props){
        super(props)
        this.state = {
            show: false,
            data: {
              billAmount: '',
              controlNumber: '',
              paymentComplete: false
            }
        }
        this.handleShow = this.handleShow.bind(this)
    }
    handleShow(){
        this.setState({
            show: !this.state.show
        })
    }
    componentDidMount(){
      const user = decryptInfo('printManager')
      console.log(user)
      axios({
          method: 'post',
          url: `${baseurl}/printManager/billStatus`,
          data: {
            orderCode: this.props.row.orderCode
          },
          headers: {
              Authorization: `Bearer ${user.headers.authorization}`
          }
      }).then((res)=>{
          console.log(res)
          if(res.data.data){
            this.setState({
              data: res.data.data
          })
          }
         
      }).catch((error)=>{
          console.log(error)
      })
  }
    render(){
        const {show,data} = this.state
        const {row} = this.props
        console.log(data)
        return (
          <React.Fragment>
            <Button
              variant="secondary"
              className="btn btn-xs"
              disabled= {!row.billInitiated?true: false}
              onClick={this.handleShow}
            >
              <img src={eye} width="18" height="18" alt="eye" />
            </Button>

            <Modal show={show} onHide={this.handleShow} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Bill status</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <div className="row mb-3">
                  <div className="col-sm-3">Bill status</div>
                  <div className="col-sm-9">
                    <table className="table table-bordered col-sm-11">
                      <tbody>
                            <tr>
                                <th>Bill amount</th>
                                <td> {parseFloat(data.billAmount.$numberDecimal)}</td>
                            </tr>
                            <tr>
                                <th>Control number</th>
                                <td>{data.controlNumber}</td>
                            </tr>
                            <tr>
                                <th>Payment status</th>
                                <td>{data.paymentComplete? 'PAID':'NOT PAID'}</td>
                            </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleShow}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </React.Fragment>
        );
    }
}