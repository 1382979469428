import React, { Component } from "react";
import Wrapper from "./wrapper";
import axios from "axios";
import { baseurl } from "../../config";
import { decryptInfo} from "../../utils/auth";
import ViewOrder from './view_order'
import Loader from "react-loader-spinner";
export default class OrderExecution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      role: null,
      orders: [],
      status: false,
      error: "",
      displayError: false
    };
  }
  componentDidMount() {
    const user = decryptInfo("publisher");
    console.log(user);
    axios({
      method: "get",
      url: `${baseurl}/publisher/orderExecution`,
      headers: {
        Authorization: `Bearer ${user.headers.authorization}`
      }
    })
      .then(res => {
        console.log(res);
        this.setState({
          data: res.data.data
        });
      })
      .catch(error => {
        console.log(error);
      });
  }
  render() {
    const { data, role, status, error, displayError } = this.state;
    console.log(role);
    let filteredRole = [];
    if (!role) {
      filteredRole = data;
    } else {
      filteredRole = data.filter(
        row => Date.parse(row.order.publicationDate) === Date.parse(role)
      );
    }
    return (
      <Wrapper>
        <div className="page-content-wrapper ">
          <div className="container container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="row align-items-center">
                    <div className="col-md-8">
                      <h4 className="page-title m-0 ml-3">publisher</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div class="col-12">
                <div class="card m-b-30">
                  <div class="card-body">
                    <h4 class="mt-0 header-title">ORDER EXECUTION REPORT</h4>
                    <div
                      className="form-group text-center "
                      style={{ display: `${status ? "block" : "none"}` }}
                    >
                      <Loader
                        type="Bars"
                        color="#ff6805"
                        width="50"
                        height="30"
                      />
                    </div>
                    <div
                      className="form-group row"
                      style={{ display: `${displayError ? "block" : "none"}` }}
                    >
                      <div className="col-12">
                        <span className="text-danger"> {error}</span>
                      </div>
                    </div>
                    <div class="table-rep-plugin">
                      <div
                        class="table-responsive b-0"
                        data-pattern="priority-columns"
                      >
                        <table
                          id="tech-companies-1"
                          class="table  table-striped"
                        >
                          <thead>
                            <tr>
                              <th>Print Order ID</th>
                              <th data-priority="1">Printer Discrepancy</th>
                              <th data-priority="1">Dispatch Discrepancy</th>
                              <th data-priority="3">Confirmed Delivery</th>
                              <th data-priority="3">View </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.data.map((row, index) => (
                              <tr key={index}>
                                <td className="text-uppercase">{row.printOrderID}</td>
                                <td>{row.printerDiscrepany}</td>
                                <td>{row.dispatchDiscrepany}</td>
                                <td>{row.confirmedDelivery}</td>   
                                <td><ViewOrder row={row} /></td>                         
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}
