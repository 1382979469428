import React, { Component } from "react";
import Wrapper from "./wrapper";
import { decryptInfo } from "../../utils/auth";
import axios from "axios";
import { baseurl } from "../../config";
import Loader from "react-loader-spinner";
import { toMilliSeconds } from "../../utils/filterTask";
class AddTerritory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      territoryName: "",
      territoryType: "",
      displayError: false,
      publisherId: null,
      orderCreationDayZone: null,
      orderCreationTime: null,
      printingWindowEndDayZone: null,
      printingWindowEndTime: null,
      printingWindowStartDayZone: null,
      printingWindowStartTime: null,
      shippingWindowStartDayZone: null,
      shippingWindowStartTime: null,
      shippingWindowEndDayZone: null,
      shippingWindowEndTime: null,
      dispatchWindowEndDayZone: null,
      dispatchWindowEndTime: null,
      dispatchWindowStartDayZone: null,
      dispatchWindowStartTime: null,
      deliveryWindowEndDayZone: null,
      deliveryWindowEndTime: null,
      deliveryWindowStartDayZone: null,
      deliveryWindowStartTime: null,
      returnClaimWindowEndDayZone: null,
      returnClaimWindowEndTime: null,
      returnClaimWindowStartDayZone: null,
      returnClaimWindowStartTime: null,
      returnSubmissionWindowEndDayZone: null,
      returnSubmissionWindowEndTime: null,
      returnSubmissionWindowStartDayZone: null,
      returnSubmissionWindowStartTime: null,
      verificationWindowEndDayZone: null,
      verificationWindowEndTime: null,
      verificationWindowStartDayZone: null,
      verificationWindowStartTime: null,
      data: [],
      error: "",
      status: false
    };
    this.onHandleChange = this.onHandleChange.bind(this);
    this.onHandleSubmit = this.onHandleSubmit.bind(this);
  }
  componentDidMount() {
    const user = decryptInfo("admin");
    console.log(user);
    axios({
      method: "post",
      url: `${baseurl}/user/list`,
      headers: {
        Authorization: `Bearer ${user.headers.authorization}`
      },
      data: {
        role: "publisher"
      }
    })
      .then(res => {
        console.log(res);
        this.setState({
          data: res.data.data
        });
      })
      .catch(error => {
        console.log(error);
      });
  }
  onHandleSubmit(event) {
    event.preventDefault();
    const { territoryName, territoryType,publisherId } = this.state;
    let operations = {
      dispatchWindow: {
        end:{
          time: toMilliSeconds(this.state.dispatchWindowEndTime),
          dayZone: this.state.dispatchWindowEndDayZone
        },
        start:{
          time: toMilliSeconds(this.state.dispatchWindowStartTime),
          dayZone: this.state.dispatchWindowStartDayZone
        }
      },
      deliveryWindow: {
        start:{
          time: toMilliSeconds(this.state.deliveryWindowStartTime),
          dayZone: this.state.deliveryWindowStartDayZone
        },
        end:{
          time: toMilliSeconds(this.state.deliveryWindowEndTime),
          dayZone: this.state.deliveryWindowEndDayZone
        }
      },
      returnClaimWindow: {
        start:{
          time: toMilliSeconds(this.state.returnClaimWindowStartTime),
          dayZone: this.state.returnClaimWindowStartDayZone
        },
        end:{
          time: toMilliSeconds(this.state.returnClaimWindowEndTime),
          dayZone: this.state.returnClaimWindowEndDayZone
        }
      },
      returnSubmissionWindow: {
        start:{
          time: toMilliSeconds(this.state.returnSubmissionWindowStartTime),
          dayZone: this.state.returnSubmissionWindowStartDayZone
        },
        end:{
          time: toMilliSeconds(this.state.returnSubmissionWindowEndTime),
          dayZone: this.state.returnSubmissionWindowEndDayZone
        }
      },
      returnVerificationWindow: {
        start:{
          time: toMilliSeconds(this.state.verificationWindowStartTime),
          dayZone: this.state.verificationWindowStartDayZone
        },
        end:{
          time: toMilliSeconds(this.state.verificationWindowEndTime),
          dayZone: this.state.verificationWindowEndDayZone
        }
      }

    }
    if(territoryType === 'Primary'){
      operations.orderCreation = {
        end:{
          time: toMilliSeconds(this.state.orderCreationTime),
          dayZone: this.state.orderCreationDayZone
        }
      }
     operations.printingWindow = {
        end:{
          time: toMilliSeconds(this.state.printingWindowEndTime),
          dayZone: this.state.printingWindowEndDayZone
        },
        start:{
          time: toMilliSeconds(this.state.printingWindowStartTime),
          dayZone: this.state.printingWindowStartDayZone
        }
      }
    }
    else if(territoryType ==="Secondary"){

     operations.shippingWindow ={
        end:{
          time: toMilliSeconds(this.state.shippingWindowEndTime),
          dayZone: this.state.shippingWindowEndDayZone
        },
        start:{
          time: toMilliSeconds(this.state.shippingWindowStartTime),
          dayZone: this.state.shippingWindowStartDayZone
        }
      }
    }
    let data =  {  
      ...operations,      
      territoryName,
      territoryType,
      publisherId
    }
    console.log(data)
    this.setState({
      status: true
    });
    const user = decryptInfo("admin");
    axios({
      method: "post",
      url: `${baseurl}/territory/new`,
      data:data,
      headers: {
        Authorization: `Bearer ${user.headers.authorization}`
      }
    })
      .then(res => {
        this.setState({
          status: false,
          displayError: true,
          error: `${res.data.message}`
        });
        console.log(res);
      })
      .catch(error => {
        console.log(error);
        this.setState({
          status: false,
          displayError: true,
          error: error.response? error.response.data.message: "Request has failed"
        });
      });
  }
  onHandleChange(event) {
    event.preventDefault()
    console.log(this.state);
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  render() {
    console.log(this.state)
    const {
      territoryName,
      territoryType,
      data,
      publisherId,
      displayError,
      orderCreationDayZone,
      orderCreationTime,
      printingWindowEndDayZone,
      printingWindowEndTime,
      printingWindowStartDayZone,
      printingWindowStartTime,
      shippingWindowStartDayZone,
      shippingWindowStartTime,
      shippingWindowEndDayZone,
      shippingWindowEndTime,
      dispatchWindowEndDayZone,
      dispatchWindowEndTime,
      dispatchWindowStartDayZone,
      dispatchWindowStartTime,
      deliveryWindowEndDayZone,
      deliveryWindowEndTime,
      deliveryWindowStartDayZone,
      deliveryWindowStartTime,
      returnClaimWindowEndDayZone,
      returnClaimWindowEndTime,
      returnClaimWindowStartDayZone,
      returnClaimWindowStartTime,
      returnSubmissionWindowEndDayZone,
      returnSubmissionWindowEndTime,
      returnSubmissionWindowStartDayZone,
      returnSubmissionWindowStartTime,
      verificationWindowEndDayZone,
      verificationWindowEndTime,
      verificationWindowStartDayZone,
      verificationWindowStartTime,
      error,
      status
    } = this.state;
    console.log(this.state)
    let internal = territoryType === "Primary"? false : true
    let external = territoryType === "Secondary"? false : true
    return (
      <Wrapper>
        <div className="page-content-wrapper ">
          <div className="container container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="row align-items-center">
                    <div className="col-md-8">
                      <h4 className="page-title m-0  ml-3">Add territory</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card m-b-30">
                  <div className="card-body">
                    <h4 className="mt-0 header-title">Add Territory</h4>
                    <p className="text-muted m-b-30 font-14"></p>
                    <div
                      className="form-group row"
                      style={{ display: `${displayError ? "block" : "none"}` }}
                    >
                      <div className="col-12">
                        <span className="text-danger"> {error}</span>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="input"
                        className="col-sm-3 col-form-label"
                      >
                        Territory name
                      </label>
                      <div className="col-sm-4">
                        <input
                          className="form-control"
                          name="territoryName"
                          type="text"
                          value={territoryName}
                          onChange={this.onHandleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="input"
                        className="col-sm-3 col-form-label"
                      >
                        Territory type
                      </label>
                      <div className="col-sm-4">
                        <select
                          className="custom-select"
                          name="territoryType"
                          defaultValue={territoryType}
                          onChange={this.onHandleChange}
                        >
                          <option selected>Select territory type</option>
                          <option value="Primary">Primary</option>
                          <option value="Secondary">Secondary</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-3 col-form-label">
                        Select publisher
                      </label>
                      <div className="col-sm-4">
                        <select
                          className="custom-select"
                          name="publisherId"
                          defaultValue={publisherId}
                          onChange={this.onHandleChange}
                        >
                          <option selected>Select publisher</option>
                          {data.map((role, index) => (
                            <option key={index} value={role._id}>
                              {role.userName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div style={{display: `${internal?'none':'block'}`}} className ="form-group">
                        <div className ="col-sm-12 row">
                        <label className="col-sm-3 col-form-label">
                        Print order  create deadline
                        </label>
                        <label className="col-sm-3 col-form-label">
                        Day zone
                        </label>
                        <input
                          className="form-control col-sm-3 mb-2"
                          name="orderCreationDayZone"
                          type="number"
                          value={orderCreationDayZone}
                          onChange={this.onHandleChange}
                         
                        /> 
                        </div>
                        <div className="col-sm-12 row">
                          <div className="col-sm-3"></div>
                          <label className="col-sm-3 col-form-label">
                            Time
                        </label>
                        <input
                          className="form-control col-sm-3 mb-2"
                          name="orderCreationTime"
                          type="time"
                          value={orderCreationTime}
                          onChange={this.onHandleChange}
                         
                        /> 
                        </div>
                    </div>
                          <div className ="form-group" style={{display: `${internal?'none':'block'}`}}>
                        <div className ="col-sm-12 row">
                        <label className="col-sm-3 col-form-label">
                        Order Printing Window
                        </label>
                        <label className="col-sm-3 col-form-label">
                            Start Day Zone
                        </label>
                        <input
                          className="form-control col-sm-3 mb-2"
                          name="printingWindowStartDayZone"
                          type="number"
                          value={printingWindowStartDayZone}
                          onChange={this.onHandleChange}
                         
                        /> 
                        </div>
                        <div className="col-sm-12 row">
                          <div className="col-sm-3"></div>
                          <label className="col-sm-3 col-form-label">
                            Start Time
                        </label>
                        <input
                          className="form-control col-sm-3 mb-2"
                          name="printingWindowStartTime"
                          type="time"
                          value={printingWindowStartTime}
                          onChange={this.onHandleChange}
                         
                        /> 
                        </div>
                        <div className ="col-sm-12 row">
                        <div className="col-sm-3">
                      
                        </div>
                        <label className="col-sm-3 col-form-label">
                            End Day Zone
                        </label>
                        <input
                          className="form-control col-sm-3 mb-2"
                          name="printingWindowEndDayZone"
                          type="number"
                          value={printingWindowEndDayZone}
                          onChange={this.onHandleChange}
                         
                        /> 
                        </div>
                        <div className="col-sm-12 row">
                          <div className="col-sm-3"></div>
                          <label className="col-sm-3 col-form-label">
                            End Time
                        </label>
                        <input
                          className="form-control col-sm-3 mb-2"
                          name="printingWindowEndTime"
                          type="time"
                          value={printingWindowEndTime}
                          onChange={this.onHandleChange}
                         
                        /> 
                        </div>
                    </div>

                    <div className ="form-group" style={{display: `${external?'none':'block'}`}}>
                        <div className ="col-sm-12 row">
                        <label className="col-sm-3 col-form-label">
                        Order Shipping Window
                        </label>
                        <label className="col-sm-3 col-form-label">
                            Start Day Zone
                        </label>
                        <input
                          className="form-control col-sm-3 mb-2"
                          name="shippingWindowStartDayZone"
                          type="number"
                          value={shippingWindowStartDayZone}
                          onChange={this.onHandleChange}
                         
                        /> 
                        </div>
                        <div className="col-sm-12 row">
                          <div className="col-sm-3"></div>
                          <label className="col-sm-3 col-form-label">
                            Start Time
                        </label>
                        <input
                          className="form-control col-sm-3 mb-2"
                          name="shippingWindowStartTime"
                          type="time"
                          value={shippingWindowStartTime}
                          onChange={this.onHandleChange}
                         
                        /> 
                        </div>
                        <div className ="col-sm-12 row">
                        <div className="col-sm-3">
                      
                        </div>
                        <label className="col-sm-3 col-form-label">
                            End Day Zone
                        </label>
                        <input
                          className="form-control col-sm-3 mb-2"
                          name="shippingWindowEndDayZone"
                          type="number"
                          value={shippingWindowEndDayZone}
                          onChange={this.onHandleChange}
                         
                        /> 
                        </div>
                        <div className="col-sm-12 row">
                          <div className="col-sm-3"></div>
                          <label className="col-sm-3 col-form-label">
                            End Time
                        </label>
                        <input
                          className="form-control col-sm-3 mb-2"
                          name="shippingWindowEndTime"
                          type="time"
                          value={shippingWindowEndTime}
                          onChange={this.onHandleChange}
                         
                        /> 
                        </div>
                    </div>


                    <div className ="form-group">
                        <div className ="col-sm-12 row">
                        <label className="col-sm-3 col-form-label">
                        Order Dispatch Window
                        </label>
                        <label className="col-sm-3 col-form-label">
                            Start Day Zone
                        </label>
                        <input
                          className="form-control col-sm-3 mb-2"
                          name="dispatchWindowStartDayZone"
                          type="number"
                          value={dispatchWindowStartDayZone}
                          onChange={this.onHandleChange}
                         
                        /> 
                        </div>
                        <div className="col-sm-12 row">
                          <div className="col-sm-3"></div>
                          <label className="col-sm-3 col-form-label">
                            Start Time
                        </label>
                        <input
                          className="form-control col-sm-3 mb-2"
                          name="dispatchWindowStartTime"
                          type="time"
                          value={dispatchWindowStartTime}
                          onChange={this.onHandleChange}
                         
                        /> 
                        </div>
                        <div className ="col-sm-12 row">
                        <div className="col-sm-3">
                      
                        </div>
                        <label className="col-sm-3 col-form-label">
                            End Day Zone
                        </label>
                        <input
                          className="form-control col-sm-3 mb-2"
                          name="dispatchWindowEndDayZone"
                          type="number"
                          value={dispatchWindowEndDayZone}
                          onChange={this.onHandleChange}
                         
                        /> 
                        </div>
                        <div className="col-sm-12 row">
                          <div className="col-sm-3"></div>
                          <label className="col-sm-3 col-form-label">
                            End Time
                        </label>
                        <input
                          className="form-control col-sm-3 mb-2"
                          name="dispatchWindowEndTime"
                          type="time"
                          value={dispatchWindowEndTime}
                          onChange={this.onHandleChange}
                         
                        /> 
                        </div>
                    </div>

                    

                    <div className ="form-group">
                        <div className ="col-sm-12 row">
                        <label className="col-sm-3 col-form-label">
                        Order Delivery Window
                        </label>
                        <label className="col-sm-3 col-form-label">
                            Start Day Zone
                        </label>
                        <input
                          className="form-control col-sm-3 mb-2"
                          name="deliveryWindowStartDayZone"
                          type="number"
                          value={deliveryWindowStartDayZone}
                          onChange={this.onHandleChange}
                        
                        /> 
                        </div>
                        <div className="col-sm-12 row">
                          <div className="col-sm-3"></div>
                          <label className="col-sm-3 col-form-label">
                            Start Time
                        </label>
                        <input
                          className="form-control col-sm-3 mb-2"
                          name="deliveryWindowStartTime"
                          type="time"
                          value={deliveryWindowStartTime}
                          onChange={this.onHandleChange}
                        
                        /> 
                        </div>
                        <div className ="col-sm-12 row">
                        <div className="col-sm-3">
                      
                        </div>
                        <label className="col-sm-3 col-form-label">
                            End Day Zone
                        </label>
                        <input
                          className="form-control col-sm-3 mb-2"
                          name="deliveryWindowEndDayZone"
                          type="number"
                          value={deliveryWindowEndDayZone}
                          onChange={this.onHandleChange}
                        
                        /> 
                        </div>
                        <div className="col-sm-12 row">
                          <div className="col-sm-3"></div>
                          <label className="col-sm-3 col-form-label">
                            End Time
                        </label>
                        <input
                          className="form-control col-sm-3 mb-2"
                          name="deliveryWindowEndTime"
                          type="time"
                          value={deliveryWindowEndTime}
                          onChange={this.onHandleChange}
                        
                        /> 
                        </div>
                    </div>



                    <div className ="form-group">
                        <div className ="col-sm-12 row">
                        <label className="col-sm-3 col-form-label">
                        Return Claim Window
                        </label>
                        <label className="col-sm-3 col-form-label">
                            Start Day Zone
                        </label>
                        <input
                          className="form-control col-sm-3 mb-2"
                          name="returnClaimWindowStartDayZone"
                          type="number"
                          value={returnClaimWindowStartDayZone}
                          onChange={this.onHandleChange}
                        
                        /> 
                        </div>
                        <div className="col-sm-12 row">
                          <div className="col-sm-3"></div>
                          <label className="col-sm-3 col-form-label">
                            Start Time
                        </label>
                        <input
                          className="form-control col-sm-3 mb-2"
                          name="returnClaimWindowStartTime"
                          type="time"
                          value={returnClaimWindowStartTime}
                          onChange={this.onHandleChange}
                        
                        /> 
                        </div>
                        <div className ="col-sm-12 row">
                        <div className="col-sm-3">
                      
                        </div>
                        <label className="col-sm-3 col-form-label">
                            End Day Zone
                        </label>
                        <input
                          className="form-control col-sm-3 mb-2"
                          name="returnClaimWindowEndDayZone"
                          type="number"
                          value={returnClaimWindowEndDayZone}
                          onChange={this.onHandleChange}
                        
                        /> 
                        </div>
                        <div className="col-sm-12 row">
                          <div className="col-sm-3"></div>
                          <label className="col-sm-3 col-form-label">
                            End Time
                        </label>
                        <input
                          className="form-control col-sm-3 mb-2"
                          name="returnClaimWindowEndTime"
                          type="time"
                          value={returnClaimWindowEndTime}
                          onChange={this.onHandleChange}
                        
                        /> 
                        </div>
                    </div>




                  
                    <div className ="form-group">
                        <div className ="col-sm-12 row">
                        <label className="col-sm-3 col-form-label">
                        Return Submission Window
                        </label>
                        <label className="col-sm-3 col-form-label">
                            Start Day Zone
                        </label>
                        <input
                          className="form-control col-sm-3 mb-2"
                          name="returnSubmissionWindowStartDayZone"
                          type="number"
                          value={returnSubmissionWindowStartDayZone}
                          onChange={this.onHandleChange}
                        
                        /> 
                        </div>
                        <div className="col-sm-12 row">
                          <div className="col-sm-3"></div>
                          <label className="col-sm-3 col-form-label">
                            Start Time
                        </label>
                        <input
                          className="form-control col-sm-3 mb-2"
                          name="returnSubmissionWindowStartTime"
                          type="time"
                          value={returnSubmissionWindowStartTime}
                          onChange={this.onHandleChange}
                        
                        /> 
                        </div>
                        <div className ="col-sm-12 row">
                        <div className="col-sm-3">
                      
                        </div>
                        <label className="col-sm-3 col-form-label">
                            End Day Zone
                        </label>
                        <input
                          className="form-control col-sm-3 mb-2"
                          name="returnSubmissionWindowEndDayZone"
                          type="number"
                          value={returnSubmissionWindowEndDayZone}
                          onChange={this.onHandleChange}
                        
                        /> 
                        </div>
                        <div className="col-sm-12 row">
                          <div className="col-sm-3"></div>
                          <label className="col-sm-3 col-form-label">
                            End Time
                        </label>
                        <input
                          className="form-control col-sm-3 mb-2"
                          name="returnSubmissionWindowEndTime"
                          type="time"
                          value={returnSubmissionWindowEndTime}
                          onChange={this.onHandleChange}
                        
                        /> 
                        </div>
                    </div>

                    




                  
                    <div className ="form-group">
                        <div className ="col-sm-12 row">
                        <label className="col-sm-3 col-form-label">
                        Return Verification Window
                        </label>
                        <label className="col-sm-3 col-form-label">
                            Start Day Zone
                        </label>
                        <input
                          className="form-control col-sm-3 mb-2"
                          name="verificationWindowStartDayZone"
                          type="number"
                          value={verificationWindowStartDayZone}
                          onChange={this.onHandleChange}
                        
                        /> 
                        </div>
                        <div className="col-sm-12 row">
                          <div className="col-sm-3"></div>
                          <label className="col-sm-3 col-form-label">
                            Start Time
                        </label>
                        <input
                          className="form-control col-sm-3 mb-2"
                          name="verificationWindowStartTime"
                          type="time"
                          value={verificationWindowStartTime}
                          onChange={this.onHandleChange}
                        
                        /> 
                        </div>
                        <div className ="col-sm-12 row">
                        <div className="col-sm-3">
                      
                        </div>
                        <label className="col-sm-3 col-form-label">
                            End Day Zone
                        </label>
                        <input
                          className="form-control col-sm-3 mb-2"
                          name="verificationWindowEndDayZone"
                          type="number"
                          value={verificationWindowEndDayZone}
                          onChange={this.onHandleChange}
                        
                        /> 
                        </div>
                        <div className="col-sm-12 row">
                          <div className="col-sm-3"></div>
                          <label className="col-sm-3 col-form-label">
                            End Time
                        </label>
                        <input
                          className="form-control col-sm-3 mb-2"
                          name="verificationWindowEndTime"
                          type="time"
                          value={verificationWindowEndTime}
                          onChange={this.onHandleChange}
                        
                        /> 
                        </div>
                    </div>


                            




                    
                    <div
                      className="form-group text-center "
                      style={{ display: `${status ? "block" : "none"}` }}
                    >
                      <Loader
                        type="Bars"
                        color="#ff6805"
                        width="50"
                        height="30"
                      />
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2"></div>
                      <div className="col-sm-4 text-right">
                        <button
                          className="btn button2 text-white"
                          onClick={this.onHandleSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default AddTerritory;
