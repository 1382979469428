import React,{Component} from 'react'
import angleDown from '../assets/icons/angle-down.svg'
import avatar from '../assets/icons/man-user.svg'
import {NavLink} from 'react-router-dom'
import $ from 'jquery'
import {decryptInfo} from '../utils/auth'
import {Redirect} from 'react-router-dom'
class Navbar extends Component{
    
    componentDidMount(){
        document.body.style.backgroundColor = "white"
        $('.toggle-search').on('click', function (event) {
            event.preventDefault()
            var targetId = $(this).data('target');
            var $searchBar;
            if (targetId) {
                $searchBar = $(targetId);
                $searchBar.toggleClass('open');
            }
        });
        $('[data-toggle="tooltip"]').tooltip();
        $('[data-toggle="popover"]').popover();
    }
    render(){   
        return(
            <div className="topbar">

            <div className="topbar-left	d-none d-lg-block">
                <div className="text-center">
                    <NavLink to="/" className="logo">
                        <span style={{color: '#e3f2fd'}}  className=" text-uppercase ">gazeti network 20</span>
                    </NavLink>
                </div>
            </div>

            <nav className="navbar-custom">
                <ul className="list-inline float-right mb-0">
                    <li className="list-inline-item ">   
                    
                    </li>
                    <li className="list-inline-item dropdown notification-list nav-user">
                        <a className="nav-link dropdown-toggle arrow-none waves-effect" data-toggle="dropdown" href="/" role="button"
                        aria-haspopup="false" aria-expanded="false">
                            <img src={avatar} alt="user" className="rounded-circle" />
        <span className="d-none d-md-inline-block ml-1"><NavLink className="text-white" to="/logout">{}</NavLink>
                             <i className="mdi mdi-chevron-down"><img src={angleDown} width="10" height="10" alt="icon"/></i> 
                             </span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right dropdown-menu-animated profile-dropdown">
                            <a className="dropdown-item" href="/"><i className="dripicons-exit text-muted"></i> <NavLink to="/logout">Logout</NavLink></a>
                            <a className="dropdown-item" href="/login"><i className="dripicons-exit text-muted"></i> <NavLink to="/login">Login</NavLink></a>
                            <a className="dropdown-item" href="/activationCode"><i className="dripicons-exit text-muted"></i> <NavLink to="/activationCode">Activation code</NavLink></a>
                        </div>
                    </li>

                </ul>

                <ul className="list-inline menu-left mb-0">
                  
                   

                </ul>


            </nav>

        </div> 
        )
    }
}
export  default Navbar