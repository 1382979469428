import React, { Component } from "react";
import Wrapper from "./wrapper";
import axios from "axios";
import { baseurl } from "../../config";
import { decryptInfo, formatDate } from "../../utils/auth";
import { roles } from "../../config";
import ViewOrder from "./view_order";
import ReturnClaim from "./add_printed";
import RequestControlNumber from "./request_control_number";
import BillStatus from "./bill_status";
class ListClaims extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      role: null
    };
  }
  componentDidMount() {
    const user = decryptInfo("returnOfficer");
    console.log(user);
    axios({
      method: "get",
      url: `${baseurl}/returnClaim/list`,
      headers: {
        Authorization: `Bearer ${user.headers.authorization}`
      }
    })
      .then(res => {
        console.log(res);
        this.setState({
          data: res.data.data
        });
      })
      .catch(error => {
        console.log(error);
      });
  }
  render() {
    const { data, role } = this.state;
    console.log(role);
    let filteredRole = [];
    if (!role) {
      filteredRole = data;
    } else {
      filteredRole = data.filter(
        row => Date.parse(row.publicationDate) === Date.parse(role)
      );
    }
    return (
      <Wrapper>
        <div className="page-content-wrapper ">
          <div className="container container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="row align-items-center">
                    <div className="col-md-8">
                      <h4 className="page-title m-0 ml-3">RETURN OFFICER</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div class="col-12">
                <div class="card m-b-30">
                  <div class="card-body">
                    <h4 class="mt-0 header-title">RETURN CLAIMS</h4>
                    <div class="col-sm-12 text-right mb-3 pr-3">
                      <input
                        class="form-control"
                        type="date"
                        onChange={event =>
                          this.setState({
                            role: event.target.value
                          })
                        }
                        value={role || ""}
                        className="custom-select bg-secondary text-white col-sm-3"
                        id="example-date-input"
                      />
                    </div>
                    <div class="table-rep-plugin">
                      <div
                        class="table-responsive b-0"
                        data-pattern="priority-columns"
                      >
                        <table
                          id="tech-companies-1"
                          class="table  table-striped"
                        >
                          <thead>
                            <tr>
                              <th>Created At</th>
                              <th>Submission code</th>
                              <th data-priority="3">External bill amount</th>
                              <th data-priority="3">Internal bill amount</th>
                              <th>View Claim</th>
                              <th>Verify claim</th>
                              <th>Request Control Number</th>
                              <th>Bill status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredRole.map((row, index) => (
                              <tr key={index}>
                                <td>{formatDate(row.createdAt)}</td>
                                <td>{row.submissionCode}</td>
                                <td>
                                  {parseFloat(
                                    row.billAmount.external.$numberDecimal
                                  )}
                                </td>
                                <td>
                                  {parseFloat(
                                    row.billAmount.internal.$numberDecimal
                                  )}
                                </td>
                                <td>
                                  <ViewOrder row={row} />
                                </td>
                                <td>
                                  <ReturnClaim row={row} />
                                </td>
                                <td>
                                  <RequestControlNumber row={row} />
                                </td>
                                <td>
                                  <BillStatus row={row} />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default ListClaims;
