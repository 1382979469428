import React from "react";
import { formatDate } from "../../utils/auth";
const OrderFailedTable = ({ filteredRole }) => {
  return (
    <div class="table-rep-plugin">
      <div class="table-responsive b-0" data-pattern="priority-columns">
        <table id="tech-companies-1" class="table  table-striped">
          <thead>
            <tr>
              <th>Created At</th>
              <th>Publication Date</th>
              <th>Customer code</th>
              <th data-priority="1">Product code</th>
              <th data-priority="3">Quantity</th>
              <th data-priority="1">Status</th>
              <th data-priority="1">Reason</th>
            </tr>
          </thead>
          <tbody>
            {filteredRole.map((row, index) => (
              <tr key={index}>
                <td>{formatDate(row.createdAt)}</td>
                <td>
                  {row.order.publicationDate ? formatDate(row.order.publicationDate) : "NULL"}
                </td>
                <td>{row.order ? row.order.customerCode : "NULL"}</td>
                <td>{row.order ? row.order.productCode : "NULL"}</td>
                <td>{row.order ? row.order.productQuantity : "NULL"}</td>
                <td>{row.status}</td>
                <td className="text-lowercase">
                  {row.reason.split("_").join(" ")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default OrderFailedTable;
