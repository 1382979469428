import React, {Component} from 'react'
import Nav from '../nav/nav'
import Content from '../content/content'
import {decryptInfo} from '../../utils/auth'
import {Redirect} from 'react-router-dom'
import list from '../../assets/icons/list.svg'
import home from '../../assets/icons/home.svg'
class Wrapper extends Component{
    constructor(props){
        super(props)
        this.state = {}
    }
    render(){
        const user = decryptInfo('nightShiftSupervisor') 
          
        console.log(user)
        if(!user){
            return(
                <Redirect to="/login" />
            )
        }
        const links = [
            {
                name: 'Confirm Handover',
                url: '/nightShiftSupervisor/confirm',
                icon:home
            },
            {
                name: 'List Orders',
                url: '/nightShiftSupervisor/list',
                icon:list
            },
            {
                name: 'Parcel List',
                url: '/nightShiftSupervisor/listParcel',
                icon:list
            }
        
        ]
        const header = 'Night Shift Supervisor'
        return(
        <div id="wrapper">
            <Nav links ={links} header={header} />           
            <Content>
                {this.props.children}
            </Content>
        </div>
        )
    }
    
}
export default Wrapper