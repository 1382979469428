import React, {Component} from 'react'
import {Modal,Button} from 'react-bootstrap'
import eye from '../../assets/icons/eye.svg'
import {formatDate} from '../../utils/auth'
import axios from 'axios'
import {baseurl} from '../../config'
import {decryptInfo} from '../../utils/auth'
export default class ClaimStatus extends Component{
    constructor(props){
        super(props)
        this.state = {
            show: false,
            data: {
              acceptedReturns: [],
              rejectReturns: []
            }
        }
        this.handleShow = this.handleShow.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }
    handleShow(){
        this.setState({
            show: !this.state.show
        })
    }
    handleClose(){
      
    }
    componentDidMount(){
      const user = decryptInfo('directCustomer')
      console.log(user)
      axios({
          method: 'post',
          url: `${baseurl}/returnClaim/status`,
          data: {
            submissionCode: this.props.row.submissionCode
          },
          headers: {
              Authorization: user?`Bearer ${user.headers.authorization}`:''
          }
      }).then((res)=>{
          console.log(res)
          this.setState({
              data: res.data.data.submissionBucket
          })
      }).catch((error)=>{
          console.log(error)
      })
  }
    render(){
        const {show,data} = this.state
        const {row} = this.props
        return (
          <React.Fragment>
            <Button
              variant="secondary"
              className="btn btn-xs"
              onClick={this.handleShow}
              disabled = {!row.submissionCode? true : false}
            >
              <img src={eye} width="18" height="18" alt="eye" />
            </Button>

            <Modal show={show} onHide={this.handleShow} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Return Claim Status</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <div className="row mb-3">
                  <div className="col-sm-3">Accepted returns</div>
                  <div className="col-sm-9">
                    <table className="table table-bordered col-sm-11">
                      <tr>
                        <th>Product code</th>
                        <th>Product quantity</th>
                      </tr>
                      <tbody>
                        {data.acceptedReturns.map(item => (
                          <tr>
                            <td>{item.productCode}</td>
                            <td>{item.productQuantity}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-3">Rejected returns</div>
                  <div className="col-sm-9">
                    <table className="table table-bordered col-sm-11">
                      <tr>
                        <th>Product code</th>
                        <th>Product quantity</th>
                      </tr>
                      <tbody>
                        {data.rejectReturns.map(item => (
                          <tr>
                            <td>{item.productCode}</td>
                            <td>{item.productQuantity}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </React.Fragment>
        );
    }
}