import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { formatDate, decryptInfo } from "../../utils/auth";
import axios from "axios";
import { baseurl } from "../../config";
import eye from "../../assets/icons/order.svg";
export default class ClosedOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      displayError: false,
      error: "",
      status: false,
      data: []
    };
    this.handleShow = this.handleShow.bind(this);
   
  }
  handleShow() {
    this.setState({
      show: !this.state.show
    });
  }
  componentDidMount() {
      const user = decryptInfo("printOrderMaker");
      axios({
        method: "post",
        url: `${baseurl}/printOrderMaker/closedOrders`,
        data: {
          printOrderId: this.props.id
        },
        headers: {
          Authorization: `Bearer ${user.headers.authorization}`
        }
      })
        .then(res => {
          this.setState({
            data: res.data.data
          });
          console.log(res);
        })
        .catch(error => {
          console.log(error);
        });
    
  }
  render() {
    const { show, data } = this.state;
    return (
      <React.Fragment>
        <Button
          variant="secondary"
          className="btn btn-xs btn-secondary"
          disabled ={this.props.disabled}
          onClick={this.handleShow}
        >
         <img src={eye} width="17" height="17" alt="eye" />
        </Button>

        <Modal show={show} onHide={this.handleShow} size="lg">
          <Modal.Header closeButton>
            <Modal.Title className="text-uppercase">Closed Orders</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table id="tech-companies-1" class="table  table-striped">
              <thead>
                <tr>
                  <th>Publication Date</th>
                  <th>Customer Name</th>
                  <th>Product Name</th>
                  <th>Product Quantity</th>
                  <th>Price</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row, index) => (
                  <tr key={index}>
                    <td>{formatDate(row.publicationDate)}</td>
                    <td>{row.customerName}</td>
                    <td>{row.productName}</td>
                    <td>{row.productQuantity}</td>
                    <td>TSH {row.price * row.productQuantity}</td>
                    <td>{row.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}
