import React from 'react'
import {NavLink} from 'react-router-dom'
import home from '../../assets/icons/home.svg'
const HomeContent = ({name,url})=>{
    return(
        <div className="services border" >
        <div className="d-flex flex-column align-items-center justify-content-center ">
            <i>
                 <img src={home} width="40" height="40" alt="icon"/>
            </i>            
        </div>
        <h4 className="text-capitalize" style={{fontWeight: 300 , fontSize: '1rem'}}>
            {name}
        </h4>
         <NavLink  to={url} exact>
            <button className="button button2">                            
                <span className="Navlink">Go</span>                            
            </button>
        </NavLink>
    </div>
    )
}
export default HomeContent
