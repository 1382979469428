import React, { Component } from "react";

import Wrapper from "./wrapper";
import { decryptInfo } from "../../utils/auth";
import axios from "axios";
import { baseurl } from "../../config";
import "@material/react-chips/dist/chips.css";
import Loader from "react-loader-spinner";
class ConfirmSubmission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayError: false,
      error: "",
      status: false,
      productCode: "",
      productQuantity: null,
      productsReceived: [],
      orderReceivedCode: ""
    };
    this.onHandleChange = this.onHandleChange.bind(this);
    this.onHandleSubmit = this.onHandleSubmit.bind(this);
    this.onHandleAdd = this.onHandleAdd.bind(this);
  }
  onHandleAdd() {
    const { productCode, productQuantity, productsReceived } = this.state;
    if (productCode !== "" && productQuantity) {
      const products = productsReceived.filter(
        product => product.productCode !== productCode
      );
      this.setState({
        productsReceived: [
          {
            productCode: productCode,
            productQuantity: productQuantity
          },
          ...products
        ]
      });
    } else {
      this.setState({
        displayError: true,
        error: "Please fill product code and product quantity"
      });
    }
  }
  onHandleSubmit(event) {
    event.preventDefault();
    const { productsReceived, orderReceivedCode } = this.state;

    if (productsReceived.length > 0) {
      this.setState({
        status: true
      });
      const user = decryptInfo("storeOfficer");
      axios({
        method: "post",
        url: `${baseurl}/storeOfficer/confirm`,
        data: {
          submissionCode: orderReceivedCode,
          returnStorage: productsReceived
        },
        headers: {
          Authorization: `Bearer ${user.headers.authorization}`
        }
      })
        .then(res => {
          this.setState({
            status: false,
            displayError: true,
            error: `${res.data.message}`
          });
          console.log(res);
          this.props.history.push('/storeOfficer/list')
        })
        .catch(error => {
          console.log(error);
          this.setState({
            status: false,
            displayError: true,
            error: error.response? error.response.data.message: "Request has failed"
          });
        });
    } else {
      this.setState({
        displayError: true,
        error: "No products submitted"
      });
    }
  }
  onHandleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
      displayError: false
    });
  }

  render() {
    const {
      displayError,
      productCode,
      productQuantity,
      orderReceivedCode,
      error,
      status
    } = this.state;
    return (
      <Wrapper>
        <div className="page-content-wrapper ">
          <div className="container container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="row align-items-center">
                    <div className="col-md-8">
                      <h4 className="page-title m-0 ml-3">Confirm handlover</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card m-b-30">
                  <div className="card-body">
                    <h4 className="mt-0 header-title">Confirm submission</h4>
                    <p className="text-muted m-b-30 font-14"></p>
                    <div
                      className="form-group row"
                      style={{ display: `${displayError ? "block" : "none"}` }}
                    >
                      <div className="col-12">
                        <span className="text-danger"> {error}</span>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="input"
                        className="col-sm-2 col-form-label"
                      >
                        Submission code
                      </label>
                      <div className="col-sm-6">
                        <input
                          className="form-control"
                          name="orderReceivedCode"
                          type="text"
                          value={orderReceivedCode}
                          onChange={this.onHandleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="input"
                        className="col-sm-2 col-form-label"
                      >
                        Product code
                      </label>
                      <div className="col-sm-6">
                        <input
                          className="form-control"
                          type="text"
                          name="productCode"
                          value={productCode}
                          onChange={this.onHandleChange}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="input"
                        className="col-sm-2 col-form-label"
                      >
                        Product Quantity
                      </label>
                      <div className="col-sm-6">
                        <input
                          className="form-control"
                          name="productQuantity"
                          type="number"
                          value={productQuantity}
                          onChange={this.onHandleChange}
                        />
                      </div>
                    </div>

                    <div
                      className="form-group text-center "
                      style={{ display: `${status ? "block" : "none"}` }}
                    >
                      <Loader
                        type="Bars"
                        color="#ff6805"
                        width="50"
                        height="30"
                      />
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2"></div>
                      <div className="col-sm-6 text-right">
                        <button
                          className="btn button2 text-white"
                          onClick={this.onHandleAdd}
                        >
                          Add
                        </button>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-2"></div>
                        <div className="col-sm-6 text-right">
                          <button
                            className="btn button2 text-white"
                            onClick={this.onHandleSubmit}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default ConfirmSubmission;
