import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import eye from "../../assets/icons/eye.svg";
import { formatDate } from "../../utils/auth";
export default class ViewOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
    this.handleShow = this.handleShow.bind(this);
  }
  handleShow() {
    this.setState({
      show: !this.state.show
    });
  }
  render() {
    const { show } = this.state;
    const { row } = this.props;
    return (
      <React.Fragment>
        <Button
          variant="secondary"
          className="btn btn-xs"
          onClick={this.handleShow}
        >
          <img src={eye} width="15" height="15" alt="eye" />
        </Button>

        <Modal show={show} onHide={this.handleShow} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Order Summary</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table id="tech-companies-1" class="table  table-striped">
              <thead>
                <th colSpan="2"></th>
              </thead>
              <tbody>
                <tr>
                  <td>Order ID</td>
                  <td className="text-uppercase">{row._id}</td>
                </tr>
                <tr>
                  <td>Created At</td>
                  <td>{formatDate(row.createdAt)}</td>
                </tr>
                <tr>
                  <td>Customer Code</td>
                  <td>{row.customerCode}</td>
                </tr>
                <tr>
                  <td>Customer Name</td>
                  <td>{row.customerName}</td>
                </tr>
                <tr>
                  <td>Publication Date</td>
                  <td>{formatDate(row.publicationDate)}</td>
                </tr>
                <tr>
                  <td>Product Code</td>
                  <td>{row.productCode}</td>
                </tr>
                <tr>
                  <td>Product Name</td>
                  <td>{row.productName}</td>
                </tr>
                <tr>
                  <td>Product Quantity</td>
                  <td>{row.productQuantity}</td>
                </tr>
                <tr>
                  <td>Value</td>
                  <td>TSH {row.price * row.productQuantity}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>{row.status}</td>
                </tr>
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleShow}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}
