import React, { Component } from "react";
import Wrapper from "./wrapper";
import axios from "axios";
import { baseurl } from "../../config";
import { decryptInfo, formatDate } from "../../utils/auth";
import ViewOrder from "./show_items";
import ReturnClaim from "./return_claim";
import ClaimStatus from "./check_claim_status";
import CheckBox from "../print_order_maker/check_box";
import Loader from "react-loader-spinner";
class CustomerOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      role: null,
      displayError: false,
      error: ""
    };
    this.onCheck = this.onCheck.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {
    const user = decryptInfo("directCustomer");
    console.log(user);
    axios({
      method: "get",
      url: user ? `${baseurl}/customer/list` : "",
      headers: {
        Authorization: user ? `Bearer ${user.headers.authorization}` : ""
      }
    })
      .then(res => {
        console.log(res);
        this.setState({
          data: res.data.data
        });
      })
      .catch(error => {
        console.log(error);
      });
  }
  onSubmit(event) {
    event.preventDefault();
    this.setState({
      status: true
    });
    const user = decryptInfo("directCustomer");
    let orderCodes = [];
    this.state.data.forEach(order => {
      if (order.isChecked) {
        orderCodes.push(order.orderCode);
      }
    });
    axios({
      method: "post",
      url: `${baseurl}/customer/billRequest`,
      data: {
        orderCodes: orderCodes
      },
      headers: {
        Authorization: user ? `Bearer ${user.headers.authorization}` : ""
      }
    })
      .then(res => {
        this.setState({
          status: false,
          displayError: true,
          error: `${res.data.message}`
        });
        console.log(res);
        window.location.reload(false)
      })
      .catch(error => {
        console.log(error);
        this.setState({
          status: false,
          displayError: true,
          error: error.response.data.message
        });
      });
  }
  onCheck(event) {
    const { data } = this.state;
    data.forEach(row => {
      if (row._id === event.target.value) {
        row.isChecked = event.target.checked;
      }
    });
    this.setState({
      data: data,
      displayError: false
    });
  }
  render() {
    const { data, role, displayError, error, status } = this.state;
    console.log(data);
    let filteredRole = [];
    if (!role) {
      filteredRole = data;
    } else {
      filteredRole = data.filter(
        row => Date.parse(row.createdAt) === Date.parse(role)
      );
    }
    return (
      <Wrapper>
        <div className="page-content-wrapper ">
          <div className="container container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-title-box">
                  <div className="row align-items-center">
                    <div className="col-md-8">
                      <h4 className="page-title m-0 ml-3">LIST OF ORDERS</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div class="col-12">
                <div class="card m-b-30">
                  <div class="card-body">
                    <h4 class="mt-0 header-title">ALL ORDERS</h4>
                    <div class="col-sm-12  text-right mb-3 pr-3">
                      <div
                        className="form-group row"
                        style={{
                          display: `${displayError ? "block" : "none"}`
                        }}
                      >
                        <div className="col-12">
                          <span className="text-danger"> {error}</span>
                        </div>
                      </div>
                      <div
                        className="form-group text-center "
                        style={{ display: `${status ? "block" : "none"}` }}
                      >
                        <Loader
                          type="Bars"
                          color="#ff6805"
                          width="50"
                          height="30"
                        />
                      </div>
                      <button
                        className="btn btn-primary"
                        onClick={this.onSubmit}
                      >
                        Make Invoice
                      </button>
                    </div>
                    <div class="table-rep-plugin">
                      <div
                        class="table-responsive b-0"
                        data-pattern="priority-columns"
                      >
                        <table
                          id="tech-companies-1"
                          class="table  table-striped"
                        >
                          <thead>
                            <tr>
                              <th>Select</th>
                              <th>Publication Date</th>
                              <th>Order code</th>
                              <th>View Order</th>
                              <th>Return claim</th>
                              <th>Return claim status</th>
                             
                            </tr>
                          </thead>
                          <tbody>
                            {filteredRole.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  <CheckBox
                                    id={row._id}
                                    handleCheck={this.onCheck}
                                    disabled={row.billInitiated}
                                    checked={row.isChecked}
                                  />
                                </td>
                                <td>{formatDate(row.createdAt)}</td>

                                <td>{row.orderCode}</td>
                                <td>
                                  <ViewOrder row={row} />
                                </td>
                                <td>
                                  <ReturnClaim row={row} />
                                </td>
                                <td>
                                  <ClaimStatus row={row} />
                                </td>
                              
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default CustomerOrders;
